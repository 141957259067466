import api from '@application/index/asset/utils/apiV2'

export const GET_PERSONAL_FOLLOW_UP_DISCOUNT_REQUEST = 'GET_PERSONAL_FOLLOW_UP_DISCOUNT_REQUEST'
export const GET_PERSONAL_FOLLOW_UP_DISCOUNT_SUCCESS = 'GET_PERSONAL_FOLLOW_UP_DISCOUNT_SUCCESS'
export const GET_PERSONAL_FOLLOW_UP_DISCOUNT_FAILURE = 'GET_PERSONAL_FOLLOW_UP_DISCOUNT_FAILURE'

export const getPersonalFollowUpDiscount = () => {
  return api.get({
  endpoint: '/api/v2/personal_follow_up_discounts',
  normalize: true,

  types: [
    GET_PERSONAL_FOLLOW_UP_DISCOUNT_REQUEST,
    GET_PERSONAL_FOLLOW_UP_DISCOUNT_SUCCESS,
    GET_PERSONAL_FOLLOW_UP_DISCOUNT_FAILURE
  ]
})
}
