import { connect } from 'react-redux'

import { onSuccess } from '@application/index/asset/store/actions/signup'
import { signupEmail } from '@application/index/asset/store/actions/auth'
import showServerErrors from '@application/index/asset/utils/errors'

import SignupEmailClientForm from './component'

const onError = showServerErrors('forms.signup')
const onSubmit = signupEmail.bind(null, 'client')

const mapDispatchToProps = {
  onError,
  onSubmit,
  onSuccess
}

export default connect(null, mapDispatchToProps)(
  SignupEmailClientForm
)
