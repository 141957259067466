import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import styles from './.module.scss'

import HeaderLight from './HeaderLight'

import {meSelector} from '@application/index/asset/store/selectors/me'
import SubscriptionPlan from './SubscriptionPlan'

import NavigationMenu from './NavigationMenu/index/index'

const Layout = ({ isClient = true, className, children }) => {
  const [navigationMenuOpen, setNavigationMenuOpen] = useState()

  const toggleNavigationMenuOpen = open => {
    if (open && !navigationMenuOpen || !open && navigationMenuOpen)
      setNavigationMenuOpen(open)
  }

  return <>
    <HeaderLight className={styles.header} {...{
      navigationMenuOpen, toggleNavigationMenuOpen
    }}/>

    {isClient && <>
      {useSelector(meSelector).subscription &&
        <SubscriptionPlan/>
      }
      <NavigationMenu
        open={navigationMenuOpen} setOpen={setNavigationMenuOpen}
      />
    </>}

    <div className={classNames('container ' + styles.container, className)}>
      {children}
    </div>
  </>
}

Layout.propTypes = {
  children: PropTypes.node,
  isClient: PropTypes.bool
}

export default Layout
