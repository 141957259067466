import transform from 'lodash/transform'

import queryString from 'query-string'
import Cookie from 'js-cookie'

const headersList = [
  'access-token', 'client', 'uid', 'token-type'
]

export const persistHeadersInCookies = (headers) => (
  headersList.forEach((headerName) =>
    Cookie.set(headerName, headers[headerName])
  )
)

export const cleanHeadersInCookies = () => (
  headersList.forEach((headerName) =>
    Cookie.remove(headerName)
  )
)

export const extractFromHeaders = (headers) => (
  transform(headersList, (result, headerName) => {
    result[headerName] = headers[headerName]
  }, {})
)

export const persistHeaders = persistHeadersInCookies
export const cleanHeaders = cleanHeadersInCookies

export const getHeaders = () => ({
  // cookie headers
  ...headersList.reduce((result, header) => {
    result[header] = Cookie.get(header)

    return result
  }, {})
  // location headers
  , ...(() => {
    const {
      token, client_id, uid
    } =
      queryString.parse(document.location.search)

    return {
      ...(token && client_id && uid && {
        'access-token': token
        , client: client_id
        , uid
        , 'token-type': 'bearer'
      })
    }
  })()
})