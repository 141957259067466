import React from 'react'
import {Modal} from 'semantic-ui-react'
import classNames from 'classnames'

import Headline from '@application/index/views/client/Project/new/components/Headline'

import Link from '@application/index/component/index/LinkWithRelation'
import Spinner from '@application/index/component/index/Spinner'
import ButtonWithArrow from './ButtonWithArrow'

import styles from './.module.scss'

export default ({
  className,
  size = 'tiny',
  title,
  buttonClass,
  confirmationEnabled = true
  , onConfirm, loading
  , onCancel, linkTitle, to, action,
  children,
  declineAction, declineActionUnderlined = true, setDisplayed
}) => {
  if (setDisplayed)
    var onClick = onCancel || (() => setDisplayed(false))

  const onConfirmOrTo = onConfirm || to

  return <Modal
    className={classNames(styles.modal, className)}
    {...{size}}
    open
    onClose={onClick}
  >
    <Modal.Content className={styles.modalContent}>
      <Headline {...(onConfirm && {className: styles.heading})}>
        {title}
      </Headline>
      {children}

      {(
        onConfirmOrTo || declineAction
      ) &&
        <div className={styles.modalFooter}>
          {onConfirmOrTo && (
            loading ?
              <Spinner/>
            :
              <ButtonWithArrow className={buttonClass}
                disabled={!confirmationEnabled}
                {...(
                  to && {
                    as: Link, title: linkTitle, to
                  }
                )}
                onClick={onConfirm}
              >
                {action}
              </ButtonWithArrow>
          )}

          {onClick && <p {...{
            className: classNames(
              styles.createNewAccount, {
                [styles.underlined]: declineActionUnderlined
              }
            ),
            onClick
          }}>
            {declineAction}
          </p>}
        </div>
      }
    </Modal.Content>
    {onClick &&
      <div className={'icon-cross ' + styles.closeIcon} {...{onClick}}/>
    }
  </Modal>
}