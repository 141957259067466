import api from '@application/index/asset/utils/api'
import { actions } from 'react-redux-form'
import { REQUIRED_GOOD_EXAMPLES_COUNT } from '@application/index/asset/constants'
import { validateProductStep, isProductStepValid } from '../validators/projectProductStep'
import { transformServerErrors } from '../validators/serverErrorsTransformer'

import { history } from '@application/index/index/history'

export const incrementSkipAndCancelledExamplesIndex = () => ({
  type: 'INCREMENT_SKIP_AND_CANCELLED_EXAMPLES_INDEX'
})

export const PROJECT_LOAD_REQUEST = 'PROJECT_LOAD_REQUEST'
export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS'
export const PROJECT_LOAD_FAILURE = 'PROJECT_LOAD_FAILURE'

export const loadProject = (id) => api.get({
  endpoint: `/api/v1/projects/${id}`,
  normalize: true,

  types: [
    PROJECT_LOAD_REQUEST,
    PROJECT_LOAD_SUCCESS,
    PROJECT_LOAD_FAILURE
  ]
})

export const PROJECT_CREATE_REQUEST = 'PROJECT_CREATE_REQUEST'
export const PROJECT_CREATE_SUCCESS = 'PROJECT_CREATE_SUCCESS'
export const PROJECT_CREATE_FAILURE = 'PROJECT_CREATE_FAILURE'

export const SET_PRODUCT_VALIDATION_ERRORS = 'SET_PRODUCT_VALIDATION_ERRORS'

const setProductValidationErrors = (payload) => ({
  type: SET_PRODUCT_VALIDATION_ERRORS,
  payload
})

export const create = (project) => api.post({
  endpoint: '/api/v1/public/projects',
  normalize: true,
  body: { project },

  types: [
    PROJECT_CREATE_REQUEST,
    PROJECT_CREATE_SUCCESS,
    PROJECT_CREATE_FAILURE
  ]
})

export const createProject = (productId, designerId, projectType = 'contest') =>
  async (dispatch, getState) => {
    var state = getState()

    const project = state.newProject.stepProductAttributes = {
      productId, designerId, projectType
    }

    , validation = validateProductStep(state)

    if (isProductStepValid(validation)) {
      const {error, payload} = await dispatch(create(project))

      if (error) {
        const errors = transformServerErrors(payload)

        if (errors)
          dispatch(setProductValidationErrors(errors))
      } else {
        const {
          projects: {current}
          , entities: {projectBuilderSteps, projects}
        } =
          getState()

        history.push(
          '/new-project/' + current + '/'
          + projectBuilderSteps[
            projects[current].currentStep
          ].path
        )
      }
    } else {
      dispatch(setProductValidationErrors(validation))
    }
  }

export const PROJECT_UPDATE_REQUEST = 'PROJECT_UPDATE_REQUEST'
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS'
export const PROJECT_UPDATE_FAILURE = 'PROJECT_UPDATE_FAILURE'

export const updateProjectBrandExamples = (id, openStep, project, upgradeProjectState = true) => api.patch({
  endpoint: `/api/v1/public/projects/${id}`,
  body: { project, upgradeProjectState, stepId: openStep.id },

  types: [
    PROJECT_UPDATE_REQUEST,
    PROJECT_UPDATE_SUCCESS,
    PROJECT_UPDATE_FAILURE
  ]
})

const removeExamplesFormError = () => actions.setValidity(
  'forms.newProjectExamplesStepParams',
  true
)

const generateMarkExampleAsFunction = (group) => (brandExampleId, openStep) => (dispatch, getState) => {
  dispatch({ type: `MARK_EXAMPLE_AS_${group.toUpperCase()}`, brandExampleId })

  const id = getState().projects.current
  const project = getState().newProject.examplesStep

  dispatch(updateProjectBrandExamples(id, openStep, project, false))
  dispatch(removeExamplesFormError())
}

export const markExampleAsBad = generateMarkExampleAsFunction('BAD')
export const markExampleAsGood = generateMarkExampleAsFunction('GOOD')
export const markExampleAsSkip = generateMarkExampleAsFunction('SKIP')
export const markExampleAsCancelled = generateMarkExampleAsFunction('CANCELLED')

export const PROJECT_DESTROY_REQUEST = 'PROJECT_DESTROY_REQUEST'
export const PROJECT_DESTROY_SUCCESS = 'PROJECT_DESTROY_SUCCESS'
export const PROJECT_DESTROY_FAILURE = 'PROJECT_DESTROY_FAILURE'

const destroyProject = (id) => api.delete({
  endpoint: `/api/v1/projects/${id}`,

  types: [
    PROJECT_DESTROY_REQUEST,
    PROJECT_DESTROY_SUCCESS,
    PROJECT_DESTROY_FAILURE
  ]
})

export const REMOVE_PROJECT_FROM_ENTITIES = 'REMOVE_PROJECT_FROM_ENTITIES'

const removeProjectFromEntities = (id) => ({
  type: REMOVE_PROJECT_FROM_ENTITIES,
  id,
})

export const deleteProject = (id) => (dispatch) => {
  dispatch(destroyProject(id)).then(() => {
    dispatch(removeProjectFromEntities(id))
  })
}

const updateProject = (id, project, step, upgradeProjectState = true) => api.patch({
  endpoint: `/api/v1/public/projects/${id}/${step}`,
  body: { project, upgradeProjectState },

  types: [
    PROJECT_UPDATE_REQUEST,
    PROJECT_UPDATE_SUCCESS,
    PROJECT_UPDATE_FAILURE
  ]
})

export const CHANGE_PROJECT_PRODUCT_ATTRIBUTE = 'CHANGE_PROJECT_PRODUCT_ATTRIBUTE'

export const changeProjectProductAttributes = (name, value) => ({
  type: CHANGE_PROJECT_PRODUCT_ATTRIBUTE,
  name,
  value
})
