import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'

import {className, header} from './.module.scss'

import JoinQueueModalContent from '@application/index/component/modal/reserve-spot/JoinQueueModalContent'
import ReserveSpotModalContent from './Content'

const ReserveSpotModal = ({ hideModal, project, me }) => {
  const areSpotsUnavailable = me.maxActiveSpotsCount !== null && me.activeSpotsCount >= me.maxActiveSpotsCount

  return (
    <Modal open size="small"
      className={'main-modal ' + className}
      onClose={hideModal}
    >
      <div className="modal-primary">
        { areSpotsUnavailable ? (
          <div className="modal-primary__body-block modal-primary__block-p-b text-center">
            <p className="modal-primary__text">
              You've reached the maximum amount of active spots: {me.maxActiveSpotsCount} out of {me.maxActiveSpotsCount}.
            </p>
            <button onClick={hideModal} className="main-button main-button--clear-black main-button--md">Ok</button>
          </div>
        ) : <>
          <div className={header}>Spot Reservation</div>

          {0 < project.spotsAvailable ?
            <ReserveSpotModalContent {...{project}}/>
          :
            <JoinQueueModalContent {...{project}}/>
          }
        </>}
      </div>
    </Modal>
  )
}

ReserveSpotModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired
}

export default ReserveSpotModal
