import React from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { prevProjectBuilderStepSelector } from '@application/index/asset/store/selectors/newProjectBuilder'

import styles from './BackButton.module.scss'

import Left from './Left'

const BackButton = () => {
  const { t } = useTranslation()
  const { step } = useParams()
  const pollId = useLocation().state

  return (
    <Link className={styles.btn} to={
      step ?
        useSelector(
          state => prevProjectBuilderStepSelector(state, step)
        ).path
      :
        pollId ?
          pollId
        :
          '../polls'
    }>
      <Left/> <span className={styles.label}>
        {t('back')}
      </span>
    </Link>
  )
}

export default BackButton
