import _ from 'lodash'
import { containsBannedRegexp, isAdult, isValidDate, buildDateOfBirth } from './helpers'
import { isEmail } from '@application/index/asset/utils/validators'

const validate = (
  properties, validation, {designer: {profileAttributes}}
) =>
  properties.forEach(property => {
    if (!profileAttributes[property]) {
      validation[property] = 'Required'
    }
  })

const validateDateOfBirth = (validation, state) => {
  const dateOfBirth = buildDateOfBirth(state.designer.profileAttributes.dateOfBirthMonth, state.designer.profileAttributes.dateOfBirthDay, state.designer.profileAttributes.dateOfBirthYear)

  if (!state.designer.profileAttributes.dateOfBirthYear) {
    validate(['dateOfBirthYear'], validation, state)
  } else if (!isValidDate(dateOfBirth)) {
    validation.dateOfBirthYear = 'Not valid date'
  } else if (!isAdult(dateOfBirth)) {
    validation.dateOfBirthYear = 'Sorry, you have to be at least 18 to use DesignBro'
  }
}

export const validateProfile = (state) => {
  const validation = {}

  if (!state.designer.profileAttributes.displayName) {
    validation.displayName = 'Please enter your user name'
  }

  if (!validation.displayName && containsBannedRegexp(state.designer.profileAttributes.displayName, /[.@]/)) {
    validation.displayName = '"." and "@" are not allowed'
  }

  validate([
    'firstName', 'lastName', 'countryCode', 'dateOfBirthDay',
    'dateOfBirthMonth'
  ], validation, state)

  validateDateOfBirth(validation, state)

  validate([
    'gender', 'portfolioLink', 'experienceEnglish'
  ], validation, state)

  return validation
}

export const isProfileValid = (validation) => {
  const attributes = [
    'displayName',
    'firstName',
    'lastName',
    'countryCode',
    'dateOfBirthDay',
    'dateOfBirthMonth',
    'dateOfBirthYear',
    'gender',
    'portfolioLink',
    'experienceEnglish'
  ]

  return _.every(_.pick(validation, attributes), _.isEmpty)
}

export const validateSettingsProfile = (state) => {
  const validation = {}

  validate(['email'], validation, state)

  if (state.designer.profileAttributes.email && !isEmail(state.designer.profileAttributes.email)) {
    validation.email = 'Not valid email'
  }

  validate([
    'firstName', 'lastName', 'dateOfBirthDay', 'dateOfBirthMonth'
  ], validation, state)

  validateDateOfBirth(validation, state)

  validate([
    'gender', 'portfolioLink', 'countryCode', 'city', 'address1',
    'phone'
  ], validation, state)

  return validation
}

export const isProfileSettingsValid = (validation) => {
  const attributes = [
    'email',
    'firstName',
    'lastName',
    'countryCode',
    'dateOfBirthDay',
    'dateOfBirthMonth',
    'dateOfBirthYear',
    'gender',
    'portfolioLink',
    'city',
    'address1',
    'phone'
  ]

  return _.every(_.pick(validation, attributes), _.isEmpty)
}
