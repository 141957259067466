import React, {useTransition} from 'react'
import {Router} from 'react-router-dom'

export default ({history, ...properties}) => {
	const [, startTransition] = useTransition()

	, {push} = history

	var historyWithTransition = history

	historyWithTransition.push = (...parameters) =>
		startTransition(() =>
			push.apply(history, parameters)
		)

	return <Router
		history={historyWithTransition} {...properties}
	/>
}