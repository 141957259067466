import { connect } from 'react-redux'
import { add, remove } from '@application/index/asset/store/actions/tags'
import Tags from './component'

const mapStateToProps = (state) => {
  return {
    tags: state.tags.names.map(name => ({ id: name, text: name }))
  }
}

export default connect(mapStateToProps, {
	add,
	remove
})(Tags)
