import { connect } from 'react-redux'
import { showModal, hideModal } from '@application/index/asset/store/actions/modal'
import { changeAttributes } from '@application/index/asset/store/actions/projectBuilder'
import BrandsModal from '../../BrandsModal'
import { getFinalistDesigners } from '@application/index/asset/store/selectors/finalistDesigners'

const mapStateToProps = (state) => {
  return {
    hasDesigners: getFinalistDesigners(state).length > 0
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onContinue: () => {
      const params = new URLSearchParams(document.location.search)
      const designerId = params.get('designer_id')

      dispatchProps.hideModal()
      if (stateProps.hasDesigners && !designerId) {
        dispatchProps.showModal('DESIGNER_OR_CONTEST_DECISION')
      } else {
        if (designerId) {
          dispatchProps.changeAttributes({ projectType: 'one_to_one', designerId: designerId })
        }

        dispatchProps.changeAttributes({ projectType: 'contest' })
      }
    },
  }
}

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  changeAttributes
}, mergeProps)(BrandsModal)
