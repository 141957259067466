import { combineReducers } from 'redux'

const defaultSettings = {
  paymentType: 'credit_card'
}

const settings = (state = defaultSettings, action) => {
  switch (action.type) {
  case 'VALIDATE_AUTH_SUCCESS': {
    const me = action.payload.data

    return {
      paymentType: me.attributes.preferredPaymentMethod || defaultSettings.paymentType
    }
  }

  default:
    return state
  }
}

export default combineReducers({
  settings,
})
