import React from 'react'

import { className } from './.module.scss'

import SelectMultiple from '@application/index/component/inputs/SelectMultiple'
import PropTypes from 'prop-types'

const Tags = ({ tags, add, remove }) => {
  return (
    <div {...{ className }}>
      <h5>Add at least 3 tags that describe the design, separated by comma’s.</h5>
      <p>Example: modern, clean, real estate, logo design, United States, blue</p>
      <SelectMultiple
        allowDragDrop={false}
        placeholder="Tags"
        delimiters={[10, 13, 188]}
        {...{tags}}
        onAdd={tag => add(tag.text)}
        onDelete={index => remove(tags[index].text)}
      />
    </div>
  )
}

Tags.propTypes = {
  id: PropTypes.number,
  uploadedFileId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  add: PropTypes.func,
  remove: PropTypes.func
}

export default Tags
