import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { useTranslation, Trans } from 'react-i18next'

import Headline from '../Headline'
import Input from '../Input'
import {valid, message} from '@application/index/component/inputs/password'
import usePassword from './usePassword'
import PasswordInput from '../PasswordInput'
import { ContinueButton } from '../Footer'

import Cross from '../Cross'

import { signupEmail } from '@application/index/asset/store/actions/auth'
import { onSuccess } from '@application/index/asset/store/actions/signup'
import { showSignInModal, hideModal } from '@application/index/asset/store/actions/modal'

import { isEmail, isWeak } from '@application/index/asset/utils/validators'

import styles from './SignUpModal.module.scss'

import useEmail from './useEmail'

import Checkbox from '@application/index/component/inputs/react-hook-form/Checkbox'

const inputNames = [
  'First Name',
  'Last Name'
]

const loginText = 'or login'
const hereText = 'here'

const SignUpModal = signUpModalProperties => {
  const { t } = useTranslation()
  , {
    title, names, successCallback, optional, projectLoading
  } =
    signUpModalProperties

  , dispatch = useDispatch()

  if (names) {
    var form = useRef()

    var [skip, setSkip] = useState()
  }

  const [email, setEmail] = useState('')
  , [password, setPassword] = useState('')

  , namesAndSkip = names && skip
  , noEmailAndPassword = !email && !password
  , isValid =
    (!namesAndSkip || skip)
      && email && isEmail(email)
      && password && isWeak(password)
      && valid(password)
    || namesAndSkip && noEmailAndPassword

  , handleSignInClick = () => {
    dispatch(hideModal())
    dispatch(showSignInModal({
      successCallback, projectLoading, signUpModalProperties
    }))
  }

  , LoginButton = properties =>
    <button
      className={styles.loginButton}
      onClick={handleSignInClick}
      {...properties}
    />

  , handleClose = () => {
    if (optional)
      successCallback()

    dispatch(hideModal())
  }

  , handleEmailChange = event =>
    setEmail(event.target.value)

  , [displayError, setDisplayError] = useState()

  , onSubmit = async event => {
    event.preventDefault()

    if ((optional || namesAndSkip) && noEmailAndPassword)
      successCallback()
    else {
      const response = await dispatch(
        signupEmail(
          'client',
          {
            ...(names && inputNames.reduce((result, name) => ({
              ...result,
              [
                name[0].toLowerCase()
                + name.substring(1).split(' ').join('')
              ]:
                form.current[name].value
            }), {})),
            email, password
          },
          projectLoading
        )
      )

      if (!response.error)
        dispatch(onSuccess(response, successCallback))
    }
  }

  return (
    <Modal
      size="tiny"
      open
      className={styles.modal}
      onClose={handleClose}
    >
      <Cross onClick={handleClose}/>

      <Modal.Content className={styles.modalContent}>
        <Headline>{t(title)}</Headline>

        {!names &&
          <div className={styles.alternative}>
            <Trans {...{t}}
              i18nKey="or login here"
              components={[<LoginButton/>]}
            />
          </div>
        }

        <form {...(names && {ref: form})} {...{onSubmit}}>
          <div className={styles.signUpForm}>
            {names && inputNames.map((name, index) =>
              <Input {...{
                key: name,
                className: styles.part
                  + (1 + index === inputNames.length ?
                    ' ' + styles.last
                  : ''),
                inputClassName: styles.input,
                name,
                placeholder: name,
                autoFocus: index === 0,
                disabled: skip
              }}/>
            )}

            <Input
              inputClassName={styles.input}
              value={email}
              onChange={handleEmailChange}
              placeholder={useEmail()}
              disabled={skip}
            />

            <PasswordInput
              statusLine
              inputClassName={styles.input}
              type="password"
              value={password}
              error={displayError && message}
              onChange={event => {
                const password = event.target.value

                setPassword(password)

                if (valid(password)) {
                  if (displayError)
                    setDisplayError(false)
                } else
                  if (!displayError)
                    setDisplayError(true)
              }}
              placeholder={usePassword()}
              disabled={skip}
            />
          </div>

          <div className={styles.modalFooter}>
            {false && names &&
              <Checkbox
                classes={{root: styles.checkbox}}
                onClick={() => setSkip(!skip)}
                label="or vote anonymous"
              />
            }
            {optional && noEmailAndPassword ?
              <ContinueButton isValid>
                {t('projectBuilder.Skip this step')} →
              </ContinueButton>
            :
              <ContinueButton {...{
                ...(names && {
                  className: styles.continueButton
                    + (isValid ? '' : ' ' + styles.invalid)
                }),
                isValid,
                popupContent: 'Please create your account before moving on'
              }}>
                {t('continue')} →
              </ContinueButton>
            }
            {names && <>
              <LoginButton>
                {loginText} {hereText}
              </LoginButton>
              <p>
                By joining you agree with our Terms and Privacy Policy. By joining you allow DesignBro to send you emails relating to the functioning of the platform as well as marketing. You can switch off receiving marketing emails in your account at any time.
              </p>
            </>}
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

SignUpModal.defaultProps = {
  title: 'projectBuilder.Create your account'
}

export default SignUpModal
