import api from '@application/index/asset/utils/apiV2'

export const LOAD_NDA_PRICES_REQUEST = 'project/builder/LOAD_NDA_PRICES_REQUEST'
export const LOAD_NDA_PRICES_SUCCESS = 'project/builder/LOAD_NDA_PRICES_SUCCESS'
export const LOAD_NDA_PRICES_FAILURE = 'project/builder/LOAD_NDA_PRICES_FAILURE'

export const loadNdaPrices = () => api.get({
  endpoint: '/api/v2/nda_prices',
  types: [LOAD_NDA_PRICES_REQUEST, LOAD_NDA_PRICES_SUCCESS, LOAD_NDA_PRICES_FAILURE]
})
