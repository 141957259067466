import { connect } from 'react-redux'

import { hideModal } from '@application/index/asset/store/actions/modal'
import { getMe } from '@application/index/asset/store/reducers/me'

import ReserveSpotModal from '@application/index/component/modal/reserve-spot'

const mapStateToProps = (state) => {
  return { me: getMe(state) }
}

const mapDispatchToProps = {
  hideModal
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ReserveSpotModal
)
