import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import MaterialRadio from '@application/index/component/inputs/MaterialRadioPlain'
import MaterialTextarea from '@application/index/component/inputs/MaterialTextareaPlain'

import { hideModal } from '@application/index/asset/store/actions/modal'

const path = 'client.project.modal.'
, mainPath = path + 'blockDesigner'

const BlockDesignerModal = ({ onClose, onConfirm, design, project, history }) => {
  const {t} = useTranslation()
  , {
    options, decline, confirm
  } =
    t(mainPath, {designer: null})

  const [reason, setReason] = useState(null)
  const [blockCustomReason, setBlockCustomReason] = useState(null)

  const onChange = (name, value) => {
    setReason(value)
    name === 'other' || setBlockCustomReason(null)
  }

  const handleCustomReasonChange = (name, value) => {
    setBlockCustomReason(value)
  }

  const isFormInvalid = !reason || (reason === 'other' && !blockCustomReason)

  const handleBlockDesigner = () => {
    const blockData = {
      blockReason: reason,
      blockCustomReason: blockCustomReason,
    }
    if (!isFormInvalid) {
      onConfirm(blockData).then(() => {
        history.push(`/c/projects/${project.id}`)
        onClose()
      })
    }
  }

  return (
    <Modal id="block-designer-modal" className="main-modal main-modal--xs" size="small" open onClose={onClose}>
      <div className="modal-primary">
        <div className="modal-primary__header conv-modal-primary-header bg-green-500 in-white">
          <p className="modal-primary__header-title">
            {t(path + 'title')}
          </p>
        </div>
        <div className="modal-primary__body">
          <div className="modal-primary__body-block conv-confirmation-modal-primary-body-block">
            <p className="in-grey-200 font-14">
              {t(mainPath + '.description', {
                designer: design.designerName
              })}
            </p>
            {Object.entries(options).map(
              ([name, label]) =>
                <MaterialRadio key={name}
                  {...{name, label, onChange}}
                  value={name}
                  checked={reason === name}
                />
            )}
            {reason === 'other' &&
              <MaterialTextarea
                type="text"
                value={blockCustomReason}
                name="eliminate_custom_reason"
                onChange={handleCustomReasonChange}
                error={
                  reason === 'other'
                  && !blockCustomReason &&
                    t('Required')
                }
              />
            }
          </div>
        </div>
        <div className="modal-primary__actions conv-modal-primary-actions conv-confirmation-primary-actions align-items-start">
          <button className="main-button-link main-button-link--lg main-button-link--grey-black m-b-10" type="button" onClick={onClose}>
            {decline}
          </button>
          <button
            id="modal-confirm"
            className="main-button-link main-button-link--lg main-button-link--black-pink m-b-10"
            type="button"
            onClick={handleBlockDesigner}
            disabled={isFormInvalid}
          >
            {confirm}
            <i className="m-l-20 font-8 icon-arrow-right-long" />
          </button>
        </div>
      </div>
    </Modal>
  )
}

BlockDesignerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default withRouter(connect(null, {
  onClose: hideModal
})(BlockDesignerModal))
