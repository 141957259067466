import React from 'react'

const size = 45
, stopColor = '#808080'

export default ({className}) =>
	<svg
		className={'spinner-img'
			+ (className ? ' ' + className : '')
		}
		width={size} height={size}
		viewBox={'0 0 ' + size + ' ' + size}
	>
		<linearGradient id="linear-gradient">
			<stop offset="0%" {...{stopColor}}/>
			<stop offset="100%" {...{stopColor}}
				stopOpacity="0"
			/>
		</linearGradient>

		<path
			fill="url(#linear-gradient)"
			d="M0,22.5A22.5,22.5,0,1,0,22.5,0,22.5,22.5,0,0,0,0,22.5Zm41,0A18.5,18.5,0,1,1,22.5,4,18.5,18.5,0,0,1,41,22.5Z"
		/>
	</svg>