import React, { useState } from 'react'
import classNames from 'classnames'
import { Modal } from 'semantic-ui-react'
import styles from './UpsellModal.module.scss'
import Button from '../../../../../../components/Button'
import { useTranslation } from 'react-i18next'

const UpsellModal = ({
  id,
  title,
  count,
  minCount,
  maxCount,
  totalCost,
  onUp,
  onDown,
  onContinue,
  onClose,
  productKey
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open
      className={classNames('main-modal main-modal--xs')}
      size="small"
      onClose={onClose}
    >
      <div className={styles.modalBody}>
        {onClose && <div className={classNames('icon-cross', styles.closeIcon)} onClick={onClose} />}

        <div className={styles.modalHeader}>
          <h3>{t('projectBuilder.' + productKey + '.How many')}</h3>
        </div>

        <p className={styles.hint}>{t('projectBuilder.' + productKey + '.Select the size')}</p>
        <p className={styles.hint}>{t('projectBuilder.' + productKey + '.Select the number')}</p>

        <div className={styles.modalContent}>
          <div className={styles.counter}>
            <div>
              <button
                className={styles.buttonDown}
                disabled={count <= minCount}
                onClick={onDown}
              />
            </div>

            <div className={styles.count}>{count}</div>

            <div>
              <button
                className={styles.buttonUp}
                disabled={count >= maxCount}
                onClick={onUp}
              />

            </div>
          </div>

          <div className={styles.totalCost}>
            {totalCost}
          </div>

          <Button
            className={styles.continueBtn}
            onClick={onContinue}
          >
            {t('continue')} →
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default UpsellModal
