import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { OuterClick } from 'react-outer-click'

import styles from './.module.scss'

import {
  Home, InProgress, Drafts, Polls, Files, Completed, NewProject
} from
  './icon/menu'

import { Down, Up } from './icon/arrow'

import { ReactComponent as ArrowLeft } from './icon/arrowLeft.svg'

import useNarrowScreen from '../../useNarrowScreen'

import clientEndpoint from '@application/index/component/index/clientEndpoint'
import useApi from '@application/index/function/useApi'

import LinkWithRelation from '@application/index/component/index/LinkWithRelation'

import { home, newProject } from '../shared'

import SideBarContent from '../SideBarContent'

const layout = 'layout.'

, homeTextKey = layout + 'Home'

, inProgress = 'inProgress'

, pollsText = 'Polls'
, files = 'Files'
, drafts = 'Drafts'

, completed = 'completed'

, useLinks = (path, type) =>
  useApi(type + '_LINKS', clientEndpoint + path)

, notNewTab = event => event.which !== 2 && !event.ctrlKey

export default ({ open, setOpen }) => {
  const { t } = useTranslation()
  const pathName = useLocation().pathname
  const [content, setContent] = useState()
  const [sideBarOpen, setSideBarOpen] = useState()

  const narrowScreen = useNarrowScreen()

  const [links, setLinks] = useState()

  const closeSideBar = () => setSideBarOpen(false)

  const polls = content === pollsText
  const contentFiles = content === files
  const contentDrafts = content === drafts

  return <div className={classNames(styles.navigationMenu, {
    [styles.open]: open
  })}><OuterClick as="nav" onOuterClick={closeSideBar}>
    <nav className={styles.menu}>
      {[
        [homeTextKey, Home, '', ['']]
        , [inProgress, InProgress, '', [
          undefined, 'in_progress'
        ]]
        , [drafts, Drafts, layout + 'item.']
        , [pollsText, Polls]
        , [files, Files]
        , [completed, Completed, '']
        , ['New Project', NewProject, undefined, ['']
          , newProject
        ]
      ].map(([
        key, Icon, prefix = layout, [contentName, path] = []
        , to = home
      ], index) => {
        if (contentName != '')
          contentName = key

        path = path ?? contentName

        const mainHomeLink = key === homeTextKey
        const currentPath = pathName === to
        const current = content === contentName

        const isHome = to === home

        const selected = !content && currentPath && (
            !isHome || mainHomeLink
          )
          || content && sideBarOpen && content === contentName
          || !sideBarOpen && currentPath && isHome && mainHomeLink

        , title = t(prefix + key)

        if (path) {
          var contentNameIsPollsText =
            contentName == pollsText

          var typeLinks = useLinks(
            ...contentNameIsPollsText ? [
              'polls', 'POLL'
            ] : (() => {
              const type =
                path.toLowerCase().split(' ').join('_')

              return [
                'projects/' + type, type.toUpperCase()
              ]
            })()
          )
        }

        const [onClick, setOnClick] = useState()

        useEffect(() => {
          if (onClick && typeLinks) {
            setOnClick(false)
            setLinks(
              contentNameIsPollsText ?
                [
                  'started', 'finished'
                ].reduce((result, type) => ({
                  ...result,
                  [type]:
                    typeLinks.filter(
                      ({state}) => state === type
                    )
                }), {})
              :
                typeLinks
            )
          }
        }, [
          onClick, typeLinks
        ])

        return <div {...{key
          , className: classNames(styles.item, {
            [styles.current]: selected
          })
        }}>
          <LinkWithRelation {...{key
            , title, to
            , ...(
              (
                contentName || mainHomeLink && currentPath
              ) && {
                onClick: event => {
                  if (notNewTab(event)) {
                    event.preventDefault()

                    if ((!current || !sideBarOpen) && contentName) {
                      setOnClick(true)
                      if (!current)
                        setContent(contentName)
                    }

                    if (!mainHomeLink || (mainHomeLink && sideBarOpen)) {
                      setSideBarOpen(!mainHomeLink && (
                        !current || current && !sideBarOpen
                      ))
                    }
                  }
                }
              }
            )
          }}>
            <Icon/>
            <h2>{title}</h2>
            {narrowScreen && contentName && (() => {
              const Arrow = selected ? Up : Down

              return <Arrow className={styles.arrow}/>
            })()}
          </LinkWithRelation>
          {narrowScreen && contentName && <nav>
            <SideBarContent
              {...{polls, links, contentFiles, contentDrafts}}
              onClick={event => {
                if (notNewTab(event))
                  setOpen(false)
              }}
            />
          </nav>}
        </div>
      })}
    </nav>
    <div className={classNames(styles.sideBar, {
      [styles.open]: sideBarOpen
    })}>
      <div className={styles.main}><nav>
        {content && (() => {
          const inProgressContent = content === inProgress

          , condition = !polls && inProgressContent
          , otherCondition = !polls && !(
            inProgressContent || contentFiles
          )

          return <>
            <header>
              <h3>
                {(condition || otherCondition) ? <>
                  {condition &&
                    t(layout + inProgress, {
                      projectsText: t('projectsText')
                    })
                  }
                  {otherCondition && (() => {
                    const completedContent =
                      content == completed

                    return t(layout + 'projects', {
                      type: t(
                        (contentDrafts || completedContent ?
                          layout
                        : '')
                        + content
                        , completedContent && {
                          completed: t(completed)
                        }
                      )
                      , projectsText: t('projectsText')
                    })
                  })()}
                </> :
                  t(layout + content)
                }
              </h3>
              <ArrowLeft onClick={closeSideBar}/>
            </header>
            <SideBarContent {...{
              polls, links, contentFiles, contentDrafts
            }}/>
          </>
        })()}
      </nav></div>
      <div className={styles.shadowArea} onClick={closeSideBar}/>
    </div>
  </OuterClick></div>
}
