export const brandBackgroundColors = [
  '#74B9FF',
  '#FF7675',
  '#FFB176',
  '#8EE8A0',
  '#FDCF88',
  '#AFA9FE',
  '#C3CCD0',
  '#FFAFD8',
  '#6ADCD5'
]
