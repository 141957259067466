import { connect } from 'react-redux'

import { upload, destroy } from '@application/index/asset/store/actions/featuredImage'

import FeaturedImageForm from './component'
import { getFeaturedImage } from '@application/index/asset/store/selectors/featuredImage'

const mapStateToProps = (state) => ({
  canContinue: !!getFeaturedImage(state).uploadedFileId && state.tags.names.length >= 3
})

export default connect(mapStateToProps, {
  upload,
  destroy
})(FeaturedImageForm)
