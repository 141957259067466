import React, {useState} from 'react'

import Offer from './Offer'

import Share from './Share'

export default ({rating, comment, subscriptionActive, ...properties}) => {
  const [offerDisplayed, setOfferDisplayed] = useState(true)

  return 5 <= rating && offerDisplayed && !subscriptionActive ?
    <Offer setDisplayed={setOfferDisplayed} {...{comment}}/>
  :
    <Share {...properties}/>
}
