import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { bindActionCreators } from 'redux'

import { createReview } from '@application/index/asset/store/actions/reviews'
import { hideModal, showModal } from '@application/index/asset/store/actions/modal'

import LeaveReviewModal from './component'

const onConfirm = ({design}) => () => (dispatch, getState) => {
  const review = getState().forms.review
  const data = { ...review, designId: design.id }
  const subscriptionActive = getState().me.me.subscriptionActive

  dispatch(createReview(data)).then((response) => {
    if (response.error) {
      return dispatch(
        actions.setSubmitFailed(
          'forms.review'
        )
      )
    }

    dispatch(hideModal())

    const {
      overallRating: rating
      , overallComment: comment
    } =
      data

    dispatch(
      showModal('SUCCESSFUL_REVIEW', {rating, comment, subscriptionActive})
    )
  })
}

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators({
    onClose: hideModal, onConfirm: onConfirm(props)
  }, dispatch)

export default connect(null, mapDispatchToProps)(
  LeaveReviewModal
)
