import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '@application/index/component/index/Footer/useTranslate'

import MaterialModal from './Material'
import PrivacyPolicyModalContent from '@application/index/component/static/signup/PrivacyPolicyModalContent'

const PrivacyPolicyModal = ({linkClasses = null}) =>
  <MaterialModal
    {...{linkClasses}}
    trigger={useTranslate()('Privacy Policy')}
    title={
      <span className="policy-modal__header">
        designbro.com Privacy Policy
      </span>
    }
    value={<PrivacyPolicyModalContent/>}
  />

PrivacyPolicyModal.propTypes = {
  linkClasses: PropTypes.string
}

export default PrivacyPolicyModal
