import domain from '../domain'

import translation from './translation'

export default
	Object.values(domain).reduce(
		(result, language) => ({
			...result
			, [language]: {
				translation: translation[language]
			}
		})
	, {})