import {useDispatch} from 'react-redux'

import api from '@application/index/asset/utils/apiV2'

export default () => {
	const dispatch = useDispatch()

	return (method, type, endpoint, body) =>
		dispatch(
			api[method]({endpoint, type, body})
		)
}