export default {
  'English': 'English',
  'Dutch': 'Dutch',
  'Are you sure you want to skip': 'Are you sure you want to skip?',
  'Yes, skip': 'Yes, skip',
  'No, go back': 'No, go back',
  'Required': 'Required',
  'or login here': 'or login <0>here</0>',
  notEmailAddress: "Doesn't look like email"
  , sendInvitation: 'Send invitation'
  , replaceDesign: 'Replace Design'
  , header: {
    title: 'Start your Design Project - World’s Best 5% Creative Designers'
    , 'Account Settings': 'Account Settings',
    'My Brands': 'My Brands',
    'Transactions': 'Transactions',
    'Logout': 'Logout'
  },
  continue: 'Continue'
  , back: 'Back'
  , projectBuilder: {
    'projectBuilder_tooltip': "This is the average number of designs to choose from. At the end of the project you'll receive the final files for 1 design.",
    '99% happy customers': '99% happy customers. We want to keep it that way. Conditions apply',
    'A brand new design': 'A brand new design',
    'Add more': 'Add more',
    'Additional screens': 'Additional Screens',
    'Additional variants': 'Additional Variants',
    'Awesome!': 'Awesome!',
    'Billing address': 'Billing address',
    'Blind Project': 'Blind Project',
    'Bold': 'Bold',
    'Book now': 'Book now',
    'Book your call with one of our experts': 'Book your call with one of our experts',
    'Brand description': 'Brand description',
    'Brand name': 'Brand name',
    'Business': 'Business',
    'Can you show us inspirational colors you think look great?': 'Do you want to pick colors for your design?',
    'Can you show us inspirational designs you think look great?': 'Can you show us inspirational designs you think look great?',
    'Can you show us your competitors?': 'Can you show us your competitors?',
    'Can you tell us about the brand': 'Can you tell us about the brand?',
    'Change existing design': 'Change existing design',
    'Checkout': 'Checkout',
    'Clean': 'Clean',
    'Comment (optional)': 'Comment (optional)',
    'Company name (optional)': 'Company name (optional)',
    'Country': 'Country',
    'Create new account': 'Create new account',
    'Create new brand': 'Create new brand',
    'Create your account': 'Create your account',
    'Dedicated account manager': 'Dedicated account manager',
    'Delivered by our full agency partner': 'Delivered by our full agency partner Gency.co',
    'Describe your brand DNA': 'Describe your brand DNA',
    'Design': 'Design',
    'Detailed': 'Detailed',
    'Discount': 'Discount',
    'Do you have a tagline, slogan or any additional text for your logo': 'Do you have a tagline, slogan or any additional text for your logo?',
    'Do you have any ideas or special requirements for the designers?': 'Do you have any ideas or special requirements for the designers?',
    'eg style guides, moodboards, your own scribbles etc': 'eg. style guides, moodboards, your own scribbles etc.',
    'eg ‘Founded in 2020’ or ‘The best bakery in town’': 'eg. ‘Founded in 2020’ or ‘The best bakery in town’.',
    'Email': 'Email',
    'Expect 10 - 14 designs': 'Expect 10 - 14 designs',
    'Expect 3 - 6 designs': 'Expect 3 - 6 designs',
    'Expect 5 - 8 designs': 'Expect 5 - 8 designs',
    'Expect 5 designs': 'Expect 5 designs',
    'Expect the first designs within 24-48 hours so sit back & relax or read our tips & tricks to get the most out of your project': 'Expect the first designs within 24-48 hours so sit back & relax or read our tips & tricks to get the most out of your project',
    'fair pass': 'Fair Pass',
    'Feminine': 'Feminine',
    'First name': 'First name',
    'Fit in the category': 'Fit in the category',
    'Free upgrade': 'Free upgrade',
    'Free': 'Free',
    'From 10+ professional designers': 'From 10+ professional designers',
    'From 3+ professional designers': 'From 3+ professional designers',
    'From 5 hand-picked designers': 'From 5 hand-picked designers',
    'From 5+ professional designers': 'From 5+ professional designers',
    'Full copyright': 'Full copyright',
    'Give us a bit of background, what makes you special': 'Give us a bit of background, what makes you special?',
    'Go to my projects': 'Go to my projects',
    'Got a discount code': 'Got a discount code?',
    'Handcrafted': 'Handcrafted',
    'High income': 'High income',
    'hmm.. We really need to know your brand name before we move on': 'hmm.. We really need to know your brand name before we move on',
    'Ideas or special requirements (optional)': 'Ideas or special requirements (optional)',
    'Larger designer prize': 'Larger designer prize',
    'Last Name': 'Last Name',
    'Let’s upload more items': 'Let’s upload more items',
    'Low income': 'Low income',
    'Made to order solution': 'Made to order solution',
    'Masculine': 'Masculine',
    'Mature': 'Mature',
    'Max file size': 'Max file size',
    'Minimalist': 'Minimalist',
    'Modern': 'Modern',
    'Money back guarantee': 'Money back guarantee',
    'Money back guarantee*': 'Money back guarantee*',
    'Move the sliders to let us know which of the following best fits the image / identity of the brand you are trying to create': 'Move the sliders to let us know which of the following best fits the image / identity of the brand you are trying to create',
    'Move the sliders to let us know which of the following best shows who your customer is': 'Move the sliders to let us know which of the following best shows who your customer is',
    'Neutral': 'Neutral',
    'No, don’t show competitors': 'No, don’t show competitors',
    'No, don’t show inspiration': 'No, don’t show inspiration',
    'No, let the designer decide': 'No, let the designer decide',
    'oops': 'oops...',
    'oops Select at least': 'oops... Select at least {{count}} logo designs that you like before we continue',
    'or enter your color (codes) manually': 'or enter your color (codes) manually',
    'Password': 'Password',
    'Pay now & start': 'Pay now & start',
    'Payment methods accepted': 'Payment methods accepted',
    'Playful': 'Playful',
    'Please create your account before checking out': 'Please create your account before checking out',
    'Please fill in the required fields before being able to pay & start': 'Please fill in the required fields before being able to pay & start',
    'Popular': 'Popular',
    'Premium': 'Premium',
    'Priority support': 'Priority support',
    'Pro': 'Pro',
    'Project intake call with a brand consultant': 'Project intake call with a brand consultant',
    'Project price': 'Project price',
    'Refined': 'Refined',
    'refund policy': 'refund policy',
    'Request intake call': 'Request intake call',
    'Required': 'Required',
    'Save your progress': 'Save your progress',
    'See our': 'See our',
    'Select at least': 'Select at least {{count}} designs that you think fit your brand to help the designers understand the visual style',
    'Select color': 'Select color',
    'Select designs you like': 'Select designs you like',
    'Select your brand below': 'Select your brand below',
    'Select your color here': 'Select your color here',
    'Select': 'Select',
    'Selected': 'Selected',
    'Serious': 'Serious',
    'Show your competitors to help the designers understand the market in which you operate': 'Show your competitors to help the designers understand the market in which you operate',
    'Skip this step': 'Skip this step',
    'Stand out from the crowd': 'Stand out from the crowd',
    'Standard NDA': 'Standard NDA',
    'Starter': 'Starter',
    'strong pass': 'Strong Pass',
    'Summary': 'Summary',
    'Super modern': 'Super modern',
    'Supported file types': 'Supported file types',
    'Tagline, slogan or additional text (optional)': 'Tagline, slogan or additional text (optional)',
    'Take me to checkout': 'Take me to checkout',
    'Tell us about your target audience': 'Tell us about your target audience',
    'Tell us in which language your briefing will be': 'Tell us in which language your briefing will be',
    tip: 'Pro tip: make your briefing in English to reach more designers'
    , 'Tell us the brand name you need in your logo': 'Tell us the brand name you need in your logo',
    'This file is too big': 'This file is too big',
    'This file type is not supported': 'This file type is not supported',
    'This would really help the designers and would likely give better results for your project': 'This would really help the designers and would likely give better results for your project.',
    'Tips & Tricks': 'Tips & Tricks',
    'Top designers': 'Top designers',
    'Total': 'Total',
    'Traditional': 'Traditional',
    'Unlimited revisions': 'Unlimited revisions',
    'Upgrade Pack': 'Upgrade Pack',
    'Upload your additonal items here': 'Upload your additonal items here',
    'Upload your competitors here': 'Upload your competitors here',
    'Upload your existing design here': 'Upload your existing design here',
    'Upload your existing logo here': 'Upload your existing logo here',
    'Upload': 'Upload',
    'Value': 'Value',
    'VAT number (optional)': 'VAT number (optional)',
    'VAT': 'VAT',
    'Very traditional': 'Very traditional',
    'weak pass': 'Weak Pass',
    'Welcome back': 'Welcome back!',
    'What are we going to create for you?': 'What are we going to create for you?',
    'What is it that you do': 'What is it that you do?',
    'Which countries will your design be aimed at': 'Which countries will your design be aimed at?',
    'Which design pack would you like?': 'Which design pack would you like?',
    'Would you like to upload anything else for the designers': 'Would you like to upload anything else for the designers?',
    'Yes, I can show competitors': 'Yes, I can show competitors',
    'Yes, I can show inspiration': 'Yes, I can show inspiration',
    'Yes, let’s pick some color': 'Yes, let’s pick some color',
    'Yes, skip': 'Yes, skip',
    'You can drag & drop the file from your computer': 'You can drag & drop the file from your computer',
    'You save': 'You save {{amount}}',
    'You selected a maximum of': 'You selected a maximum of {{count}} designs, first deselect a design if you want to change your selection',
    'Youthful': 'Youthful',
    'You’re good to go!': 'You’re good to go!',
    'We plant 1 tree': 'We plant 1 tree',
    'every design project you start': 'every design project you start',
    'Change': 'Change',
    'Make your project a secret (NDA)': 'Make your project a secret (NDA)',
    'For just {{price}} you can keep your project a total secret': 'For just {{price}} you can keep your project a total secret with our ‘non disclosure agreement’. This way your project will stay out of any google searches, and also the designers have to agree to the secrecy terms before being able to read your briefing. See the text <button>here</button>.',
    'Added': 'Added',
    'Add': 'Add',
    'Full Agency': 'Full Agency',
    'Yes, let me upload my images': 'Yes, let me upload my images',
    "No, I don't yet have images": "No, I don't yet have images",
    'Upload your inspiration here': 'Upload your inspiration here',
    'Brand Identity Design Brief': 'Brand Identity Design Brief',
    'Complete the questions and get started!': 'Complete the questions and get started!',
    'Business card (front side)': 'Business card (front side)',
    'Which details would you like on the front?': 'Which details would you like on the front?',
    'Business card (back side)': 'Business card (back side)',
    'Which details would you like on the back?': 'Which details would you like on the back?',
    'Letterhead': 'Letterhead',
    'Which details would you like on the Letterhead (top and bottom)?': 'Which details would you like on the Letterhead (top and bottom)?',
    'Compliment/note slip': 'Compliment/note slip',
    'What details would you like on the compliment slip?': 'What details would you like on the compliment slip?',
    'Please fill in all mandatory fields': 'Please fill in all mandatory fields',
    'Would you like the designers to use stock images?': 'Would you like the designers to use stock images?',
    'Yes, use stock images': 'Yes, use stock images',
    "No, I don't need any images": "No, I don't need any images",
    'Free stock images only': 'Free stock images only',
    'Free or paid stock images': 'Free or paid stock images',
    'Expect to pay +/- $50 per photo': 'Expect to pay +/- $50 per photo',
    'Our designers would be more than happy to help you find professional images': "Our designers would be more than happy to help you find professional images that fit your brand from free or paid resources. For the paid resources they would look at large stock photography websites. They will be able to provide you with a cost indication and once you approve an image they will send you a link and you will be able to buy the image directly from the supplier. DesignBro or the designers are not affiliated with these third parties and do not earn a commission on these purchases.",
    'Lets get started': 'Let\'s get started',
    'Upload your images or photos here': 'Upload your images or photos here',
    'Rate the design': 'Rate the design',
    'Or start a logo project first': 'Or start a logo project first',
    'packaging': {
      'Prices based on': 'Prices based on {{count}} variants',
      'How many': 'How many variants would you like in total?',
      'Select the size': '1 design (for 1 size or flavor) is included.',
      'Select the number': 'Select the total number of designs you would like to have designed'
    },
    'website': {
      'Prices based on': 'Prices based on {{count}} screens',
      'How many': 'How many screens would you like?',
      'Select the size': 'Please select the size would you like!',
      'Select the number': 'Select the number of screen you would like to have designed (eg. homepage, about us, contact, loading screen, etc.)'
    },
    'Popular': 'Popular',
    'Upload your technical drawing here': 'Upload your technical drawing here',

    'Brand DNA': {
      'hint': 'Move the sliders to let us know which of the following best fits the image / identity of the brand you are trying to create.'
    },
    'What size': {
      questions: {
        'album-cover': 'What size would you like your album cover to be?',
        'book-cover': 'What size would you like your book cover to be?',
        'business-card': 'What’s going to be the size of your business card?',
        'magazine-cover': 'What size would you like your magazine cover to be?',
        't-shirt': 'What size would you like your t-shirt to be?',
        billboard: 'What size would you like your billboard to be?',
        flyer: 'What size would you like your flyer to be?',
        menu: 'What size would you like your menu to be?',
        poster: 'What size would you like your poster to be?'
      },
      placeholders: {
        'album-cover': 'eg. 3000 by 3000 pixels',
        'book-cover': 'eg. 10 inches by 12 inches',
        'business-card': 'eg. 3.5 inches by 2 inches',
        'magazine-cover': 'eg. 8.5 inches by 11 inches',
        't-shirt': 'eg. 10 inches by 12 inches',
        billboard: 'eg. 10 feet by 6.6 feet',
        flyer: 'eg. 5 inches by 3 inches',
        menu: 'eg. 9 inches by 12 inches',
        poster: 'eg. US Poster:  18" x 24" or EU A2 Poster: 420 x 594 mm'

      }
    },
    'What is it for': {
      questions: {
        'album-cover': 'What\'s your album cover for?',
        'book-cover': 'What\'s your book cover for?',
        'business-card': 'What\'s your business card for?',
        'magazine-cover': 'What\'s your magazine cover for?',
        't-shirt': 'What\'s your t-shirt for?',
        billboard: 'What\'s your billboard for?',
        flyer: 'What\'s your flyer for?',
        menu: 'What\'s your menu for?',
        poster: 'What\'s your poster for?',
        packaging: 'What are we creating?',
        'instagram-post': 'What\'s your Instagram post for?',
        'facebook': 'What\'s your Facebook brand page for?',
        'twitter': 'What\'s your Twitter profile for?',
        'linkedin': 'What\'s your LinkedIn company page for?',
        'youtube': 'What\'s your YouTube channel for?',
        'website-banner': 'What\'s your website banner for?',
        'packaging-additional-variant': 'What are we creating'
      },
      hints: {
        'album-cover': 'Tell us a little bit more about your album cover project, think of it as the ‘what?’ and the ‘why?’ to your project',
        'book-cover': 'Tell us a little bit more about your book cover project, think of it as the ‘what?’ and the ‘why?’ to your project',
        'business-card': 'Tell us a little bit more about your business card project, think of it as the ‘what?’ and the ‘why?’ to your project',
        'magazine-cover': 'Tell us a little bit more about your magazine cover project, think of it as the ‘what?’ and the ‘why?’ to your project',
        't-shirt': 'Tell us a little bit more about your t-shirt project, think of it as the ‘what?’ and the ‘why?’ to your project',
        billboard: 'Tell us a little bit more about your billboard project, think of it as the ‘what?’ and the ‘why?’ to your project',
        flyer: 'Tell us a little bit more about your flyer project, think of it as the ‘what?’ and the ‘why?’ to your project',
        menu: 'Tell us a little bit more about your menu project, think of it as the ‘what?’ and the ‘why?’ to your project',
        poster: 'Tell us a little bit more about your poster project, think of it as the ‘what?’ and the ‘why?’ to your project',
        packaging: 'Tell us about your product. What are we creating, what’s special about it? The more information we have, the better the results.',
        'instagram-post': 'Tell us a little bit more about your Instagram post, think of it as the ‘what?’ and the ‘why?’ to your project',
        'facebook': 'Tell us a little bit more about your Facebook brand page, think of it as the ‘what?’ and the ‘why?’ to your project',
        'twitter': 'Tell us a little bit more about your Twitter profile, think of it as the ‘what?’ and the ‘why?’ to your project',
        'linkedin': 'Tell us a little bit more about your LinkedIn company page, think of it as the ‘what?’ and the ‘why?’ to your project',
        'youtube': 'Tell us a little bit more about your YouTube channel, think of it as the ‘what?’ and the ‘why?’ to your project',
        'website-banner': 'Tell us a little bit more about your website banner, think of it as the ‘what?’ and the ‘why?’ to your project',
        'packaging-additional-variant': 'Tell us about your product. What are we creating, what’s special about it? The more information we have, the better the results.'
      },
      placeholders: {
        'album-cover': 'Album cover reason',
        'book-cover': 'Book cover reason',
        'business-card': 'Business card reason',
        'magazine-cover': 'Magazine cover reason',
        't-shirt': 'T-shirt reason',
        billboard: 'Billboard reason',
        flyer: 'Flyer reason',
        menu: 'Menu reason',
        poster: 'Poster reason',
        packaging: 'Packaging reason',
        'instagram-post': 'Instagram post reason',
        'facebook': 'Facebook brand page reason',
        'twitter': 'Twitter profile reason',
        'linkedin': 'LinkedIn company page reason',
        'youtube': 'YouTube channel reason',
        'website-banner': 'Website banner reason',
        'packaging-additional-variant': 'Packaging reason'
      }
    },
    'Technical Drawing': {
      'questions.packaging': 'Do you have existing technical drawings (or die-cuts) or an image of the pack you’re planning to use?'
      , 'yes.packaging': 'I can share a technical drawing or image'
      , 'no.packaging': 'I don’t have a technical drawing or image',
      'questions.packaging-additional-variant': 'Do you have existing technical drawings (or die-cuts) or an image of the pack you’re planning to use?',
      'yes.packaging-additional-variant': 'I can share a technical drawing or image',
      'no.packaging-additional-variant': 'I don’t have a technical drawing or image'
    },
    'Technical details': {
      'questions.packaging': 'Can you tell us what type of pack you’re planning to use, is it a can, a bottle, box, label or something else?'
      , 'hints.packaging': 'Also please indicate sizing if you have it, or place a link to the pack if you have it.'
      , 'placeholders.packaging': ''
    },
    'Product text': {
      questions: {
        'album-cover': 'What text would you like to feature on your album cover?',
        'book-cover': 'What text would you like to feature on your book cover?',
        'business-card': 'What text would you like to feature on your business card?',
        'magazine-cover': 'What text would you like to feature on your magazine cover?',
        't-shirt': 'What text would you like to feature on your t-shirt?',
        billboard: 'What text would you like to feature on your billboard?',
        flyer: 'What text would you like to feature on your flyer?',
        menu: 'What text would you like to feature on your menu?',
        poster: 'What text would you like to feature on your poster?',
        website: 'What text would you like to feature on your website?',
        'website-banner': 'What text would you like to feature on your website banner?',
        'instagram-post': 'What text would you like to feature on your Instagram post?',
        'website-additional-screens': 'What text would you like to feature on your website?'
      },
      hints: {
        'album-cover': 'Let us know what text you would like to feature on your album cover',
        'book-cover': 'You can specify what you want on the front, spine and back of your book cover',
        'business-card': 'You can specify what you want on the front and back of your business card',
        'magazine-cover': 'Let us know what text you would like to feature on the front cover of your magazine',
        't-shirt': 'You can specify what text and logo you want to want to print on your t-shirt',
        billboard: '',
        flyer: '',
        menu: 'You can specify what you want on each page/side of the menu',
        poster: '',
        website: 'If you don’t have any final text yet, don’t worry, just ask the designer to use sample text!',
        'website-banner': 'For instance: I would like to create a banner that tells customers about the special deal they get when they register through our website.',
        'instagram-post': 'What text would you like to feature on your Instagram post?',
        'website-additional-screens': 'If you don’t have any final text yet, don’t worry, just ask the designer to use sample text!'
      },
      placeholders: {
        'album-cover': 'Album cover text',
        'book-cover': 'Book cover text',
        'business-card': 'Business card text',
        'magazine-cover': 'Magazine cover text',
        't-shirt': 'T-shirt text',
        billboard: 'Billboard text',
        flyer: 'Flyer text',
        menu: 'Menu text',
        poster: 'Poster text',
        website: 'Website text',
        'website-banner': 'Website banner text',
        'instagram-post': 'Instagram post text',
        'website-additional-screens': 'Website text'
      }
    },
    'Brand Name': {
      questions: {
        'album-cover': 'What brand would you like to create an album cover for?',
        'book-cover': 'What brand would you like to create a book cover for?',
        'business-card':  'What brand would you like to create a business card for?',
        'magazine-cover': 'What brand would you like to create a magazine cover for?',
        't-shirt': 'What brand would you like to create a t-shirt for?',
        'zoom-background': 'What brand would you like to create a zoom background for?',
        billboard: 'What brand would you like to create a billboard for?',
        flyer: 'What brand would you like to create a flyer for?',
        logo: 'Tell us the brand name you need in your logo',
        'brand-identity': 'Tell us the brand name you need in your brand identity',
        menu: 'What brand would you like to create a menu for?',
        packaging: 'What brand would you like to create a packaging for?',
        poster: 'What brand would you like to create a poster for?',
        website: 'What brand would you like to create a website for?',
        'website-banner': 'What brand would you like to create a website banner for?',
        'instagram-post': 'What brand would you like to create an Instagram post for?',
        'facebook': 'What brand would you like to create a Facebook brand page for?',
        'twitter': 'What brand would you like to create a Twitter profile for?',
        'linkedin': 'What brand would you like to create a LinkedIn company page for?',
        'youtube': 'What brand would you like to create a YouTube channel for?',
        'brand-guidelines': 'Tell us the brand name you need in your brand guidelines',
        'animated-logo': 'Tell us the brand name you need in your animated logo'
      }
    },
    'Existing Design Step': {
      questions: {
        'album-cover': 'Do you already have a logo you want to feature on your album cover?',
        'business-card': 'Do you already have a logo you want to feature on your business card?',
        'book-cover': 'Do you already have a logo you want to feature on your book cover?',
        'magazine-cover': 'Do you already have a logo you want to feature on your magazine cover?',
        'zoom-background': 'Do you already have a logo you want to feature on your zoom background?',
        't-shirt': 'Do you already have a logo you want to feature on your t-shirt?',
        billboard: 'Do you already have a logo you want to feature on your billboard?',
        flyer: 'Do you already have a logo you want to feature on your flyer?',
        logo: 'What are we going to create for you?',
        'brand-identity': 'What are we going to create for you?',
        menu: 'Do you already have a logo you want to feature on your menu?',
        poster: 'Do you already have a logo you want to feature on your poster?',
        packaging: 'Do you already have a logo you want to feature on your packaging?',
        website: 'Do you already have a logo you want to feature on your website?',
        'website-banner': 'Do you already have a logo you want to feature on your website banner?',
        'instagram-post': 'Do you already have a logo you want to feature on your Instagram post?',
        'facebook': 'Do you already have a logo you want to feature on your Facebook brand page?',
        'twitter': 'Do you already have a logo you want to feature on your Twitter profile?',
        'linkedin': 'Do you already have a logo you want to feature on your LinkedIn company page?',
        'youtube': 'Do you already have a logo you want to feature on your YouTube channel?',
        'animated-logo': 'Do you already have a logo?',
        'brand-guidelines': 'Do you already have a logo?',
        'packaging-additional-variant': 'Do you already have a logo?',
        'website-additional-screens': 'Do you already have a logo?'
      },
      yesAnswer: {
        'album-cover': 'Yes, I have a logo for my album cover',
        'book-cover': 'Yes, I have a logo for my book cover',
        'business-card': 'Yes, I have a logo for my business card',
        'magazine-cover': 'Yes, I have a logo for my magazine cover',
        'zoom-background': 'Yes, I have a logo for my zoom background',
        't-shirt': 'Yes, I have a logo for my t-shirt',
        billboard: 'Yes, I have a logo for my billboard',
        flyer: 'Yes, I have a logo for my flyer',
        logo: 'Change existing design',
        'brand-identity': 'Change existing design',
        menu: 'Yes, I have a logo for my menu',
        poster: 'Yes, I have a logo for my poster',
        packaging: 'Yes, I have a logo for my packaging',
        website: 'Yes, I have a logo for my website',
        'website-banner': 'Yes, I have a logo for my website banner',
        'instagram-post': 'Yes, I have a logo for my Instagram post',
        'facebook': 'Yes, I have a logo for my Facebook brand page',
        'twitter': 'Yes, I have a logo for my Twitter profile',
        'linkedin': 'Yes, I have a logo for my LinkedIn company page',
        'youtube': 'Yes, I have a logo for my YouTube channel',
        'animated-logo': 'Yes, I have a logo',
        'brand-guidelines': 'Yes, I have a logo',
        'packaging-additional-variant': 'Yes, I have a logo',
        'website-additional-screens': 'Yes, I have a logo'
      },
      noAnswer: {
        'album-cover': 'No, I don\'t need a logo on my album cover',
        'book-cover': 'No, I don\'t need a logo on my book cover',
        'business-card': 'No, I don\'t need a logo on my business card',
        'magazine-cover': 'No, I don\'t need a logo on my magazine cover',
        'zoom-background': 'No, I don\'t need a logo on my zoom background',
        't-shirt': 'No, I don\'t need a logo on my t-shirt',
        billboard: 'No, I don\'t need a logo on my billboard',
        flyer: 'No, I don\'t need a logo on my flyer',
        logo: 'A brand new design',
        'brand-identity': 'A brand new design',
        menu: 'No, I don\'t need a logo on my menu',
        poster: 'No, I don\'t need a logo on my poster',
        packaging: 'Create a logo for me',
        website: 'No, I don\'t need a logo on my website',
        'website-banner': 'No, I don\'t need a logo on my website banner',
        'instagram-post': 'No, I don\'t need a logo on my Instagram post',
        'facebook': 'No, I don\'t need a logo on my Facebook brand page',
        'twitter': 'No, I don\'t need a logo on my Twitter profile',
        'linkedin': 'No, I don\'t need a logo on my LinkedIn company page',
        'youtube': 'No, I don\'t need a logo on my YouTube channel',
        'animated-logo': 'Start a logo project first',
        'brand-guidelines': 'Start a logo project first',
        'packaging-additional-variant': 'Start a logo project first',
        'website-additional-screens': 'No, I don\'t need a logo on my website'
      }
    },
    'Existing Images Step': {
      questions: {
        'album-cover': 'Do you already have images or photos you\'d like to feature on your album cover?',
        'book-cover': 'Do you already have images or photos you\'d like to feature on your book cover?',
        'business-card': 'Do you already have images or photos you\'d like to feature on your business card?',
        'magazine-cover': 'Do you already have images or photos you\'d like to feature on your magazine cover?',
        't-shirt': 'Do you already have images or photos you\'d like to feature on your t-shirt?',
        'zoom-background': 'Do you already have images or photos you\'d like to feature on your zoom background?',
        billboard: 'Do you already have images or photos you\'d like to feature on your billboard?',
        flyer: 'Do you already have images or photos you\'d like to feature on your flyer?',
        menu: 'Do you already have images or photos you\'d like to feature on your menu?',
        poster: 'Do you already have images or photos you\'d like to feature on your poster?',
        packaging: 'Do you already have images or photos you\'d like to feature on your packaging?',
        website: 'Do you already have images or photos you\'d like to feature on your website?',
        'website-banner': 'Do you already have images or photos you\'d like to feature on your website banner?',
        'instagram-post': 'Do you already have images or photos you\'d like to feature on your Instagram post?',
        'facebook': 'Do you already have images or photos you\'d like to feature on your Facebook brand page?',
        'twitter': 'Do you already have images or photos you\'d like to feature on your Twitter profile?',
        'linkedin': 'Do you already have images or photos you\'d like to feature on your LinkedIn company page?',
        'youtube': 'Do you already have images or photos you\'d like to feature on your YouTube channel?',
        'brand-guidelines': 'Do you already have images or photos you\'d like to feature on your brand guidelines?',
        'packaging-additional-variant': 'Do you already have images or photos you\'d like to feature on your packaging?',
        'website-additional-screens': 'Do you already have images or photos you\'d like to feature on your website?'
      }
    },
    'Existing Packaging Design': {
      'questions.packaging': 'Do you already have an existing design you would like to change?'
      , 'yesAnswer.packaging': 'Yes, I have a design for my packaging'
      , 'noAnswer.packaging': 'No, I don\'t have a design for my packaging',
      'questions.packaging-additional-variant': 'Do you already have an existing design you would like to change?',
      'yesAnswer.packaging-additional-variant': 'Yes, I have a design for my packaging',
      'noAnswer.packaging-additional-variant': 'Create a packaging design first',
      'Please upload existing design first.packaging-additional-variant': 'Please upload existing design first'
    }
  },
  projectBrief: {
    'Additional documents': 'Additional documents',
    'Additional text for logo': 'Additional text for logo',
    'Bold': 'Bold',
    'Brand description': 'Brand description',
    'Brand DNA': 'Brand DNA',
    'Brand Name': 'Brand Name',
    'Brief': 'Brief',
    'Clean': 'Clean',
    'Colors': 'Colors',
    'Competitors': 'Competitors',
    'Designs': 'Designs',
    'Detailed': 'Detailed',
    'Examples': 'Examples',
    'Existing Design': 'Existing Design',
    'Feminine': 'Feminine',
    'Fit in, look as if it belongs in the category': 'Fit in, look as if it belongs in the category',
    'Good examples': 'Good examples',
    'Handcrafted': 'Handcrafted',
    'High income': 'High income',
    'Ideas or special requirements': 'Ideas or special requirements',
    Inspiration: 'Inspiration'
    , 'Logo Design': 'Logo Design',
    'Look as if it has been around for 100 years': 'Look as if it has been around for 100 years',
    'Low income': 'Low income',
    'Masculine': 'Masculine',
    'Mature': 'Mature',
    'Minimalist': 'Minimalist',
    'Modern': 'Modern',
    'Playful': 'Playful',
    'Premium': 'Premium',
    'Project type': 'Project type',
    'Refined': 'Refined',
    'Serious': 'Serious',
    'Slogan': 'Slogan',
    'Stand out from the crowd': 'Stand out from the crowd',
    'Startup ready to take on the world': 'Startup ready to take on the world',
    'Target Audience': 'Target Audience',
    'Traditional': 'Traditional',
    'Value': 'Value',
    'Youthful': 'Youthful',
    'Stationery': 'Stationery',
    'Existing Logo': 'Existing Logo',
    'Front business card details': 'Front business card details',
    'Back business card details': 'Back business card details',
    'Letter head': 'Letter head',
    'Compliment': 'Compliment',
    'Number of pages to be designed': 'Number of pages to be designed',
    'Number of variants to be designed': 'Number of variants to be designed',
    'Name': 'Name',
    'Additional text for package': 'Additional text for package',
    'What would you like us to create': 'What would you like us to create?',
    'Background story': 'Background story',
    'What is special': 'What is special',
    'Where it is used': 'Where it is used',
    'Can you tell us what type of pack': 'Can you tell us what type of pack you’re planning to use, is it a can, a bottle, box, label or something else?',
    'Dont need a logo to be featured on my': "Don't need a logo to be featured on my",
    'Download Zip': 'Download Zip',
    'Comment': 'Comment',
    'Rate the design': 'Rate the design',
    'Colors to use': 'Colors to use',
    'Countries where design will be aimed at': 'Countries where design will be aimed at',
    'Download': 'Download',
    'Bad examples': 'Bad examples',
    'Use free (for commercial use) images only': 'Use free (for commercial use) images only',
    'Use free or paid/stock images': 'Use free or paid/stock images',
    'Whenever (paid) stock photos are being used in the design': 'Whenever (paid) stock photos are being used in the design, be sure to supply the client with a link to the image they have to purchase. (Note: you should never purchase the image yourself)',
    'Images to use': 'Images to use',
    'No images needed': 'No images needed',
    'Technical drawings': 'Technical drawings'
  },
  moneyBackGuarantee: {
    'All refunds are at the discretion of the Support Executive at DesignBro': 'All refunds are at the discretion of the Support Executive at DesignBro.',
    'Client approaches the designer to work off-platform': 'Client approaches the designer to work off-platform',
    'Client gives a star-rating of 4 or higher to any design': 'Client gives a star-rating of 4 or higher to any design',
    'Client has allowed the project to timeout': 'Client has allowed the project to timeout',
    'Client has not contacted support at least once during the design stage to try to see if they can help with their particular problem': 'Client has not contacted support at least once during the design stage to try to see if they can help with their particular problem',
    'Client has not given any feedback to any designers': 'Client has not given any feedback to any designers',
    'Client mentions to a designer they will work with designer / pick designer as a finalist / select designer as a winner': 'Client mentions to a designer they will work with designer / pick designer as a finalist / select designer as a winner',
    'Client selects a finalist': 'Client selects a finalist',
    'DesignBro cannot issue a refund on any NDA agreements': 'DesignBro cannot issue a refund on any NDA agreements.',
    'DesignBro cannot issue refunds on any extra time purchased': 'DesignBro cannot issue refunds on any extra time purchased.',
    'DesignBro charges a one-time administration fee of $20 to process the refund (this will be deducted from the receivable)': 'DesignBro charges a one-time administration fee of $20 to process the refund (this will be deducted from the receivable).',
    'First of all we would like to note that customer satisfaction is a very high priority at DesignBro': 'First of all we would like to note that customer satisfaction is a very high priority at DesignBro. We value our customers, and want to deliver you the design you’ll love. Contact us prior to considering a refund, as we can help in almost all cases',
    'If there was a disregard for the briefing by the designers (this is at DesignBro’s discretion)': 'If there was a disregard for the briefing by the designers (this is at DesignBro’s discretion)',
    'If there were less designs submitted (including eliminated) during the design stage than paid for': 'If there were less designs submitted (including eliminated) during the design stage than paid for',
    'Refund policy': 'Refund policy',
    'The Client can be given a refund in these cases': 'The Client can be given a refund in these cases:',
    'The Client forfeits any refund if': 'The Client forfeits any refund if:',
    'The Client launched the same project simultaneously elsewhere': 'The Client launched the same project simultaneously elsewhere',
    'The reason is that Client no longer has a need for the project': 'The reason is that Client no longer has a need for the project',
    'When Client opts for a money back guarantee - Client will not be able to use (in any way shape or form) any of the designs presented': 'When Client opts for a money back guarantee - Client will not be able to use (in any way shape or form) any of the designs presented.',
    'When the client is unsatisfied with the works delivered and requests the refund to the DesignBro team no later than 5 days into the design stage': 'When the client is unsatisfied with the works delivered and requests the refund to the DesignBro team no later than 5 days into the design stage'
  },

  logIn: {
    'Allow DesignBro to send you emails relating to the functioning of the platform as well as marketing': 'Allow DesignBro to send you emails relating to the functioning of the platform as well as marketing',
    'Already have an account?': 'Already have an account?',
    'By joining you agree with our Terms and Privacy Policy': 'By joining you agree with our Terms and Privacy Policy',
    'Don’t have an account?': 'Don’t have an account?',
    'Join as a Designer': 'Join as a Designer',
    'Join': 'Join',
    'Log in now to save your work!': 'Log in now to save your work!',
    'Login': 'Login',
    passwordResetLinkRequest: 'Forgot password?'
    , logIn: 'Log in'
    , 'or': 'or',
    'Register as a designer': 'Register as a designer',
    'Select category first': 'Select category first',
    'Sign up now to save your work!': 'Sign up now to save your work!',
    'Start a project as a client': 'Start a project as a client',
    'Want to start a design project as a client?': 'Want to start a design project as a client?'
  },

  startNew: 'Start a new {{item}}'

  , completed: 'Completed'
  , inProgress: 'In Progress'
  , projectsText: 'Projects'

  , brands: {
    remove: 'Delete brand',
    'Briefing details needed': 'Briefing details needed',
    'completed': '{{number}} completed',
    'files': '{{number}} files',
    'In progress': 'In progress',
    'in progress': '{{number}} in progress',
    'My Brands': 'My Brands',
    'Payment needed': 'Payment needed',
    'Search brand': 'Search brand...',
    'switch brand': 'switch brand',
    project: 'project',
    projectOrBrand: 'project or brand'
  }

  , elapsedTime: {
    hour_one: '{{count}} hour'
    , hour_other: '{{count}} hours'

    , day_one: '{{count}} day'
    , day_other: '{{count}} days'
  }

  , project: {
    finalist: 'finalist'
    , winner: 'winner'
  }

  , client: {
    project: {
      subscriptionPlan: {
        label: 'Subscription plan'
        , activeProjectsLabel: 'Active projects'
        , startProject: 'Start new project'
        , startProjectDescription: 'Finish a project before starting a new one'
      }
      , new: {
        title: 'Select the brand for your next project'
        , new: {
          new: 'New brand'
          , form: {
            title: 'Let us know your new brand name:'
            , label: 'Brand name'
          }
        }
        , projectType: {
          title: 'Who do you want to work with?'
          , options: [
            [
              'Existing designer'
              , 'Work with one of your previous winning designers'
            ]
            , [
              'Contest'
              , 'Start a contest with multiple designers'
            ]
          ]
        }
        , 'individual.title': "Choose the designer you'd like to work with"
        , designType: {
          switcher: '\u25C2\u00A0 <button>switch brand</button> \u00A0 or create \u00A0 <button>new brand</button>'
          , title: 'Select project type for<br><span>{{brand}}</span><switcher/>'
          , custom: {
            title: 'Looking for something else or want to change your existing design?'
            , contact: 'Let us know'
            , quote: {
              title: '<span>Let us know</span> what you <br>have in mind'
              , placeholder: 'eg. need a business card design change for one of my new colleagues'
              , get: 'Get a quote'
              , note: 'DesignBro typically replies in one business day'
              , confirmation: {
                title: 'Awesome!'
                , description: "We'll get back <br>to you shortly"
              }
            }
          }
          , other: {
            title: 'Other'
            , description: '{{customTitle}} {{customContact}}.'
          }
          , 'payment.discount': 'Voucher code'
        }
      }
      , poll: {
        poll: 'poll'
        , modal: {
          agreement: {
            title: 'You have an NDA on this project'
            , description: 'If you start a poll for this project you allow that some information of the project will be shown publicly and will be visible online.'
            , action: 'Accept, continue'
          }
          , startNew: {
            title: 'Why not start a poll?!'
            , description: 'Curious what your friends, customers or colleagues think?! Just start a poll, share it as a link, or post it on your socials, and find out! Just pick the designs and get this started.'
            , action: 'Start Your Poll'
          }
          , declineAction: 'No, I don’t want to start a poll'
        }
        , new: {
          yourPoll: 'your poll'
          , headline: 'Select the designs you’d like to add to {{yourPoll}}'
          , modal: {
            share: 'Share {{subject}}'
            , link: 'the link'
            , copy: {
              action: 'Copy link'
              , completed: 'Copied!'
            }
            , on: 'on {{location}}'
            , socialMedia: 'social media'
            , message: 'Hi everyone! I hope you can help me out. I recently started a design project on DesignBro and need you to vote which design you think is best:'
            , action: 'See results'
          }
        }
        , detail: {
          started: 'Started: <date>{{content}}</date>'
          , design: '<icon/><count>{{content}}</count> designs'
          , link: '<icon/><count>{{content}}</count> link clicks'
          , vote: '<icon/><count>{{content}}</count> votes'
          , comment: '<icon/><count>{{content}}</count> comments'
        }
        , view: 'See Poll'
        , page: {
          toProject: 'Back to project'
          , new: 'Start new poll'
          , end: 'End poll'
          , share: '<icon/> Share poll'
          , download: 'Download results'
          , design: {
            by: '<fragment>by</fragment> <span>{{name}}</span>'
            , clicks: 'From {{count}} total clicks'
            , comments: {
              view: 'View comments ({{count}})'
              , modal: {
                title: 'Comments'
                , design: '#{{place}} <span><by/></span>'
                , anonymous: 'Anonymous'
                , close: '<Left/> Back to poll'
              }
            }
          }
        }
      }
      , modal: {
        title: 'Confirmation'
        , eliminateDesign: {
          description: 'Eliminate this work? Please provide the reason for your elimination so we can give this feedback to the designer and click confirm. The design will also permanently be removed from your project, and the spot will open up again to our pool of designers.'
          , options: {
            not_in_line: 'Design is not in line with the briefing / my needs'
            , not_communicate: 'Designer did not communicate back to me'
            , not_understand: 'Designer did not understand my feedback'
            , looking_for_something_else: 'Just looking for something else'
            , other: 'Other'
          }
        }
        , blockDesigner: {
          description: 'If you block {{designer}} you will never see them again, even in future projects. Please let us know why you want to block the designer and click confirm. The design will also permanently be removed from your project,and the spot will open up again to our pool of designers.'
          , options: {
              disappointing: "The designer's work was disappointing"
              , not_communicate: 'Designer did not communicate well'
              , was_rude: 'Designer was rude'
              , plagiarism: 'I suspect the designer of plagiarism'
              , other: 'Other'
          }
          , decline: 'No'
          , confirm: 'Yes'
        }
        , continue: 'Confirm and continue'
        , filesApproval: {
          termsAndConditions: 'Terms & Conditions'
          , description: 'I approve the files and agree to the DesignBro <termsAndConditions/>'
        }
        , leaveReview: {
          designer: 'How was your Designer?'
          , brand: 'How was your overall experience?'
          , label: 'Describe your experience working with {{name}}'
          , required: 'Required.'
        }
      }
      , files: {
        review: {
          success: {
            offer: {
              title: [
                'Thank you for your review!',
                'We would really like to ask for your help:'
              ],
              description: `
                Could you please post your review on the following places as well?
                <br/>In exchange we would love to offer you a discount of
                <br/> <strong>{{discountAmount}}{{discountType}} off your next project!</strong>
              `,
              copy: 'Copy quote'
              , detail: 'Valid for {{durationAmount}} days',
              decline: 'No thanks, continue',

              reward: {
                title: 'Thank you!',
                subTitle: 'Here’s your <strong>{{discountAmount}}{{discountType}} discount code:</strong>'
                , copy: 'Copy'
                , description: [
                  'Please copy this code and keep it safe!',
                  'This discount code is valid for the coming {{durationAmount}} days.'
                ],
                confirm: 'Continue'
              }
            },
            share: {
              title: 'Congrats on your brand new design!'
              , message: 'Hi there! Check out this brand new design created on DesignBro ({{link}})!'
              , description: 'Share this new design with your friends!'
            }
          }
        }
      }
    }
  }

  , projectFeedback: {
    page: {
      title: 'Thank you for your valuable feedback!',
      description: 'We hope to see you around on DesignBro in the future!'
    },
    other: {
      title: 'We’d love to hear why you decided not to pick DesignBro',
      description: 'Your valuable feedback is very much appreciated!',
      form: {
        label: 'Type here',
        submit: 'Submit'
      }
    }
  },

  projects: {
    'Latest activity': 'Latest activity',
    'My Projects': 'My Projects',
    'Discover Projects': 'Discover Projects',
    'Your portfolio has not yet been approved': 'Your portfolio has not yet been approved',
    'Expired spots': 'Expired spots',
    'In progress': 'In progress',
    'Finalists': 'Finalists',
    'Winner': 'Winner',
    'Finalize': 'Finalize',
    'Delete draft': 'Delete draft',
    'See Project': 'See Project',
    'Complete briefing': 'Complete briefing',
    'Add spot': 'Add spot',
    'Please check the uploaded source files here': 'Please check the uploaded source files here',
    'Review files': 'Review files',
    'Select this Designer as finalist': 'Select this Designer as finalist?',
    'Select this Designer as winner' : 'Select this Designer as winner?',
    'Do you want to approve this design': 'Do you want to approve this design?',
    'Approve': 'Approve',
    'Approve design': 'Approve design',
    'View': 'View',
    'Comment': 'Comment',
    'Select Finalist left': 'Select Finalist ({{count}} left)',
    'Eliminate Work': 'Eliminate Work',
    'Block Designer': 'Block Designer',
    'No files yet': 'No files yet',
    'files uploaded': 'files uploaded',
    'Download Zip': 'Download Zip',
    'Approve Files': 'Approve Files',
    'Review Designer': 'Review Designer',
    'How many days would you like to add': 'How many days would you like to add?',
    'How many spots would you like to add': 'How many spots would you like to add?',
    'Total cost': 'Total cost',
    'Eliminate design': 'Eliminate design',
    'Select as winner': 'Select as winner',
    'Approve design & request files': 'Approve design & request files',
    'Select as finalist': 'Select as finalist',
    'Previous designs': 'Previous designs',
    'Please ask your designer in the chat to restore a previous design': 'Please ask your designer in the chat to restore a previous design',
    'for': 'for',
    'Project by': 'Project by',
    'Start type': 'Start type',
    'Send': 'Send',
    'Tell us more': 'Tell us more',
    'Drag & Drop files here': 'Drag & Drop files here',
    'Download': 'Download',
    'eliminateWorkTooltip': 'When you eliminate this design, you will never see this design again, but it will open up this spot again to our pool of designers. \n This means that if there is enough time & interest in your project, a new designer can pick this spot with a new design',
    'blockDesignerTooltip': "Really don't like the work from a designer, or were they rude? When you block a designer, it doesn't only eliminate their work, it also stops this designer from ever working with you again, even in future projects.",
    'selectFinalistTooltip': 'Before the design stage is over, you need to pick at least 1 finalist and a maximum of 3. These finalists will get {{finalistStageExpireDays}} days more to work on your project in the finalist stage'
  },

  projectSuccess: {
    referralModal: {
      title: 'How did you find us?',
      options: {
        referral: 'Referral (personal recommendation)',
        advertisement: 'Advertisement',
        onlineSearch: 'Google (search engine)',
        fromAWebsite: 'Read about you on a website',
        readReview: 'Read a review',
        socialMedia: 'Social media',
        YouTube: 'YouTube',
        workedWith: 'Worked with you before',
        other: 'Other'
      },
      label: 'Let us know how'
    }
  },

  designer: {
    project: {
      files: {
        upload: {
          featuredImage: {
            error: 'A rectangle shaped image provided. A square shaped one (within a {{amount}}% threshold) is required instead.'
          }
          , design: {
            title: 'Upload design'
            , description: 'Upload your design and type a message to {{client}} to explain a little about it. With this message you significantly increase your chances of winning.'
            , action: 'Confirm & continue'
          }
        }
      }
    }
    , settings: {
      general: {
        username: 'Displayed username'
        , dateOfBirthMonth: 'Month of Birth'
        , dateOfBirthDay: 'Day of Birth'
        , dateOfBirthYear: 'Year of Birth'
        , gender: 'Gender'
        , address: {
          title: 'Address details:'
          , address1: 'Address 1'
          , address2: 'Address 2 (opt.)'
          , stateName: 'State (opt.)'
          , zip: 'Zip (opt.)'
          , phone: 'Phone number'
        }
      }
    },
    '1-3 years': '1-3 years',
    '4-7 years': '4-7 years',
    'Acceptable': 'Acceptable',
    'Brand Identity': 'Brand Identity',
    'Country': 'Country',
    'Dashboard': 'Dashboard',
    'Female': 'Female',
    'First Name': 'First Name',
    'Go Back': 'Go Back',
    'Good': 'Good',
    'Great, thank you!': 'Great, thank you!',
    'If you have any issues or questions, drop us a line.': 'If you have any issues or questions, drop us a line.',
    'Last Name': 'Last Name',
    'Let us take a look at your work and we’ll get back to you as soon as we can. This can take some time; we get a lot of good portfolios. Please bear with us in the meantime!': 'Let us take a look at your work and we’ll get back to you as soon as we can. This can take some time; we get a lot of good portfolios. Please bear with us in the meantime!',
    'Let’s get things going: just fill in your profile.': 'Let’s get things going: just fill in your profile.',
    'Male': 'Male',
    'Native': 'Native',
    'Next Step': 'Next Step',
    'No experience': 'No experience',
    'Not good': 'Not good',
    'Online Media (website, banners and others)': 'Online Media (website, banners and others)',
    'Online portfolio': 'Online portfolio',
    'over 8 years': 'over 8 years',
    'Please confirm that the work you have uploaded is your work and that there is nothing prohibiting you from sharing it.': 'Please confirm that the work you have uploaded is your work and that there is nothing prohibiting you from sharing it.',
    'Please set your experience': 'Please set your experience',
    'Prefer not to say': 'Prefer not to say',
    'Print Media': 'Print Media',
    'Set your experience': 'Set your experience',
    'Social': 'Social',
    'Tell us about the project': 'Tell us about the project',
    'Upload image (JPG or PNG, max file size 2MB)': 'Upload image (JPG or PNG, max file size 2MB)',
    'Welcome to DesignBro! It’s great to see you here!': 'Welcome to DesignBro! It’s great to see you here!',
    'Written English level': 'Written English level',
    'You can Drag & Drop your file from computer': 'You can Drag & Drop your file from computer',
    'You mentioned you have "no experience". That means that you will not be able to use our platform, as we currently only offer our clients experienced designers. You can come back if you made a mistake or once you have a bit more experience.': 'You mentioned you have "no experience". That means that you will not be able to use our platform, as we currently only offer our clients experienced designers. You can come back if you made a mistake or once you have a bit more experience.',
    'You will be able to enter competitions as soon as it gets approved!': 'You will be able to enter competitions as soon as it gets approved!',
    'Your portfolio will be vetted by our team and you will receive an answer by email as soon as we can. This can take some time; we get a lot of good portfolios. Please bear with us in the meantime!': 'Your portfolio will be vetted by our team and you will receive an answer by email as soon as we can. This can take some time; we get a lot of good portfolios. Please bear with us in the meantime!'
  }
  , layout: {
    'All Rights reserved': 'All Rights reserved'
    , startProject: [
      'design-project-types', 'Start a Project'
    ]
    , 'Blog': 'Blog'
    , projectTypeLinks: [
      ['logo-design', 'Logo']
      , ['brand-identity-design', 'Brand identity']
      , ['packaging-design', 'Packaging']
    ]
    , 'Contact Us': 'Contact Us',
    'Help': 'Help',
    'Privacy Policy': 'Privacy Policy',
    'Terms': 'Terms',
    'Home': 'Home',
    'copyright': '{{year}} © DesignBro. All Rights reserved.'
    , 'item.Drafts': 'Drafts'
    , 'Polls': 'Polls',
    'Files': 'Files',
    inProgress: '{{projectsText}} in Progress'
    , projects: '{{type}} {{projectsText}}'
    , Drafts: 'Draft'
    , completed: '{{completed}}'
    , 'New Project': 'New Project',
    'Prize': 'Prize',
    Spots: 'Spots'
    , 'Submit Design': 'Submit Design',
    'Design': 'Design',
    'Finalist': 'Finalist',
    'Not started': 'Not started',
    'Buy time': 'Buy time',
    'left to choose your finalists': 'left to choose your finalists',
    'left in the Design Stage': 'left in the Design Stage',
    'left to choose your winner': 'left to choose your winner',
    'left to approve the design': 'left to approve the design',
    'for the designer to upload files': 'for the designer to upload files',
    'left to approve the files': 'left to approve the files',
    'left in the Finalist Stage': 'left in the Finalist Stage',
    'left for the client approval': 'left for the client approval',
    'left to upload your files': 'left to upload your files',
    'Brief': 'Brief',
    'Designs': 'Designs',
    'Vacant': 'Vacant',
    'Waiting for design': 'Waiting for design',
    'Confirm': 'Confirm',
    'Confirmation': 'Confirmation',
    'Cancel': 'Cancel',
    'waiting_for_design_tooltip_contest': 'Great! The designer {{designerName}} has reserved a spot to take part in your project. This means they have now started working on a design. \n The designer needs to upload their design within {{reservationExpireHours}} hours, otherwise they automatically lose their spot. \n {{designerName}} has {{hoursToExpire}} hours left to upload before this spot opens to other designers again.',
    'waiting_for_design_tooltip_1_to_1': 'Great! {{designerName}} has been notified and will start working on your project.',
    'design_stage_tooltip_contest': 'There are three stages in your {{projectName}} project: \n Design stage: Here all designers upload all their designs and if there is enough time they can make some changes already too! \n The design stage takes {{designStageExpireDuration}} days in total. \n Finalist stage: You can pick your favorite 3 designs as finalists, you then have another {{finalistStageExpireDays}} days to work with the finalists. \n Winner stage: Once you select your 1 winner, the winning designer will upload your files within {{filesStageExpireDays}} days. \n File stage: Once your designer has uploaded files, you have {{reviewFilesStageExpireDays}} days to approve the files, or to communicate with the designer to request changes to files & formats.',
    'design_stage_tooltip_1_to_1': 'There are two stages in your {{projectName}} project: \n Design stage: The designer uploads the design, you can give feedback so the designer can make changes for you! \n The design stage takes {{designStageExpireDuration}} days in total. \n File stage: Once your designer has uploaded files, you have {{reviewFilesStageExpireDays}} days to approve the files, or to communicate with the designer to request changes to files & formats.',
    'days_left_tooltip': 'The rounded down number of days that are left in your project. \n The exact number for your project is: {{days}} and {{hours}}.',
    'designs_info_tooltip': 'The first number shows how many designs you’ve received (including eliminations). \n The second number shows the number of designs we guarantee for the package you selected and paid for.'
  },
  'stages_tooltip': {
    you_currently_have: 'You currently have <strong> {{days}} and {{hours}} </strong> left in the ',
    design_stage: '<strong>Design Stage</strong>',
    finalist_stage: '<strong>Finalist Stage</strong>',
    files_stage: '<strong>Files Stage</strong>',
    days: '{{days}} days',
    design_stage_content: 'You will receive designs and get the opportunity to provide feedback. Designers will have the chance to send updated versions. Make sure to pick (Max.3) finalists by the end of this phase.',
    finalist_stage_content: 'Finalists will have the opportunity to improve upon their original designs. You should continue to provide feedback. By the end of this phase, you should have picked the winner.',
    files_stage_content: 'The winning designer gets {{files}} days to upload all the final files. Once the files are uploaded you have {{review}} days to review the files to ensure everything is in order.'
  },
  settings: {
    notifications: {
      title: 'Email notifications'
      , projects: 'Updates with my projects'
      , messages: 'New messages received'
      , brand: 'News from DesignBro'
    },
    'General': 'General',
    'Payment': 'Payment',
    'Notifications': 'Notifications',
    'Email': 'Email',
    'Country / Language': 'Country / Language',
    'Contact Information': 'Contact Information',
    'Will be used as default billing address': 'Will be used as default billing address',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Address 1': 'Address 1',
    'City': 'City',
    'Country': 'Country',
    'Phone number (opt)': 'Phone number (opt.)',
    'VAT number (opt)': 'VAT number (opt.)',
    'Ok': 'Ok'
    , save: 'Save Changes'
    , modal: {
      title: 'Settings'
      , message: 'Excellent, your settings were successfully updated!'
    }
  },

  deliverables: {
    Deliverables: 'Deliverables',
    'please note': 'Please note: ',
    'read more': 'Read more',
    logo: {
      heading_1: 'Logo',
      description: 'Depending on the type of logo, the final files also include logo variants such as (eg.) a stand-alone icon, a landscape vs portrait logo etc.',
      note: 'Only 1 logo design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
    },
    'brand-identity': {
      heading_1: 'Logo',
      heading_2: 'Letterhead, Business card, Envelope, Compliment note',
      description: 'Depending on the type of logo, the final files also include logo variants such as (eg.) a stand-alone icon, a landscape vs portrait logo etc. \n \n The final files for the stationery items also include a print file: \n .pdf with 0.125 inch or 3 mm bleed.',
      note: 'Only 1 logo design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.'
    },
    'business-card': {
      heading_1: 'Business Card',
      description: 'The final files for the business card also include a print file: \n .pdf with 0.125 inch or 3 mm bleed.',
      note: 'Only 1 design (for front and back) is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.'
    },
    'website': {
      heading_1: 'Website design ({{screenCount}} pages)',
      description: "The final files for website design also include the files for the assets (images, icons etc.) used in the design (.jpg, .png or .svg). as well as the design for mobile view. \n \n As an indication, to avoid a page being an 'infinite length', a page is expected to have approximately 3 sections (for example a 'header' section, 'about us' section and 'contact us' section). This is approached in a “fair use” method. \n \n This Website Design project includes a design only - not a working website. The client receives all the files and assets for the website any web developer would need.",
      note: 'Only the number of pages given in the briefing are included. If more pages are requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, .xd, .fig or .psd, depending on the software the designer used'
    },
    'website-additional-screens': {
      heading_1: 'Website design ({{screenCount}} pages)',
      description: "The final files for website design also include the files for the assets (images, icons etc.) used in the design (.jpg, .png or .svg). as well as the design for mobile view. \n \n As an indication, to avoid a page being an 'infinite length', a page is expected to have approximately 3 sections (for example a 'header' section, 'about us' section and 'contact us' section). This is approached in a “fair use” method. \n \n This Website Design project includes a design only - not a working website. The client receives all the files and assets for the website any web developer would need.",
      note: 'Only the number of pages given in the briefing are included. If more pages are requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, .xd, .fig or .psd, depending on the software the designer used'
    },
    'website-banner': {
      heading_1: 'Website Banner (5 sizes)',
      description: 'A maximum of 5 banner sizes, based on the same design, are included in the project. \n The client can decide which 5 sizes. Standard website banner sizes are: 300 x 250 px, 336 x 280 px, 728 x 90 px, 300 x 600 px and 320 x 50 px.',
      note: 'Only 5 different sizes are included. If another design or more sizes are requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, .xd, .fig or .psd, depending on the software the designer used'
    },
    facebook: {
      heading_1: 'Profile image',
      heading_2: 'Cover image',
      note: 'Only 1 design (for profile image and cover image) is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    'instagram-post': {
      heading_1: 'Instagram post image',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    linkedin: {
      heading_1: 'Profile image',
      heading_2: 'Cover image',
      note: 'Only 1 design (for profile image and cover image) is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    twitter: {
      heading_1: 'Profile image',
      heading_2: 'Cover image',
      note: 'Only 1 design (for profile image and cover image) is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    youtube: {
      heading_1: 'Profile image',
      heading_2: 'Cover image',
      note: 'Only 1 design (for profile image and cover image) is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    'zoom-background': {
      heading_1: 'Zoom background image',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    poster: {
      heading_1: 'Poster',
      description: 'The final files for the poster also include a print file: \n .pdf with 0.125 inch or 3 mm bleed.',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    flyer: {
      heading_1: 'Flyer',
      description: 'The final files for the flyer also include a print file: \n .pdf with 0.125 inch or 3 mm bleed.',
      note: 'Only 1 design (for front and back) is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    'album-cover': {
      heading_1: 'Album cover',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    'book-cover': {
      heading_1: 'Book cover',
      description: 'The final files for the book cover also include a print file: \n .pdf with 0.125 inch or 3 mm bleed.',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    'magazine-cover': {
      heading_1: 'Magazine cover',
      description: 'The final files for the magazine cover also include a print file: \n .pdf with 0.125 inch or 3 mm bleed.',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, .indd or .psd, depending on the software the designer used'
    },
    packaging: {
      heading_1: 'Packaging design',
      description: 'The final files for packaging design include a print file (.pdf) that should be based on the dieline or sizes supplied by the client.',
      note: '1 design is included in the project. If another SKU is requested, please reach out to the DesignBro team as there might be additional costs.',
      variant_note: '{{variantsCount}} variants (e.g. size or flavor) based on 1 design are included in the project. If another SKU is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    'packaging-additional-variant': {
      heading_1: 'Packaging design',
      description: 'The final files for packaging design include a print file (.pdf) that should be based on the dieline or sizes supplied by the client.',
      note: 'Only 1 design for 1 SKU (flavor or variant) is included in the project. If another SKU is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai, or .psd, depending on the software the designer used'
    },
    'brand-guidelines': {
      heading_1: 'Brand guidelines document',
      description: 'The brand guidelines project include a document, based on a template design supplied by DesignBro.',
      note: 'Only 1 document is included in the project. If another document is requested, please reach out to the DesignBro team as there might be additional costs.'
    },
    'animated-logo': {
      heading_1: 'Logo animation',
      note: 'Only 1 animation is included in the project. If another animation is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .psd, .aep, .c4d, .f4v,.flv, .procreate, depending on the software the designer used.'
    },
    menu: {
      heading_1: 'Menu',
      description: 'The final files for the menu also include a print file: \n .pdf with 0.125 inch or 3 mm bleed',
      note: 'Only 1 design(double sided, cover and menu page) is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .indd, or .ai, depending on the software the designer used.'
    },
    billboard: {
      heading_1: 'Billboard',
      description: 'The final files for the billboard also include a print file: \n .pdf with 0.125 inch or 3 mm bleed',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai or .psd, depending on the software the designer used.'
    },
    't-shirt': {
      heading_1: 'T-shirt',
      note: 'Only 1 design is included. If another design is requested, please reach out to the DesignBro team as there might be additional costs.',
      'source_tooltip': 'The editable source file can be: .ai or .psd, depending on the software the designer used.'
    }
  }
}
