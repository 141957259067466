import _ from 'lodash'
import { connect } from 'react-redux'

import { upload } from '@application/index/asset/store/actions/sourceFiles'

import { getProjectById } from '@application/index/asset/store/reducers/projects'
import { getSourceFiles } from '@application/index/asset/store/reducers/sourceFiles'

import UploadFilesForm from './component'

const mapStateToProps = (state) => {
  const files = getSourceFiles(state)

  return {
    projectId: state.projects.current,
    files,
    canContinue: !_.isEmpty(files)
  }
}

export default connect(mapStateToProps, {
  upload,
})(UploadFilesForm)
