import api from '@application/index/asset/utils/apiV2'
import Dinero from 'dinero.js'
import { getFileData } from '@application/index/asset/utils/fileUtilities'
import { history } from '@application/index/index/history'
import { loadStripe } from '@stripe/stripe-js'
import { saveGACheckoutEvent, saveGAPurchaseEvent } from '@application/index/asset/utils/googleAnalytics'
import { productSelector } from '../selectors/products'
import { projectBuilderStepSelector, projectSelector } from '@application/index/asset/store/selectors/newProjectBuilder'

import clientEndpoint from '@application/index/component/index/clientEndpoint'

const stripePromise = loadStripe(window.STRIPE_PUBLISHABLE_KEY)

export const LOAD_PROJECT_REQUEST = 'project/builder/LOAD_PROJECT_REQUEST'
export const LOAD_PROJECT_SUCCESS = 'project/builder/LOAD_PROJECT_SUCCESS'
export const LOAD_PROJECT_FAILURE = 'project/builder/LOAD_PROJECT_FAILURE'

const projectsEndpoint = clientEndpoint + 'projects'
const get = id => projectsEndpoint + '/' + id

export const loadProject = (id) => api.get({
  endpoint: get(id),
  types: [LOAD_PROJECT_REQUEST, LOAD_PROJECT_SUCCESS, LOAD_PROJECT_FAILURE]
})

export const loadLastProject = () => api.get({
  endpoint: `/api/v2/client/last-project`,
  types: [LOAD_PROJECT_REQUEST, LOAD_PROJECT_SUCCESS, LOAD_PROJECT_FAILURE]
})

export const CREATE_PROJECT_REQUEST = 'project/builder/CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'project/builder/CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'project/builder/CREATE_PROJECT_FAILURE'

const createProjectApiCall = (project) => api.post({
  endpoint: projectsEndpoint,
  body: { project },
  types: [CREATE_PROJECT_REQUEST, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE]
})

const redirectToNextStepCallback = (getState) => () => {
  const state = getState()
  const { id, currentStepPath } = state.newProjectBuilder.project

  history.push(`/new-project/${id}/${currentStepPath || 'success'}`)

  const project = projectSelector(state)
  const product = productSelector(state)
  const price = Dinero({ amount: project.normalizedPrice || product.priceCents, currency: project.currency }).toFormat('0,0.00')
  const step = projectBuilderStepSelector(state, currentStepPath)

  if (step) {
    saveGACheckoutEvent({
      product,
      price,
      step
    })
  }


  if (!currentStepPath) {
    saveGAPurchaseEvent({
      product,
      id,
      price,
    })
  }

}

export const createContestProject = (project) => (dispatch, getState) => {
  dispatch(createProjectApiCall(project)).then(redirectToNextStepCallback(getState))
}

export const UPDATE_PROJECT_REQUEST = 'project/builder/UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = 'project/builder/UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAILURE = 'project/builder/UPDATE_PROJECT_FAILURE'

export const updateProject = (id, project, step, upgradeProjectState = false) => api.patch({
  endpoint: get(id),
  body: { project, step, upgradeProjectState },
  types: [UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE]
})

export const submitUpdateProject = (
  id, project, step, upgradeProjectState = false
  , submit = true
) =>
  (dispatch, getState) => {
    dispatch(
      updateProject(id, project, step, upgradeProjectState)
    ).then(
      submit && redirectToNextStepCallback(getState)
    )
  }

export const submitCheckoutStep = (id, project, step) => (dispatch, getState) => {
  dispatch(updateProject(id, project, step, true)).then(() => {
    const state = getState()
    const project = state.newProjectBuilder.project
    if(project.state !== 'draft') {
      history.push(`/new-project/${id}/success`)
    } else {
      stripePromise.then((stripe) => {
        const state = getState()
        const { stripeSessionId } = state.newProjectBuilder.project

        stripe.redirectToCheckout({
          sessionId: stripeSessionId,
        })
      })
    }
  })
}

const CREATE_PROJECT_UPLOADED_FILE_REQUEST = 'project/builder/CREATE_PROJECT_UPLOADED_FILE_REQUEST'
const CREATE_PROJECT_UPLOADED_FILE_SUCCESS = 'project/builder/CREATE_PROJECT_UPLOADED_FILE_SUCCESS'
const CREATE_PROJECT_UPLOADED_FILE_FAILURE = 'project/builder/CREATE_PROJECT_UPLOADED_FILE_FAILURE'

export const uploadProjectFile = ({ file, projectId, entity }) => api.post({
  endpoint: clientEndpoint + 'uploaded_files?project_id=' + projectId + '&entity=' + entity,
  body: getFileData({ file }),

  types: [CREATE_PROJECT_UPLOADED_FILE_REQUEST, CREATE_PROJECT_UPLOADED_FILE_SUCCESS, CREATE_PROJECT_UPLOADED_FILE_FAILURE]
})
