import React, { Component } from 'react'
import _ from 'lodash'
import Dinero from 'dinero.js'
import { connect } from 'react-redux'

import { hideModal } from '@application/index/asset/store/actions/modal'
import { buyDaysUpsell } from '@application/index/asset/store/actions/projectBuilder'

import { getVatRates, isNotIreland } from '@application/index/asset/store/selectors/vat'
import { currentDiscountSelector } from '@application/index/asset/store/selectors/discount'
import { getMe } from '@application/index/asset/store/selectors/me'
import { getCurrentProject } from '@application/index/asset/store/selectors/projects'
import { getProjectBuilderAttributes, getProjectBuilderUpsellAttributes } from '@application/index/asset/store/selectors/projectBuilder'
import { discountInAmount, currentProjectAdditionalDesignPricesSelector } from '@application/index/asset/store/selectors/prices'

import PaymentModal from '@application/index/component/projectBuilder/New/PaymentModal/PaymentModal'

const getVatRateByCountry = (vatRates, countryCode) => {
  return _.find(vatRates, (v) => v.countryCode === countryCode)
}

const getVat = (price, vatRate, vatNumber) => {
  const shouldHaveVat = vatRate && !(isNotIreland(vatRate.countryCode) && vatNumber)
  const vatMultiplier = shouldHaveVat ? vatRate.percent / 100 : 0

  return price.multiply(vatMultiplier)
}

const displayDiscountAmount = (discount) => {
  if (!discount) {
    return null
  }

  switch (discount.discountType) {
  case 'percent':
    return `${discount.value}%`
  case 'amount':
    return Dinero({ amount: discount.amountCents, currency: discount.amountCurrency }).toFormat('$0,0.00')
  default:
    return null
  }
}

const PaymentModalContainer = ({ onSubmit, project, ...props }) => (
  <PaymentModal
    {...props}
    onSubmit={onSubmit(project)}
  />
)

const mapStateToProps = (state, { project }) => {
  const me = getMe(state)
  const { preferredPaymentMethod, countryCode, vat: vatNumber, creditCardNumber, creditCardProvider } = me

  const attributes = getProjectBuilderAttributes(state)

  const { numberOfDays } = getProjectBuilderUpsellAttributes(state)
  const additionalDayPrice = project.additionalDayPrice

  const price = Dinero({
    amount: numberOfDays * additionalDayPrice.amountCents,
    currency: additionalDayPrice.amountCurrency
  })

  const discount = currentDiscountSelector(state)

  const discountAmount = displayDiscountAmount(discount)
  const isDiscountVisible = discount && discount.available

  const vatRates = getVatRates(state)

  const vatRate = getVatRateByCountry(vatRates, countryCode)
  const isVatVisible = countryCode && vatRate

  const priceWithDiscount = price.subtract(discountInAmount(price, discount))

  const vat = getVat(priceWithDiscount, vatRate, vatNumber)

  const total = priceWithDiscount.add(vat)

  return {
    project,
    paymentType: attributes.paymentType,
    basePriceName: 'Additional day(s)',
    basePriceValue: price.toFormat('$0,0.00'),
    isVatVisible,
    vatValue: vat.toFormat('$0,0.00'),
    totalValue: total.toFormat('$0,0.00'),
    paypalAmount: total.toRoundedUnit(2),
    hasCard: creditCardNumber && creditCardProvider,
    creditCardNumber,
    creditCardProvider,
    discountAmount: `- ${discountAmount}`,
    isDiscountVisible,
    currency: project.currency
  }
}

export default connect(mapStateToProps, {
  onSubmit: buyDaysUpsell,
  onClose: hideModal
})(PaymentModalContainer)
