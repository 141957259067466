import React from 'react'
import classNames from 'classnames'
import styles from './Button.module.scss'

const Button = ({
  as: Component = 'button',
  disabled,
  styledAsDisabled,
  children,
  className,
  variant,
  waiting,
  add,
  added,
  ...props
}) => {
  return (
    <Component {...{
      className: classNames(styles.btn, className, {
        [styles.disabled]: disabled || styledAsDisabled,
        [styles.waiting]: waiting,
        [styles.add]: add,
        [styles.added]: add && added
      }),
      disabled,
      ...props
    }}>
      {children}
    </Component>
  )
}

export default Button
