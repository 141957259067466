import React from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import { Modal } from 'semantic-ui-react'
import styles from './DesignerOrContestDecisionModal.module.scss'
import Button from '@application/index/component/index/Button'

const DesignerOrContestDecisionModal = ({ selectOneToOne, selectContest, className }) => {
  const {
    title, options
  } =
    useTranslation().t(
      'client.project.new.projectType'
    )

  return (
    <Modal
      open
      className={classNames('main-modal main-modal--xs', className)}
      size="small"
      closeOnDimmerClick={false}
    >
      <div className={styles.modalBody}>
        <div className={styles.modalHeader}>
          {title}
        </div>

        <div className={styles.modalContent}>
          {[
            selectOneToOne
            , selectContest
          ].map((onClick, index) =>
            <div key={onClick} className={styles.block}>
              <Button variant="outlined" className={styles.button} {...{onClick}}>
                {options[index][0]}
              </Button>
              <span className={styles.description}>
                {options[index][1]}
              </span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default DesignerOrContestDecisionModal
