import domain from '@application/index/asset/translation/index/domain'

export default language =>
	'designbro.'
		+ Object.entries(domain).reduce(
			(result, [domain, language]) => ({
				...result
				, [language]: domain
			})
		, {})[
			language
		]