import useSWR from 'swr'

import useCore from './useCore'

export default (type, endpoint) => {
	const core = useCore()

	return useSWR(
		endpoint, () => core('get', 'LOAD_' + type, endpoint)
	)
		.data
			?.payload
}