import React from 'react'
import classNames from 'classnames'

import styles from './.module.scss'

import Button from '@application/index/views/client/Project/new/components/Button'

export default ({className, ...properties}) =>
  <Button
    className={classNames(styles.button, className)}
    {...properties}
  />