import React from 'react'

import {ReactComponent as Facebook} from './Facebook.svg'
import {ReactComponent as Twitter} from './Twitter.svg'
import {ReactComponent as LinkedIn} from './LinkedIn.svg'

const network = {
  Facebook,
  Twitter,
  LinkedIn
}

export default name => {
  const Component = network[name]

  return <Component/>
}