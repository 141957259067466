import React from 'react'
import root from '@application/root'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import bugsnagReact from '@bugsnag/plugin-react'
import { bugsnagClient } from '@application/bugsnag'
import rootReducer from '../asset/store/reducers'
import configureStore from '../asset/store/configureStore'
import Routes from './Routes'
import Cookie from 'js-cookie'
import { history } from './history'

import '../asset/translation/index'

import 'rc-slider/assets/index.css'
import 'core-js/stable'

bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')

const store = configureStore(rootReducer)
const referrer = document.referrer
Cookie.set('referrer', referrer)

store.dispatch({
  type: 'VALIDATE_AUTH_' + (
    window.me ?
      'SUCCESS'
    :
      'FAILURE'
  )
  , ...(window.me && {payload: window.me})
})

root.render(
  <ErrorBoundary>
    <Provider {...{store}}>
      <ConnectedRouter {...{history}}>
        <Routes/>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
)