import { connect } from 'react-redux'
import SimpleModal from './component'
import { close } from '@application/index/asset/store/actions/simpleModal'

const mapStateToProps = (state) => {
  return state.simpleModal
}

export default connect(mapStateToProps, {
  onClose: close
})(SimpleModal)
