import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import { Link } from 'react-router-dom'

import NavigationMenuButton from '@application/index/views/Layout/NavigationMenu/Button'

//import HeaderControls from '@application/index/component/index/HeaderControls'
import HeaderControlsClient from '@application/index/component/index/HeaderControlsClient'
import HeaderControlsDesigner from '@application/index/component/index/HeaderControlsDesigner'

import MainPageLink from '@application/index/component/index/MainPageLink'

const Logo = () => <img src="/designbro-logo.svg" alt="logo" class="logo"/>

const Header = ({
  className, me: { userType }, navigationMenuOpen, toggleNavigationMenuOpen,
  onClick
}) => {
  const client = userType === 'client'

  const properties = { className: 'main-header__logo' }

  return <header className={classNames(className, "main-header bg-light")}
    onClick={
      () => toggleNavigationMenuOpen(false)
    }
  >
    {userType ? <>
      {client &&
        <NavigationMenuButton {...{
          navigationMenuOpen, toggleNavigationMenuOpen
        }}/>
      }
      <Link to={'/' + (client ? 'c' : 'd')} {...properties}><Logo/></Link>
    </> :
      <MainPageLink {...properties}><Logo/></MainPageLink>
    }

    {/*!userType && <HeaderControls />*/}

    {userType === 'designer' && <HeaderControlsDesigner />}
    {client && <HeaderControlsClient />}
  </header>
}

export default Header
