import _ from 'lodash'
import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import Input from '../Input'

import scorePassword from '@application/index/asset/utils/scorePassword'

import styles from './PasswordInput.module.scss'

function getLevel(score) {
  if (score > 60) {
    return 'strong'
  } else if (score > 25) {
    return 'fair'
  }

  return 'weak'
}

const StatusLine = ({ score }) => {
  const { t } = useTranslation()
  const level = getLevel(score)
  return (
    <div>
      <span className={cn(styles.passwordStrengthDot, styles[level])} />
      <span className={styles.passwordStrength}>
        {t('projectBuilder.' + level + ' pass')}
      </span>
    </div>
  )
}

const PasswordInput = ({ value, statusLine, ...props }) => {
  return (
    <div className={styles.inputWrapper}>
      <Input {...{value, ...props}}/>

      {statusLine && value &&
        <StatusLine score={scorePassword(value)}/>
      }
    </div>
  )
}

export default PasswordInput
