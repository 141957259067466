import React from 'react'
import { Link } from 'react-router-dom'

import useRootDomainUrl from '@application/index/function/useRootDomainUrl'

import Container from './Container'

import t from './useTranslation'

import Form from './Form'
import Navigation from '@application/index/views/Login/Navigation'

import projectTypes from '@application/index/asset/constants/path/projectTypes'

export default ({
  history, showSaveWorkHint, asModalWindow,
  showNavigation = true, showRegisterHint = true
}) =>
  <Container
    {...(asModalWindow && {className: 'm-b-0'})}
    children={<>
      {showNavigation &&
        <Navigation login {...{asModalWindow}}/>
      }
      {showSaveWorkHint &&
        <p className="m-b-35 font-bold">
          {t('Log in now to save your work!')}
        </p>
      }

      <Form {...{history}}/>
    </>}
    {...(showRegisterHint && {
      footer: <>
        <h3 className="sign-in__form-text-md">
          {t('Don’t have an account?')}
        </h3>
        <p className="sign-in__form-text">
          <a
            className="sign-in__form-link in-black no-wrap"
            href={useRootDomainUrl() + projectTypes}
          >
            {t('Start a project as a client')}
          </a>

          <span className="m-l-5 m-r-5"> {
            t('or')
          } </span>

          <Link
            className="sign-in__form-link in-green-500 no-wrap"
            to="/d/signup"
          >
            {t('Register as a designer')}
          </Link>
        </p>
      </>
    })}
  />