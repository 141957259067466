import _ from 'lodash'
import { projectSelector } from '@application/index/asset/store/selectors/newProjectBuilder'
import { createSelector } from 'reselect'
import Dinero from 'dinero.js'
import { currentProductSelector } from '@application/index/asset/store/selectors/product'

export const productsSelector = (state) => state.newProjectBuilderProducts.products
export const productsLoadInProgressSelector = (state) => state.newProjectBuilderProducts.loadInProgress

export const productAdditionalScreenPriceSelector = (state) => {
  const currentProduct = currentProductSelector(state)
  if (currentProduct){
    const productId =  Object.values(state.entities.products).find(product => product.key === currentProduct.key)?.id
    return Object.values(state.entities.additionalScreenPrices).filter(price => price.productId == productId)
  }
}

export const productSelector = createSelector(
  projectSelector,
  (project) => {
    return _.get(project, ['product'], {})
  }
)

export const currentProjectType = createSelector(
  projectSelector,
  (project) => {
    return _.get(project, ['projectType'], {})
  }
)

export const productByKeySelector = createSelector(
  productsSelector,
  (_, key) => key,
  (products, key) => {
    return _.find(products, { key })
  }
)

export const additionalDesignPricesSelector = createSelector(
  projectSelector,
  (project) => {
    return _.get(project, ['product', 'additionalDesignPrices'], [])
  }
)

export const additionalDesignPriceSelector = createSelector(
  additionalDesignPricesSelector,
  (_, spotsCount) => spotsCount,
  (prices, spotsCount) => {
    const price = _.find(prices, { quantity: spotsCount })
    if(!price) return

    return Dinero({
      amount: _.get(price, ['amountCents']),
      currency: _.get(price, ['amountCurrency'])
    })
  },
)

export const additionalScreenPricesSelector = createSelector(
  projectSelector,
  (project) => {
    return _.get(project, ['product', 'additionalScreenPrices'], [])
  }
)

export const additionalScreenPriceSelector = createSelector(
  additionalScreenPricesSelector,
  (_, screensCount) => screensCount,
  (prices, screensCount) => {
    const price = _.find(prices, { quantity: screensCount })
    if(!price) return

    return Dinero({
      amount: _.get(price, ['amountCents']),
      currency: _.get(price, ['amountCurrency'])
    })
  }
)

export const additionalVariantPricesSelector = createSelector(
  projectSelector,
  (project) => {
    return _.get(project, ['product', 'additionalVariantPrices'], [])
  }
)

export const additionalVariantPriceSelector = createSelector(
  additionalVariantPricesSelector,
  (_, variantsCount) => variantsCount,
  (prices, variantsCount) => {
    const price = _.find(prices, { quantity: variantsCount })
    if(!price) return

    return Dinero({
      amount: _.get(price, ['amountCents']),
      currency: _.get(price, ['amountCurrency'])
    })
  }
)

export const screenPriceSelector = createSelector(
  productAdditionalScreenPriceSelector,
  (_, screensCount) => screensCount,
  (prices, screensCount) => {
    const price = _.find(prices, { quantity: screensCount })
    if(!price) return

    return Dinero({
      amount: _.get(price, ['amountCents']),
      currency: _.get(price, ['amountCurrency'])
    })
  }
)
