import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import classNames from 'classnames'
import { Modal } from 'semantic-ui-react'
import styles from './SuccessMessageModal.module.scss'
import Button from '@application/index/component/index/Button'
import { history } from '@application/index/index/history'

const path = 'client.project.new.designType.custom.quote.confirmation.'

const SuccessMessageModal = ({ onClose, className }) => {
  const {t} = useTranslation()

  return (
    <Modal
      onClose={onClose}
      open
      className={classNames('main-modal main-modal--xs', className)}
      size="small"
    >
      <div className={styles.modalBody}>
        <h2>
          <span className={styles.isGreen}>
            {t(path + 'title')}
          </span>
          <Trans i18nKey={path + 'description'}/>
        </h2>
        <Button
          variant="transparent"
          className={styles.btn}
          onClick={() => {
            onClose()
            history.push('/c')
          }}
        >
          {t('continue')}
          <i className={classNames(styles.icon, 'icon-arrow-right')} />
        </Button>
      </div>
    </Modal>
  )
}

export default SuccessMessageModal
