import { connect } from 'react-redux'
import { getLongName, getMe } from '@application/index/asset/store/reducers/me'
import { letterifyName } from '@application/index/asset/utils/user'

import UserPic from './component'

const getName = (me) => letterifyName(getLongName(me))

const mapStateToProps = (state) => ({ name: getName(getMe(state)) })

export default connect(mapStateToProps)(UserPic)
