import React, { forwardRef } from 'react'
import classNames from 'classnames'

import styles from './Main.module.scss'

import Check from './Check'

const Main = forwardRef(({ inputClassName, checkClassName, ...properties }, ref) => {
  return <>
    <input
      className={classNames(styles.input, inputClassName)}
      type="checkbox"
      {...properties}
      {...{ref}}
    />

    <Check className={classNames(styles.checkbox, checkClassName)} />
  </>
})

export default Main
