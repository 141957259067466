import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const SignoutButton = ({ onClick: handleClick }) => {
  const { t } = useTranslation()

  return (
    <div id="logout" className="header__menu-dropdown-link cursor-pointer" onClick={handleClick}>
      {t('header.Logout')}
    </div>
  )
}

SignoutButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default SignoutButton
