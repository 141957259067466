import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {useTranslation, Trans} from 'react-i18next'

import Textarea from 'react-textarea-autosize'

import Reward from './Reward'

import Modal from '@application/index/component/modal/index'
import Copy from './Copy'
import Check from '@application/index/component/inputs/react-hook-form/Checkbox/Main/Check'
import AWithRelation from '@application/index/component/index/AWithRelation'

import joinWithLineBreak from './joinWithLineBreak'

import {className, last, checked} from './.module.scss'

import path from './path'
import { getPersonalFollowUpDiscount } from '@application/index/asset/store/actions/personalFollowUpDiscount'

const reviewLinks = [
  [
    'Trustpilot'
    , 'https://www.trustpilot.com/evaluate/www.designbro.com'
  ]
  , [
    'Google'
    , 'https://g.page/r/CcyQJ9D0ZQyDEAg/review'
  ]
]

var links = {}

const Offer = ({setDisplayed, comment, getPersonalFollowUpDiscount, ...props}) => {
  const {t} = useTranslation()

  useEffect(() => {
    getPersonalFollowUpDiscount()
  }, [])

  const [rewardDisplayed, setRewardDisplayed] = useState()
  , [links, setLinks] = useState({})

  const discountAmount = props.personalFollowUpDiscount?.value || 10
  const durationAmount = props.personalFollowUpDiscount?.duration || 90
  const discountType = props.personalFollowUpDiscount?.discount_type === 'percent' ? '%' : '$'

  return !rewardDisplayed ?
    <Modal
      size="large"
      {...{className, setDisplayed}}
      title={joinWithLineBreak(t(path + 'title'))}
      declineAction={t(path + 'decline')}
    >
      <p>
        <Trans
          i18nKey={path + 'description'}
          values={{discountAmount, discountType}}
        /> <span>({
          t(path + 'detail', {durationAmount})
        })</span>
      </p>
      <Textarea value={comment}/>
      <Copy content={comment} copyAction={t(path + 'copy')}/>
      {reviewLinks.map(([key, href], index) =>
        <AWithRelation
          className={classNames({
            [last]: 1 + index == reviewLinks.length
            , [checked]: links[key]
          })}
          title={key} {...{key, href}} target="_blank"
          onClick={() => {
            links[key] = true
            setLinks({...links})

            if (Object.values(links).length == 2)
              setRewardDisplayed(true)
          }}
        >
          <Check/>{key}
        </AWithRelation>
      )}
    </Modal>
  :
    <Reward {...{setDisplayed}}/>
}

const mapStateToProps = state => {
  return {
    personalFollowUpDiscount: state.personalFollowUpDiscount.getPersonalFollowUpDiscount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonalFollowUpDiscount: () => dispatch(getPersonalFollowUpDiscount())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
