import React from 'react'
import {Modal} from 'semantic-ui-react'

export default ({setDisplayed}) => {
  const onClick = () => setDisplayed(false)

  return <Modal open size="small"
    className="main-modal"
    onClose={onClick}
  >
    <div className="modal-primary">
      <div className="modal-primary__header bg-pink-500 in-white">
        <div className="modal__actions--top-right hidden-md-up">
          <button type="button" className="modal-close"
            {...{onClick}}
          >
            <i className="icon icon-cross in-white" />
          </button>
        </div>

        <p className="modal-primary__header-title">
          Wrong File Format!
        </p>
      </div>
      <div>
        <div className="modal-primary__body">
          <div className="modal-primary__body-block">
            <p className="modal-primary__text">
              You are trying to upload wrong file format.
              <br />

              Please retry and use only PNG, JPG, PDF, AI, TIF, TIFF, EPS, PSD, PSB, PPT, PPTX, DOC, DOCX, SVG, FIG, INDD, ICO, XD, MP4, GIF, MPEG, MOV, AEPX, AEP, C4D, F4V, FLV, procreate, ZIP, AEP.
            </p>
          </div>
        </div>
        <div className="modal-primary__actions flex-end">
          <button type="button"
            className="main-button-link main-button-link--lg main-button-link--black-pink m-b-10"
            {...{onClick}}
          >
            Got, it! <i className="m-l-20 font-8 icon-arrow-right-long"/>
          </button>
        </div>
      </div>
    </div>
  </Modal>
}