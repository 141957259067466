import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Form } from 'react-redux-form'

import styles from './.module.scss'

import Errors from '@application/index/component/inputs/Errors'
import useEmail from '@application/index/views/client/Project/new/components/SignUpModal/useEmail'
import usePassword from '@application/index/views/client/Project/new/components/SignUpModal/usePassword'
import MaterialInput from '@application/index/component/inputs/MaterialInput'

import t from '@application/index/views/Login/useTranslation'
import SubmitButton from '@application/index/views/Login/Form/SubmitButton'

const Content = ({signinEmail, onError}) => {
  const onSubmit = async v => {
    const fsa = await signinEmail(v)

    if (fsa.error)
      onError(fsa)
  }

  return (
    <Form className={styles.form}
      model="forms.signin"
      {...{onSubmit}}
    >
      <Errors model="." />

      {/* DON'T delete this line! It's solution for disabling inputs' auto complete */}
      <input type="password" style={{ display: 'none' }} />

      <MaterialInput
        id="email"
        type="email"
        name="email"
        label={useEmail()}
        model=".email"
        autoComplete="nope" />

      <MaterialInput
        id="password"
        type="password"
        name="password"
        label={usePassword()}
        model=".password"
        autoComplete="nope" />

      <Link className="sign-in__form-text text-underline text-left"
        to="/restore-password"
      >
        {t('passwordResetLinkRequest')}
      </Link>

      <SubmitButton>{t('logIn')}</SubmitButton>
    </Form>
  )
}

Content.propTypes = {
  onError: PropTypes.func.isRequired
}

export default Content
