import api from '@application/index/asset/utils/apiV2'

export const LOAD_BRANDS_REQUEST = 'project/builder/LOAD_BRANDS_REQUEST'
export const LOAD_BRANDS_SUCCESS = 'project/builder/LOAD_BRANDS_SUCCESS'
export const LOAD_BRANDS_FAILURE = 'project/builder/LOAD_BRANDS_FAILURE'

export const loadBrands = () => api.get({
  endpoint: '/api/v2/brands',
  types: [LOAD_BRANDS_REQUEST, LOAD_BRANDS_SUCCESS, LOAD_BRANDS_FAILURE]
})
