import React from 'react'

import styles from './.module.scss'

import useNarrowScreen from '../useNarrowScreen'

import { Open, Close } from './icon'

export default ({ navigationMenuOpen, toggleNavigationMenuOpen }) => {
  const narrowScreen = useNarrowScreen()

  return <>{narrowScreen && (() => {
    const Button = navigationMenuOpen ? Close : Open

    return <Button className={styles.button}
      onClick={() => toggleNavigationMenuOpen(true)}
    />
  })()}</>
}