import React from 'react'
import classNames from 'classnames'

import styles from './.module.scss'

export default ({className, children, footer}) =>
  <main className="sign-in__container">
    <div className={classNames('sign-in__form', className)}>
      <div className={styles.content}>
        {children}
      </div>
      {footer && <div className={styles.footer}>
        {footer}
      </div>}
    </div>
  </main>