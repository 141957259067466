import React from 'react'

import useShare from '../useShare'

import {className} from './.module.scss'

import Link from '@application/index/component/index/Link'
import {icon} from '../icon'

export default ({link, message}) => {
	const {shareOn} = useShare()

	return [
		['Facebook', 'www.facebook.com/sharer/sharer.php?u=']
		, [
			'Twitter',
			'twitter.com/share?url=',
			'&via=designbrodotcom&text=' + message
		]
		, ['LinkedIn', 'www.linkedin.com/sharing/share-offsite/?url=']
	].map(([network, url, parameters = '']) =>
		<Link key={network} as="a" {...{className}}
			title={shareOn(network)}
			href={'https://' + url + link + parameters}
			target="_blank" rel="noopener"
		>
			{icon(network)} {network}
		</Link>
	)
}