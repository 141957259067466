import { connect } from 'react-redux'

import ComingSoonModal from './component'

import { hideModal } from '@application/index/asset/store/actions/modal'

import { requestStartNotification } from '@application/index/asset/store/actions/comingSoon'

const mapStateToProps = (state) => ({
  saved: state.comingSoon.saved
})

const mapDispatchToProps = {
  onSubmit: requestStartNotification,
  onClose: hideModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoonModal)
