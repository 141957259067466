import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {loadProject} from '@application/index/asset/store/actions/newProject'

import t from './useTranslation'
import {showClientSignUpModal, showClientLoginModal} from '@application/index/asset/store/actions/modal'

const LoginNavigation = ({login, asModalWindow}) => {
  const onClick = login ?
    showClientSignUpModal
  :
    showClientLoginModal

  return [
    [!login, 'd/signup', t('Join')],
    [login, 'login', t('Login')]
  ].map(([
    active, to, children
  ]) => {
    const link = active || !asModalWindow

    const Component = link ? Link : 'div'

    return <Component key={to}
      className={classNames('cursor-pointer sign-in__form-nav-item', {
        'sign-in__form-nav-item--active': active
      })}
      {...(link ? {
        to: '/' + to
      } : {
        onClick
      })}
      {...{children}}
    />
  })
}

LoginNavigation.propTypes = {
  loadProject: PropTypes.func.isRequired
}

export default connect(null, {loadProject})(LoginNavigation)