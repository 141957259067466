import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

var timeout

export default ({
	as: Component = 'button'
	, children, content, copyAction, ...properties
}) => {
	const [displayCopied, setDisplayCopied] = useState()

	return <Component {...properties} onClick={() => {
		navigator.clipboard.writeText(content)
		clearTimeout(timeout)
		setDisplayCopied(true)
		timeout = setTimeout(() => setDisplayCopied(false), 1000)
	}}>
		{children}
		{useTranslation().t(
			displayCopied ?
				'client.project.poll.new.modal.copy.completed'
			:
				copyAction
		)}
	</Component>
}