import {
  POST_CLIENT_DISCOUNT_REQUEST,
  POST_CLIENT_DISCOUNT_SUCCESS,
  POST_CLIENT_DISCOUNT_FAILURE
} from '@application/index/asset/store/actions/discount'

import { combineReducers } from 'redux'

const clientDiscount = (state = {}, action) => {
  switch (action.type) {
  case POST_CLIENT_DISCOUNT_REQUEST:
    return null
  case POST_CLIENT_DISCOUNT_SUCCESS:
    return action.payload || null
  case POST_CLIENT_DISCOUNT_FAILURE:
    return null
  default:
    return state
  }
}

export default combineReducers({
  clientDiscount
})
