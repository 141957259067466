import useTranslate from '@application/index/asset/translation/index/useTranslate'

import {path} from './path'

export default () => {
	const translate = useTranslate(path)

	, share = subject => translate('share', {subject})

	return {
		share
		, shareOn: location => share(translate('on', {location}))
	}
}