import React from 'react'

import Layout from '@application/index/views/Layout'
import DarkLayout from '@application/index/views/designer/Dashboard/getLayout/Dark'
import SignInLayout from '@application/index/component/index/SignInLayout'
import DesignerSignupLayout from '@application/index/component/index/SignupLayout'
import DarkFooterLayout from '@application/index/component/index/DarkFooterLayout'

export const withLayout = (LayoutComponent, isClient) => (View) => (props) => {
  return (
    <LayoutComponent {...{isClient}}>
      <View {...props} />
    </LayoutComponent>
  )
}

export const withAppLayout = withLayout(Layout)
export const withDesigherAppLayout = withLayout(Layout, false)
export const withDarkAppLayout = withLayout(DarkLayout)

export const withSignInLayout = withLayout(SignInLayout)
export const withDesignerSignupLayout = withLayout(DesignerSignupLayout)
export const withDarkFooterLayout = withLayout(DarkFooterLayout)
