import capitalize from 'lodash/capitalize'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import React, { useState } from 'react'

import scorePassword from '@application/index/asset/utils/scorePassword'

import {valid, message} from './password'
import MaterialInput from '@application/index/component/inputs/MaterialInput'

const getLevel = score =>
  score <= 25 ?
    'weak'
  :
    score <= 60 ?
      'fair'
    :
      'strong'

const StatusLine = ({ score }) => {
  const level = getLevel(score)
  return (
    <div>
      <span className="main-input__strength-type">
        {capitalize(level)} Pass
      </span>
      <span className="main-input__strength-line">
        <span className={
          classNames('main-input__strength-line-bg', level)
        }/>
      </span>
    </div>
  )
}

const passwordText = 'password'

const PasswordField = ({
  name = passwordText, model = '.' + passwordText,
  validators, errors,
  ...properties
}) => {
  const [present, setPresent] = useState(false)
  const [score, setScore] = useState(0)

  const handleChange = value => {
    setPresent(!!value.length)
    setScore(scorePassword(value))
  }

  return (
    <MaterialInput
      id="password"
      type="password"
      {...{name, model}}
      validators={{
        ...validators, maximumPasswordLength: valid
      }}
      errors={{...errors, maximumPasswordLength: message}}
      {...properties}
      updateOn="change" onChange={event =>
        handleChange(event.target.value)
      }
    >
      {present && <StatusLine {...{score}} />}
    </MaterialInput>
  )
}

PasswordField.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  hasError: PropTypes.bool,
  error: PropTypes.string
}

export default PasswordField
