import { SET_TAGS } from '@application/index/asset/store/actions/tags'

const initialState = { names: [] }

export default (state = initialState, action) => {
  switch (action.type) {
  case SET_TAGS:
    return {
      ...state,
      names: action.payload,
    }
  default:
    return state
  }
}
