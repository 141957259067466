export default {
  'English': 'Engels',
  'Dutch': 'Nederlands',
  'Are you sure you want to skip': 'Weet je zeker dat je wil overslaan?',
  'Yes, skip': 'Ja, overslaan',
  'No, go back': 'Nee, ga terug',
  'Required': 'Verplicht',
  'or login here': 'of log <0>hier</0> in',
  notEmailAddress: 'Lijkt niet op email'
  , sendInvitation: 'Stuur uitnodiging'
  , replaceDesign: 'vervang ontwerp'
  , header: {
    'Account Settings': 'Accountinstellingen',
    'My Brands': 'Mijn Merken',
    'Transactions': 'Transacties',
    'Logout': 'Logout'
  },
  continue: 'Doorgaan'
  , back: 'Terug'
  , projectBuilder: {
    'projectBuilder_tooltip': "Dit is het gemiddelde aantal ontwerpen om uit te kiezen. Aan het einde van het project ontvang je alle bestanden voor 1 ontwerp.",
    '99% happy customers': '99% tevreden klanten. Dat houden we graag zo. Voorwaarden van toepassing',
    'A brand new design': 'Een gloednieuw ontwerp',
    'Add more': 'Meer toevoegen',
    'Additional screens': 'extra schermen',
    'Additional variants': 'extra varianten',
    'Awesome!': 'Super!',
    'Billing address': 'Factuuradres',
    'Blind Project': 'Blind Project',
    'Bold': 'Uitgesproken',
    'Book now': 'Maak nu een afspraak',
    'Book your call with one of our experts': 'Maak een afspraak met een expert van ons',
    'Brand description': 'Merk beschrijving',
    'Brand name': 'Merk naam',
    'Business': 'Bedrijf',
    'Can you show us inspirational colors you think look great?': 'Wil je kleuren kiezen voor je ontwerp?',
    'Can you show us inspirational designs you think look great?': 'Kan je inspirerende designs laten zien?',
    'Can you show us your competitors?': 'Kan je voorbeelden van je concurrentie laten zien?',
    'Can you tell us about the brand': 'Kan je ons over het merk vertellen?',
    'Change existing design': 'Verander een bestaand ontwerp',
    'Checkout': 'Afrekenen',
    'Clean': 'Rustig',
    'Comment (optional)': 'Commentaar (optioneel)',
    'Company name (optional)': 'Bedrijfsnaam (optioneel)',
    'Country': 'Land',
    'Create new account': 'Maak een nieuw account',
    'Create new brand': 'Maak een nieuw merk aan',
    'Create your account': 'Maak je account aan',
    'Dedicated account manager': 'Persoonlijke project manager',
    'Delivered by our full agency partner': 'Geleverd door onze volwaardige agency partner Gency.co',
    'Describe your brand DNA': 'Beschrijf je merk DNA',
    'Design': 'Ontwerp',
    'Detailed': 'Gedetailleerd',
    'Discount': 'Korting',
    'Do you have a tagline, slogan or any additional text for your logo': 'Heb je een slogan of andere tekst voor op je logo?',
    'Do you have any ideas or special requirements for the designers?': 'Heb je ideeën of speciale eisen voor de designers?',
    'eg style guides, moodboards, your own scribbles etc': 'bijv. stijlgidsen, moodboards, eigen aantekeningen etc.',
    'eg ‘Founded in 2020’ or ‘The best bakery in town’': 'bijv. ‘Opgericht in 2020’ of ‘De beste bakker van de stad’.',
    'Email': 'E-mail',
    'Expect 10 - 14 designs': 'Verwacht 10 - 14 ontwerpen',
    'Expect 3 - 6 designs': 'Verwacht 3 - 6 ontwerpen',
    'Expect 5 - 8 designs': 'Verwacht 5 - 8 ontwerpen',
    'Expect 5 designs': 'Verwacht 5 ontwerpen',
    'Expect the first designs within 24-48 hours so sit back & relax or read our tips & tricks to get the most out of your project': 'Verwacht de eerste ontwerpen binnen 24-48 uur. Wacht dit rustig af of neem de tijd om onze tips te lezen om zo het meeste uit je project te halen',
    'fair pass': 'Voldoende sterk wachtwoord',
    'Feminine': 'Vrouwelijk',
    'First name': 'Voornaam',
    'Fit in the category': 'Past binnen de doelgroep',
    'Free upgrade': 'Gratis upgrade',
    'Free': 'Gratis',
    'From 10+ professional designers': 'Van 10+ professionele ontwerpers',
    'From 3+ professional designers': 'Van 3+ professionele ontwerpers',
    'From 5 hand-picked designers': 'Van 5 hand-geselecteerde ontwerpers',
    'From 5+ professional designers': 'Van 5+ professionele ontwerpers',
    'Full copyright': 'Volledige copyright',
    'Give us a bit of background, what makes you special': 'Geef ons wat achtergrondinformatie, wat maakt je speciaal?',
    'Go to my projects': 'Ga naar mijn projecten',
    'Got a discount code': 'Heb je een kortingscode?',
    'Handcrafted': 'Handgemaakt',
    'High income': 'Hoog inkomen',
    'hmm.. We really need to know your brand name before we move on': 'hmm.. we hebben echt een merknaam nodig voordat we verder kunnen',
    'Ideas or special requirements (optional)': 'Ideeën of speciale vereisten (optioneel)',
    'Larger designer prize': 'Grotere fee voor ontwerpers',
    'Last Name': 'Achternaam',
    'Let’s upload more items': 'Meer bestanden uploaden',
    'Low income': 'Laag inkomen',
    'Made to order solution': 'Maatwerk oplossing',
    'Masculine': 'Mannelijk',
    'Mature': 'Volwassen',
    'Max file size': 'Max bestandsgrootte',
    'Minimalist': 'Minimalistisch',
    'Modern': 'Modern',
    'Money back guarantee': 'Niet goed geld terug garantie',
    'Money back guarantee*': 'Niet goed geld terug garantie*',
    'Move the sliders to let us know which of the following best fits the image / identity of the brand you are trying to create': 'Verplaats de schuifregelaars om ons te laten weten wat het beste past bij het merk / de identiteit die je wilt creëren',
    'Move the sliders to let us know which of the following best shows who your customer is': 'Verplaats de schuifregelaars om ons te laten weten wie je klant is',
    'Neutral': 'Neutraal',
    'No, don’t show competitors': 'Nee, laat geen concurrenten zien',
    'No, don’t show inspiration': 'Nee, laat geen inspiratie zien',
    'No, let the designer decide': 'Nee, laat de ontwerper kiezen',
    'oops': 'oeps...',
    'oops Select at least': 'oeps... Selecteer tenminste {{count}} logo ontwerpen voordat we verder gaan',
    'or enter your color (codes) manually': 'vul je kleur (codes) handmatig in',
    'Password': 'Wachtwoord',
    'Pay now & start': 'Betaal nu en start',
    'Payment methods accepted': 'Beschikbare betaalmethodes',
    'Playful': 'Speels',
    'Please create your account before checking out': 'Maak een account aan voordat je afrekent',
    'Please fill in the required fields before being able to pay & start': 'Vul de verplichte velden voordat je kan afrekenen en starten',
    'Premium': 'Premium',
    'Priority support': 'Priority support',
    'Pro': 'Pro',
    'Project intake call with a brand consultant': 'Project intake afspraak met een merkconsultant',
    'Project price': 'Project prijs',
    'Refined': 'Verfijnd',
    'refund policy': 'niet goed geld terug beleid',
    'Request intake call': 'Plan je intake',
    'Required': 'Verplicht',
    'Save your progress': 'Sla je gegevens op',
    'See our': 'Zie ons',
    'Select at least': 'Selecteer tenminste {{count}} ontwerpen die volgens jou passen bij jouw merk, zodat de ontwerpers jouw visuele stijl begrijpen',
    'Select color': 'Selecteer kleur',
    'Select designs you like': 'Selecteer ontwerpen de je mooi vindt',
    'Select your brand below': 'Selecteer je merk hieronder',
    'Select your color here': 'Selecteer je kleur hier',
    'Select': 'Selecteer',
    'Selected': 'Geselecteerd',
    'Serious': 'Serieus',
    'Show your competitors to help the designers understand the market in which you operate': 'Laat je concurrenten zien zodat ontwerpers jouw markt goed begrijpt',
    'Skip this step': 'Sla stap over',
    'Stand out from the crowd': 'Anders dan de rest',
    'Standard NDA': 'Standaard geheimhoudingsverklaring (NDA)',
    'Starter': 'Starter',
    'strong pass': 'Sterk wachtwoord',
    'Summary': 'Samenvatting',
    'Super modern': 'Super modern',
    'Supported file types': 'Ondersteunde bestandsformaten',
    'Tagline, slogan or additional text (optional)': 'Slogan of extra tekst (optioneel)',
    'Take me to checkout': 'Breng me naar de checkout',
    'Tell us about your target audience': 'Vertel over je doelgroep',
    'Tell us the brand name you need in your logo': 'Vertel welke merknaam je in je logo wilt',
    'This file is too big': 'Dit bestand is te groot',
    'This file type is not supported': 'Dit bestandsformaat wordt niet ondersteund',
    'This would really help the designers and would likely give better results for your project': 'Dit zou de ontwerpers veelhelpen en zorgt waarschijnlijk voor betere projectresultaten.',
    'Tips & Tricks': 'Tips & Tricks',
    'Top designers': 'Top ontwerpers',
    'Total': 'Totaal',
    'Traditional': 'Traditioneel',
    'Unlimited revisions': 'Onbeperkte aanpassingen',
    'Upgrade Pack': 'Upgrade Pakket',
    'Upload your additonal items here': 'Upload je extra bestanden hier',
    'Upload your competitors here': 'Upload je concurrenten hier',
    'Upload your existing design here': 'Upload jouw bestaande ontwerp hier',
    'Upload your existing logo here': 'Upload your existing logo here',
    'Upload': 'Upload',
    'Value': 'Goede prijs-kwaliteit',
    'VAT number (optional)': 'BTW nummer (optioneel)',
    'VAT': 'BTW',
    'Very traditional': 'Heel traditioneel',
    'weak pass': 'Zwak wachtwoord',
    'Welcome back': 'Welkom terug!',
    'What are we going to create for you?': 'Wat gaan we voor je creëren?',
    'What is it that you do': 'Wat doen jullie voor werk?',
    'Which countries will your design be aimed at': 'Op welke landen richt je je met het ontwerp?',
    'Which design pack would you like?': 'Welk ontwerp pakket zou je willen? ',
    'Would you like to upload anything else for the designers': 'Wil je nog iets anders uploaden voor de ontwerpers?',
    'Yes, I can show competitors': 'Ja, ik kan concurrenten laten zien',
    'Yes, I can show inspiration': 'Ja, ik kan inspiratie laten zien',
    'Yes, let’s pick some color': 'Ja, laten we kleuren kiezen',
    'You can drag & drop the file from your computer': 'Je kan de bestanden slepen vanaf je computer',
    'You save': 'Je bespaart {{amount}}',
    'You selected a maximum of': 'Je hebt het maximum van 5 ontwerpen geselecteerd, de-selecteer eerst een ontwerp voordat je een andere kiest',
    'Youthful': 'Jeugdig',
    'You’re good to go!': 'Het is gelukt!',
    'We plant 1 tree': 'We planten 1 boom',
    'every design project you start': 'voor elk gestart project',
    'Change': 'Aanpassen',
    'Make your project a secret (NDA)': 'Maak je project geheim (NDA)',
    'For just {{price}} you can keep your project a total secret': 'Voor maar {{price}} kan je jouw project geheel verborgen houden met onze geheimhoudingsverklaring (NDA). Zo blijft je project uit Google zoekopdrachten en de ontwerpers gaan dan akkoord met de geheimhoudingsverklaring voordat ze de briefing kunnen lezen. Zie de tekst <button>hier</button>.',
    'Added': 'Toegevoegd',
    'Add': 'Toevoegen',
    'Agency': 'Agency',
    'Full Agency': 'Agency',
    'Yes, let me upload my images': 'Ja, laat me mijn afbeeldingen uploaden',
    "No, I don't yet have images": "Nee, ik heb nog geen afbeeldingen",
    'Upload your inspiration here': 'Upload hier je inspiratie',
    'Brand Identity Design Brief': 'Huisstijl Ontwerp Briefing',
    'Complete the questions and get started!': 'Vul de vragen in en ga aan de slag!',
    'Business card (front side)': 'Visitekaartje (voorzijde)',
    'Which details would you like on the front?': 'Welke details wil je op de voorkant?',
    'Business card (back side)': 'Visitekaartje (achterkant)',
    'Which details would you like on the back?': 'Welke details wil je op de achterkant?',
    'Letterhead': 'Briefhoofd',
    'Which details would you like on the Letterhead (top and bottom)?': 'Welke details wil je op het briefhoofd (boven en onder)?',
    'Compliment/note slip': 'Compliment/notitiebriefje',
    'What details would you like on the compliment slip?': 'Welke details wil je op het complimentenbriefje?',
    'Please fill in all mandatory fields': 'Vul alsjeblieft alle vereiste velden in',
    'Would you like the designers to use stock images?': "Wil je dat de ontwerpers stockfoto's gebruiken?",
    'Yes, use stock images': "Ja, gebruik stockfoto's",
    "No, I don't need any images": "Nee, ik heb geen afbeeldingen nodig",
    'Free stock images only': "Alleen gratis stockfoto's",
    'Free or paid stock images': "Gratis of betaalde stockfoto's",
    'Expect to pay +/- $50 per photo': 'Reken op +/- $50 per foto',
    'Our designers would be more than happy to help you find professional images': "Onze ontwerpers helpen je graag bij het vinden van professionele afbeeldingen die bij je merk passen uit gratis of betaalde bronnen. Voor de betaalde bronnen kijken ze op grote stockfotografie-websites. Zij kunnen je een kostenindicatie geven en zodra je een afbeelding goedkeurt, sturen ze je een link en kun je de afbeelding rechtstreeks bij de leverancier kopen. DesignBro of de ontwerpers zijn niet gelieerd aan deze derden en verdienen geen commissie over deze aankopen.",
    'Tell us in which language your briefing will be': 'Vertel ons in welke taal je briefing zal zijn',
    tip: 'Pro tip: vul de briefing in in het Engels om meer ontwerpers te bereiken'
    , 'Popular': 'Populair',
    'Upload your technical drawing here': 'Upload je technische tekening hier',
    'Lets get started': 'Laten we beginnen',
    'Upload your images or photos here': "Upload je afbeeldingen of foto's hier",
    'Rate the design': 'Beoordeel het ontwerp',
    'Or start a logo project first': 'Of start eerst een logo project',
    'packaging': {
      'Prices based on': 'Prijs gebaseerd op {{count}} varianten',
      'How many': 'Hoeveel varianten wil je hebben?',
      'Select the number': 'Selectere het aantal packaging varianten dat je wil laten ontwerpen (bijv. 2 verschillende formaten of 3 verschillende smaken, etc.)'
    },
    'website': {
      'Prices based on': 'Prijs gebaseerd op {{count}} schermen',
      'How many': 'Hoeveel schermen wil je?',
      'Select the number': 'Selecteer het aantal schermen dat je wil laten ontwerpen (bijv. homepage, product pagina, contact, over ons, etc.)'
    },
    'Brand DNA': {
      'hint': 'Verplaats de schuifregelaars om ons te laten weten welke van de volgende opties het beste past bij het imago/de identiteit van het merk dat je wilt creëren.'
    },
    'What size': {
      questions: {
        'album-cover': 'Welke afmeting albumhoes wil je?',
        'book-cover': 'Welke afmeting boekomslag wil je?',
        'business-card': 'Welk formaat wordt het visitekaartje?',
        'magazine-cover': 'Welk formaat magazine wil je?',
        't-shirt': 'Hoe groot wordt het ontwerp op het t-shirt?',
        billboard: 'welke maat billboard wil je?',
        flyer: 'Welke maat flyer wil je?',
        menu: 'Welke maat menukaart wil je?',
        poster: 'Welke maat wordt je poster?'
      },
      placeholders: {
        'album-cover': 'bijv. 3000 bij 3000 pixels',
        'book-cover': 'bijv. 13 bij 20 cm',
        'business-card': 'bijv. 85 bij 55 mm',
        'magazine-cover': 'bijv. 22 bij 29 cm',
        't-shirt': 'bijv 20 bij 20 cm',
        billboard: 'bijv. 240 bij 330 cm',
        flyer: 'bijv. 98 bij 210 mm',
        menu: 'bijv. 98 bij 210 mm',
        poster: 'bijv 594 bij 840 mm'
      }
    },
    'What is it for': {
      questions: {
        'album-cover': 'Waarvoor is de albumhoes?',
        'book-cover': 'Waarvoor is de boekomslag?',
        'business-card': 'Waarvoor is het visitekaartje?',
        'magazine-cover': 'Waarvoor is de magazine omslag voor?',
        't-shirt': 'Waarvoor is het t-shirt ontwerp?',
        billboard: 'Waarvoor is het billboard?',
        flyer: 'Waarvoor is de flyer?',
        menu: 'Waarvoor is het menu?',
        poster: 'Waarvoor is de poster?',
        packaging: 'Wat voor verpakking gaan we ontwerpen?',
        'instagram-post': 'Waarvoor is de Instagram post?',
        'facebook': 'Waarvoor is de Facebook brand page?',
        'twitter': 'Waarvoor is de Twitter profielpagina?',
        'linkedin': 'Waarvoor is de  is de LinkedIn bedrijfspagina?',
        'youtube': 'Waar is het YouTube kanaal voor?',
        'website-banner': 'Waar is de website banner voor?',
        'packaging-additional-variant': 'Waarvoor is de poster?'
      },
      hints: {
        'album-cover': 'Vertel ons iets meer over je albumhoesproject, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'book-cover': 'Vertel ons iets meer over je boekomslagproject, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'business-card': 'Vertel ons iets meer over je visitekaartje project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'magazine-cover': 'Vertel ons iets meer over je tijdschrift, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        't-shirt': 'Vertel ons iets meer over je t-shirt ontwerp project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        billboard: 'Vertel ons iets meer over je billboard, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        flyer: 'Vertel ons iets meer over je flyer, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        menu: 'Vertel ons iets meer over je menu kaart, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        poster: 'Vertel ons iets meer over je poster project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        packaging: 'Vertel ons iets meer over je verpakking project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'instagram-post': 'Vertel ons iets meer over je instagram post, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'facebook': 'Vertel ons iets meer over je facebook pagina project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'twitter': 'Vertel ons iets meer over je twitter profiel project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'linkedin': 'Vertel ons iets meer over je Linkedin bedrijfspagina, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'youtube': 'Vertel ons iets meer over je YouTube kanaal, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'website-banner': 'Vertel ons iets meer over je website advertentie project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project',
        'packaging-additional-variant': 'Vertel ons iets meer over je verpakking project, beschouw het als het \'wat?\' en het \'waarom?\' voor je project'
      },
      placeholders: {
        'album-cover': 'Albumhoes reden',
        'book-cover': 'Boekomslag reden',
        'magazine-cover': 'Tijdschrift cover reden',
        't-shirt': 'T-shirt reden',
        billboard: 'Billboard reden',
        flyer: 'Flyer reden',
        menu: 'Menu reden',
        poster: 'Poster reden',
        packaging: 'Packaging reden',
        'instagram-post': 'Instagram post reden',
        'facebook': 'Facebook brand pagina reden',
        'twitter': 'Twitter profiel reden',
        'linkedin': 'LinkedIn bedrijfspagina reden',
        'youtube': 'YouTube kanaal reden',
        'website-banner': 'Website advertentie reden',
        'business-card': 'Waarvoor is het visitekaartje?',
        'packaging-additional-variant': 'Packaging reden'
      }
    },
    'Technical Drawing': {
      'questions.packaging': 'Heb je bestaande technische tekeningen (of die-cuts) of een afbeelding van de verpakking die je gaat gebruiken?'
      , 'yes.packaging': 'Ik kan een technische tekening of afbeelding delen'
      , 'no.packaging': 'Ik heb geen technische tekening of afbeelding'
      , 'questions.packaging-additional-variant': 'Heb je bestaande technische tekeningen (of die-cuts) of een afbeelding van de verpakking die je gaat gebruiken?'
      , 'yes.packaging-additional-variant': 'Ik kan een technische tekening of afbeelding delen'
      , 'no.packaging-additional-variant': 'Ik heb geen technische tekening of afbeelding'
    },
    'Technical details': {
      'questions.packaging': 'Kun je ons vertellen welk type verpakking je van plan bent te gebruiken, is het een blikje, een fles, een doos, een etiket of iets anders?'
      , 'hints.packaging': 'Geef ook de maat aan als je het hebt, of plaats een link naar de verpakking als je het hebt.'
      , 'placeholders.packaging': ''
    },
    'Product text': {
      questions: {
        'album-cover': 'Welke tekst wil je op je albumhoes??',
        'book-cover': 'Welke tekst zou je op je boekomslag willen hebben?',
        'business-card': 'Welke tekst wil je op je visitekaartje?',
        'magazine-cover': 'Welke tekst zou je op je tijdschriftomslag willen hebben??',
        't-shirt': 'Welke tekst zou je op je t-shirt willen hebben?',
        billboard: 'Welke tekst wil je op je billboard??',
        flyer: 'Welke tekst wil je op je flyer??',
        menu: 'Welke tekst wil je op je menukaart??',
        poster: 'Welke tekst wil je op je poster?',
        website: 'Welke tekst wil je op je website?',
        'website-banner': 'Welke tekst wil je op je advertentie?',
        'instagram-post': 'Welke tekst wil je op je instagram post?',
        'website-additional-screens': 'Welke tekst wil je op je website?'
      },
      hints: {
        'album-cover': 'Laat ons weten welke tekst je op je albumhoes zou willen hebben',
        'book-cover': 'Je kunt aangeven wat je wilt op de voor-, rug- en achterkant van je boekomslag',
        'business-card': 'Je kunt aangeven wat je wilt op de voor- en achterkant van het visitekaartje',
        'magazine-cover': 'Laat ons weten welke tekst je graag op de voorkant van je tijdschrift zou willen hebben',
        't-shirt': 'Je kunt zelf aangeven welke tekst en logo je op je t-shirt wilt laten bedrukken',
        billboard: 'Laat ons weten welke tekst je op het billboard zou willen hebben',
        flyer: 'Laat ons weten welke tekst je op de flyer zou willen hebben',
        menu: 'Je kunt op elke pagina/zijde van het menu aangeven wat je wilt',
        poster: 'Laat ons weten welke tekst je op je poster zou willen hebben',
        website: 'Als je nog geen definitieve tekst hebt, maak je geen zorgen, vraag de ontwerper om voorbeeldtekst te gebruiken!',
        'website-banner': 'Bijvoorbeeld: ik wil een banner maken die klanten vertelt over de speciale deal die ze krijgen als ze zich registreren via onze website.',
        'instagram-post': 'Welke tekst zou je op je Instagram-bericht willen plaatsen?',
        'website-additional-screens': 'Als je nog geen definitieve tekst hebt, maak je geen zorgen, vraag de ontwerper om voorbeeldtekst te gebruiken!'
      },
      placeholders: {
        'album-cover': 'Albumhoest tekst',
        'book-cover': 'Boekomslag tekst',
        'business-card': 'Visitekaartje tekst',
        'magazine-cover': 'Tijdschriftomslag tekst',
        't-shirt': 'T-shirt tekst',
        billboard: 'Billboard tekst',
        flyer: 'Flyer tekst',
        menu: 'Menu tekst',
        poster: 'Poster tekst',
        website: 'Website tekst',
        'website-banner': 'Website advertentie tekst',
        'instagram-post': 'Instagram post tekst',
        'website-additional-screens': 'Website tekst'
      }
    },
    'Brand Name': {
      questions: {
        'album-cover': 'Voor welk merk wil je een albumhoes maken?',
        'book-cover': 'Voor welk merk wil je een boekomslag maken?',
        'business-card':  'Voor welk merk wil je een visitekaartje maken?',
        'magazine-cover': 'Voor welk merk wil je een tijdschrift cover maken?',
        't-shirt': 'Voor welk merk wil je een t-shirt maken?',
        'zoom-background': 'Voor welk merk wil je een zoom achtergrond maken?',
        billboard: 'Voor welk merk wil je een billboard maken?',
        flyer: 'Voor welk merk wil je een flyer maken?',
        logo: 'Welke merknaam wil je in je logo hebben?',
        'brand-identity': 'Vertel ons welke merknaam je in je huisstijl wil gebruiken',
        menu: 'Voor welk bedrijf wil je een menukaart maken?',
        packaging: 'Voor welk merk wil je een verpakking ontwerpen?',
        poster: 'Voor welk merk wil je een poster maken?',
        website: 'Voor welk merk wil je een website ontwerp maken?',
        'website-banner': 'Voor welk merk wil je een website advertentie maken?',
        'instagram-post': 'Voor welk merk wil je een Instagram post maken?',
        'facebook': 'Voor welk merk wil je een Facebook brand page maken?',
        'twitter': 'Voor welk merk wil je een Twitter profiel maken?',
        'linkedin': 'Voor welk merk wil je een Linkedin bedrijfspagina maken?',
        'youtube': 'Voor welk merk wil je een YouTube kanaal maken?'
      }
    },
    'Existing Design Step': {
      questions: {
        'album-cover': 'Heb je al een logo dat je op je albumhoes wilt gebruiken?',
        'business-card': 'Heb je al een logo dat je op je visitekaartje wilt gebruiken?',
        'book-cover': 'Heb je al een logo dat je op je boekomslag wilt gebruiken?',
        'magazine-cover': 'Heb je al een logo dat je op je tijdschrift wilt gebruiken?',
        'zoom-background': 'Heb je al een logo dat je op je zoom achtergrond wilt gebruiken?',
        't-shirt': 'Heb je al een logo dat je op je t-shirt wilt gebruiken?',
        billboard: 'Heb je al een logo dat je op je billboard wilt gebruiken?',
        flyer: 'Heb je al een logo dat je op je flyer wilt gebruiken?',
        logo: 'Wat gaan we voor je maken?',
        'brand-identity': 'Wat gaan we voor je maken?',
        menu: 'Heb je al een logo dat je op je menu wilt gebruiken?',
        poster: 'Heb je al een logo dat je op je poster wilt gebruiken?',
        packaging: 'Heb je al een logo dat je op je verpakking wilt gebruiken?',
        website: 'Heb je al een logo dat je op je website wilt gebruiken?',
        'website-banner': 'Heb je al een logo dat je op je website advertentie wilt gebruiken?',
        'instagram-post': 'Heb je al een logo dat je op je Instagram post wilt laten zien?',
        'facebook': 'Heb je al een logo dat je op je Facebook pagina wilt gebruiken?',
        'twitter': 'Heb je al een logo dat je op je twitter profiel wilt gebruiken?',
        'linkedin': 'Heb je al een logo dat je op je Linkedin profiel wilt gebruiken?',
        'youtube': 'Heb je al een logo dat je op je YouTube kanaal wilt gebruiken?',
        'packaging-additional-variant': 'Heb je al een logo?',
        'website-additional-screens': 'Heb je al een logo dat je op je website wilt gebruiken?'
      },
      yesAnswer: {
        'album-cover': 'Ja, ik heb een logo voor mijn albumhoes',
        'book-cover': 'Ja, ik heb een logo voor mijn boekomslag',
        'business-card': 'Ja, ik heb een logo voor mijn visitekaartje',
        'magazine-cover': 'Ja, ik heb een logo voor mijn tijdschrift',
        'zoom-background': 'Ja, ik heb een logo voor mijn zoom achtergrond',
        't-shirt': 'Ja, ik heb een logo voor mijn t-shirt',
        billboard: 'Ja, ik heb een logo voor mijn billboard',
        flyer: 'Ja, ik heb een logo voor mijn flyer',
        logo: 'Verander mijn bestaande ontwerp',
        'brand-identity': 'Verander mijn bestaande ontwerp',
        menu: 'Ja, ik heb een logo voor mijn menu',
        poster: 'Ja, ik heb een logo voor mijn poster',
        packaging: 'Ja, ik heb een logo voor op mijn verpakking',
        website: 'Ja, ik heb een logo voor mijn website',
        'website-banner': 'Ja, ik heb een logo voor mijn advertentie',
        'instagram-post': 'Ja, ik heb een logo voor mijn Instagram post',
        'facebook': 'Ja, ik heb een logo voor mijn Facebook brand pagina',
        'twitter': 'Ja, ik heb een logo voor mijn Twitter profiel',
        'linkedin': 'Ja, ik heb een logo voor mijn LinkedIn pagina',
        'youtube': 'Ja, ik heb een logo voor mijn YouTube kanaal',
        'packaging-additional-variant': 'ja, ik heb een logo',
        'website-additional-screens': 'Ja, ik heb een logo voor mijn website'
      },
      noAnswer: {
        'album-cover': 'Nee, ik heb geen logo nodig op mijn albumhoes',
        'book-cover': 'Nee, ik heb geen logo nodig op mijn boekomslag',
        'business-card': 'Nee, ik heb geen logo nodig op mijn visitekaartje',
        'magazine-cover': 'Nee, ik heb geen logo nodig op mijn tijdschrift',
        'zoom-background': 'Nee, ik heb geen logo nodig op mijn zoom achtergrond',
        't-shirt': 'Nee, ik heb geen logo nodig op mijn t-shirt',
        billboard: 'Nee, ik heb geen logo nodig op mijn billboard',
        flyer: 'Nee, ik heb geen logo nodig op mijn flyer',
        logo: 'Een gloednieuw ontwerp',
        'brand-identity': 'Een gloednieuw ontwerp',
        menu: 'Nee, ik heb geen logo nodig op mijn menu',
        poster: 'Nee, ik heb geen logo nodig op mijn poster',
        packaging: 'Maak een logo voor mij',
        website: 'Nee, ik heb geen logo nodig op mijn website',
        'website-banner': 'Nee, ik heb geen logo nodig op mijn advertentie',
        'instagram-post': 'Nee, ik heb geen logo nodig op mijn instagram post',
        'facebook': 'Nee, ik heb geen logo nodig op mijn Facebook pagina',
        'twitter': 'Nee, ik heb geen logo nodig op mijn Twitter profiel',
        'linkedin': 'Nee, ik heb geen logo nodig op mijn LinkedIn pagina',
        'youtube': 'Nee, ik heb geen logo nodig op mijn Youtube kanaal',
        'packaging-additional-variant': 'Start eerst een logo project',
        'website-additional-screens': 'Nee, ik heb geen logo nodig op mijn website'
      }
    },
    'Existing Images Step': {
      questions: {
        'album-cover': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de albumhoes?',
        'book-cover': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de boekomslag?',
        'business-card': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op het visitekaartje?',
        'magazine-cover': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de tijdschrijftomslag?',
        't-shirt': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op je t-shirt?',
        'zoom-background': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de zoom achtergrond?',
        billboard: 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op het billboard?',
        flyer: 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de flyer?',
        menu: 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de menukaart?',
        poster: 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de poster?',
        packaging: 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de verpakking?',
        website: 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de website?',
        'website-banner': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de website banner?',
        'instagram-post': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de Instagram post?',
        'facebook': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op je Facebook brand pagina?',
        'twitter': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op je Twitter profiel?',
        'linkedin': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de LinkedIn pagina?',
        'youtube': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op het YouTube kanaal?',
        'packaging-additional-variant': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de verpakking?',
        'website-additional-screens': 'Heb je al afbeeldingen of foto\'s die je wilt gebruiken op de website?'
      }
    },
    'Existing Packaging Design': {
      'questions.packaging': 'Heb je al een bestaand ontwerp dat je wil aanpassen?'
      , 'yesAnswer.packaging': 'Ja, ik heb een bestaand ontwerp voor mijn verpakking'
      , 'noAnswer.packaging': 'Nee, ik heb nog geen ontwerp voor mijn verpakking'
      , 'questions.packaging-additional-variant': 'Heb je al een bestaand ontwerp dat je wil aanpassen?'
      , 'yesAnswer.packaging-additional-variant': 'Ja, ik heb een bestaand ontwerp voor mijn verpakking'
      , 'noAnswer.packaging-additional-variant': 'Maak eerst een verpakkings ontwerp'
      , 'Please upload existing design first.packaging-additional-variant': 'Upload eerst je bestaande ontwerp'
    }
  },
  projectBrief: {
    'Additional documents': 'Extra documenten',
    'Additional text for logo': 'Extra tekst voor logo',
    'Bold': 'Uitgesproken',
    'Brand description': 'Merk beschrijving',
    'Brand DNA': 'Merk DNA',
    'Brand Name': 'Merknaam',
    'Brief': 'Briefing',
    'Clean': 'Rustig',
    'Colors': 'Kleuren',
    'Competitors': 'Concurrenten',
    'Designs': 'Ontwerpen',
    'Detailed': 'Gedetailleerd',
    'Examples': 'Voorbeelden',
    'Existing Design': 'Bestaand ontwerp',
    'Feminine': 'Vrouwelijk',
    'Fit in, look as if it belongs in the category': 'Past binnen de doelgroep, categorie',
    'Good examples': 'Goede voorbeelden',
    'Handcrafted': 'Handgemaakt',
    'High income': 'Hoog inkomen',
    'Ideas or special requirements': 'Ideeën of speciale vereisten',
    Inspiration: 'Inspiratie'
    , 'Logo Design': 'Logo Ontwerp',
    'Look as if it has been around for 100 years': 'Alsof het al 100 jaar bestaat',
    'Low income': 'Laag inkomen',
    'Masculine': 'Mannelijk',
    'Mature': 'Volwassen',
    'Minimalist': 'Minimalistisch',
    'Modern': 'Modern',
    'Playful': 'Speels',
    'Premium': 'Premium',
    'Project type': 'Project type',
    'Refined': 'Verfijnd',
    'Serious': 'Serieus',
    'Slogan': 'Slogan',
    'Stand out from the crowd': 'Anders dan de rest',
    'Startup ready to take on the world': 'Startup klaar om de wereld te veroveren',
    'Target Audience': 'Doelgroep',
    'Traditional': 'Traditioneel',
    'Value': 'Goede prijs-kwaliteit',
    'Youthful': 'Jeugdig',
    'Stationery': 'Schrijfwaren',
    'Existing Logo': 'Bestaand Logo',
    'Front business card details': 'Gegevens voorkant visitekaartje',
    'Back business card details': 'Gegevens achterkand visitekaartje',
    'Letter head': 'Briefhoofd',
    'Compliment': 'Compliment',
    'Number of pages to be designed': "Aantal te ontwerpen pagina's",
    'Number of variants to be designed': 'aantal te ontwerpen varianten',
    'Name': 'Naam',
    'Additional text for package': 'Extra tekst',
    'What would you like us to create': 'Wat zou je willen dat we maken?',
    'Background story': 'Achtergrond / historie',
    'What is special': 'Wat is speciaal',
    'Where it is used': 'Waar wordt het gebruikt',
    'Can you tell us what type of pack': 'Kun je ons vertellen wat voor soort verpakking je van plan bent te gebruiken, is het een blik, een fles, een doos, een etiket of iets anders?',
    'Dont need a logo to be featured on my': "Ik heb  geen logo nodig op mijn",
    'Download Zip': 'Download Zip',
    'Comment': 'Commentaar',
    'Rate the design': 'Beoordeel het ontwerp',
    'Colors to use': 'Kleuren om te gebruiken',
    'Countries where design will be aimed at': 'Landen waar het ontwerp op gericht zal zijn',
    'Download': 'Download',
    'Bad examples': 'Slechte voorbeelden',
    'Use free (for commercial use) images only': 'Gebruik alleen gratis (voor commercieel gebruik) afbeeldingen',
    'Use free or paid/stock images': 'Gebruik gratis of betaalde/stock afbeeldingen',
    'Whenever (paid) stock photos are being used in the design': "Als er (betaalde) stockfoto's in het ontwerp gebruikt worden, geef de klant dan een link naar de afbeelding diet hij moet kopen. (Opmerking: je moet de afbeelding nooit zelf kopen.)",
    'Images to use': 'Afbeeldingen om te gebruiken',
    'No images needed': 'Geen afbeeldingen nodig',
    'Technical drawings': 'Technische tekeningen'
  },
  moneyBackGuarantee: {
    'All refunds are at the discretion of the Support Executive at DesignBro': 'All refunds are at the discretion of the Support Executive at DesignBro.',
    'Client approaches the designer to work off-platform': 'Client approaches the designer to work off-platform',
    'Client gives a star-rating of 4 or higher to any design': 'Client gives a star-rating of 4 or higher to any design',
    'Client has allowed the project to timeout': 'Client has allowed the project to timeout',
    'Client has not contacted support at least once during the design stage to try to see if they can help with their particular problem': 'Client has not contacted support at least once during the design stage to try to see if they can help with their particular problem',
    'Client has not given any feedback to any designers': 'Client has not given any feedback to any designers',
    'Client mentions to a designer they will work with designer / pick designer as a finalist / select designer as a winner': 'Client mentions to a designer they will work with designer / pick designer as a finalist / select designer as a winner',
    'Client selects a finalist': 'Client selects a finalist',
    'DesignBro cannot issue a refund on any NDA agreements': 'DesignBro cannot issue a refund on any NDA agreements.',
    'DesignBro cannot issue refunds on any extra time purchased': 'DesignBro cannot issue refunds on any extra time purchased.',
    'DesignBro charges a one-time administration fee of $20 to process the refund (this will be deducted from the receivable)': 'DesignBro charges a one-time administration fee of $20 to process the refund (this will be deducted from the receivable).',
    'First of all we would like to note that customer satisfaction is a very high priority at DesignBro': 'First of all we would like to note that customer satisfaction is a very high priority at DesignBro. We value our customers, and want to deliver you the design you’ll love. Contact us prior to considering a refund, as we can help in almost all cases',
    'If there was a disregard for the briefing by the designers (this is at DesignBro’s discretion)': 'If there was a disregard for the briefing by the designers (this is at DesignBro’s discretion)',
    'If there were less designs submitted (including eliminated) during the design stage than paid for': 'If there were less designs submitted (including eliminated) during the design stage than paid for',
    'Refund policy': 'Refund policy',
    'The Client can be given a refund in these cases': 'The Client can be given a refund in these cases:',
    'The Client forfeits any refund if': 'The Client forfeits any refund if:',
    'The Client launched the same project simultaneously elsewhere': 'The Client launched the same project simultaneously elsewhere',
    'The reason is that Client no longer has a need for the project': 'The reason is that Client no longer has a need for the project',
    'When Client opts for a money back guarantee - Client will not be able to use (in any way shape or form) any of the designs presented': 'When Client opts for a money back guarantee - Client will not be able to use (in any way shape or form) any of the designs presented.',
    'When the client is unsatisfied with the works delivered and requests the refund to the DesignBro team no later than 5 days into the design stage': 'When the client is unsatisfied with the works delivered and requests the refund to the DesignBro team no later than 5 days into the design stage'
  },

  logIn: {
    'Allow DesignBro to send you emails relating to the functioning of the platform as well as marketing': 'Sta toe dat DesignBro je e-mails stuurt over het functioneren van het platform en marketing uitingen',
    'Already have an account?': 'Heb je al een account?',
    'By joining you agree with our Terms and Privacy Policy': 'Door je aan te melden, ga je akkoord met onze Voorwaarden en Privacybeleid',
    'Don’t have an account?': 'Heb je geen account?',
    'Join as a Designer': 'Meld je aan als Ontwerper',
    'Join': 'Aanmelden',
    'Log in now to save your work!': 'Log nu in om je werk op te slaan!',
    'Login': 'Login',
    passwordResetLinkRequest: 'Wachtwoord vergeten?'
    , 'or': 'of',
    'Register as a designer': 'Registeer je als ontwerper',
    'Select category first': 'Selecteer eerst een categorie',
    'Sign up now to save your work!': 'Registreer je nu om je werk op te slaan!',
    'Start a project as a client': 'Start een project als klant',
    'Want to start a design project as a client?': 'Wil je een design project starten als klant?'
  },

  startNew: 'Start een nieuw {{item}}'

  , completed: 'Afgerond'
  , inProgress: 'Lopend'
  , projectsText: 'Projecten'

  , brands: {
    remove: 'Verwijder merk',
    'Briefing details needed': 'Briefing details nodig',
    'completed': '{{number}} afgerond',
    'files': '{{number}} bestanden',
    'In progress': 'Lopende projecten',
    'in progress': '{{number}} lopende projecten',
    'My Brands': 'Mijn Merken',
    'Payment needed': 'Betaling vereist',
    'Search brand': 'Zoek merk...',
    'switch brand': 'wissel merk',
    project: 'project',
    projectOrBrand: 'project of merk'
  }

  , elapsedTime: {
    hour_one: '{{count}} uur'
    , hour_other: '{{count}} uur'

    , day_one: '{{count}} dag'
    , day_other: '{{count}} dagen'
  }

  , project: {
    finalist: 'finalist'
    , winner: 'winnaar'
  }

  , client: {
    project: {
      subscriptionPlan: {
        label: 'Abonnement'
        , activeProjectsLabel: 'Actieve projecten'
        , startProject: 'Start nieuw project'
        , startProjectDescription: 'Rond een project af voordat je een nieuwe start'
      }
      , new: {
        title: 'Selecteer het merk voor je volgende project'
        , new: {
          new: 'Nieuw merk'
          , form: {
            title: 'Welke merknaam wil je in je logo hebben:'
            , label: 'Merk naam'
          }
        }
        , projectType: {
          title: 'Met wie wil je werken?'
          , options: [
            [
              'Bestaande ontwerper'
              , 'Werk met een van je eerere winnende ontwerpers'
            ]
            , [
              'Contest'
              , 'Start een contest met meerdere ontwerpers'
            ]
          ]
        }
        , 'individual.title': 'Kies de ontwerper waarmee je wil werken'
        , designType: {
          switcher: '\u25C2\u00A0 <button>kies een ander merk</button> \u00A0 of maak een \u00A0 <button>nieuw merk</button>'
          , title: 'Selecteer project type voor<br><span>{{brand}}</span><switcher/>'
          , custom: {
            title: 'Zoek je iets anders of wil je je bestaande ontwerp veranderen?'
            , contact: 'Laat het ons weten'
            , quote: {
              title: '<span>Laat ons weten</span> wat je <br>in gedachten hebt'
              , placeholder: "bv. heb een verandering nodig in het ontwerp van een visitekaartje voor een van mijn nieuwe collega's"
              , get: 'Vraag een offerte aan'
              , note: 'DesignBro antwoordt gewoonlijk binnen één werkdag'
              , confirmation: {
                title: 'Geweldig!'
                , description: 'We nemen binnenkort <br>contact met je op'
              }
            }
          }
          , other: {
            title: 'Ander'
            , description: '{{customTitle}} {{customContact}}.'
          }
          , 'payment.discount': 'Kortingscode'
        }
      }
      , poll: {
        poll: 'enquete'
        , modal: {
          agreement: {
            title: 'Je hebt een NDA op dit project'
            , description: 'Als je een enquete voor dit project start, sta je toe dat sommige informatie over het project publiekelijk getoond wordt en online zichtbaar is.'
            , action: 'Accepteer, ga door'
          }
          , startNew: {
            title: 'Waarom niet een enquete beginnen?!'
            , description: "Benieuwd wat je vrienden, klanten of collega's ervan vinden?! Start gewoon een enquete, deel hem als link, of plaats hem op je socials, en kom erachter! Kies de ontwerpen en ga hiermee aan de slag."
            , action: 'Start jouw enquete'
          }
          , declineAction: 'Nee, ik wil geen enquete beginnen'
        }
        , new: {
          yourPoll: 'je enquete'
          , headline: 'Selecteer de ontwerpen die je wil toevoegen aan {{yourPoll}}'
          , modal: {
            share: 'Deel {{subject}}'
            , link: 'de link'
            , copy: {
              action: 'Kopieer'
              , completed: 'Gekopieerd!'
            }
            , on: 'op {{location}}'
            , socialMedia: 'social media'
            , message: 'Hallo allemaal! Ik hoop dat jullie me een handje kunnen helpen. Ik ben onlangs een ontwerp-project begonnen op DesignBro en heb jullie nodig om te stemmen welk ontwerp jullie het beste vinden:'
            , action: 'Bekijk resultaten'
          }
        }
        , detail: {
          started: 'Gestart: <date>{{content}}</date>'
          , design: '<icon/><count>{{content}}</count> ontwerpen'
          , link: '<icon/><count>{{content}}</count> clicks'
          , vote: '<icon/><count>{{content}}</count> stemmen'
          , comment: '<icon/><count>{{content}}</count> opmerkingen'
        }
        , view: 'Bekijk enquete'
        , page: {
          toProject: 'Terug'
          , new: 'Start nieuwe enquete'
          , end: 'Stop enquete'
          , share: '<icon/> Delen'
          , download: 'Download resultaten'
          , design: {
            by: '<fragment>door</fragment> <span>{{name}}</span>'
            , clicks: 'Van {{count}} clicks'
            , comments: {
              view: 'Bekijk opmerkingen ({{count}})'
              , modal: {
                title: 'Opmerkingen'
                , design: '#{{place}} <span><by/></span>'
                , anonymous: 'Anoniem'
                , close: '<Left/> Terug'
              }
            }
          }
        }
      }
      , modal: {
        title: 'Bevestiging'
        , eliminateDesign: {
          description: 'Elimineer je dit ontwerp? Geef de reden van je eliminatie op, zodat we deze feedback aan de ontwerper kunnen geven en klik op bevestigen. Het ontwerp wordt ook voorgoed van je project verwijderd, en de plek wordt weer vrijgegeven voor onze groep van ontwerpers.'
          , options: {
            not_in_line: 'Het ontwerp is niet in overeenstemming met de briefing / mijn behoeften'
            , not_communicate: 'De ontwerper communiceerde niet terug naar mij'
            , not_understand: 'De ontwerper begreep mijn feedback niet'
            , looking_for_something_else: 'Gewoon op zoek naar iets anders'
            , other: 'Anders'
          }
        }
        , blockDesigner: {
          description: 'Als je {{designer}} blokkeert zul je hem/haar nooit meer zien, ook niet in toekomstige projecten. Laat ons weten waarom je de ontwerper wilt blokkeren en klik op bevestigen. Het ontwerp wordt ook voorgoed uit je project verwijderd, en de plek wordt weer vrijgegeven voor onze pool van ontwerpers.'
          , options: {
              disappointing: "Het werk van de ontwerper was teleurstellend"
              , not_communicate: 'De ontwerper communiceerde niet goed'
              , was_rude: 'De ontwerper was onbeleefd'
              , plagiarism: 'Ik verdenk de ontwerper van plagiaat'
              , other: 'Anders'
          }
          , decline: 'Nee'
          , confirm: 'Ja'
        }
        , continue: 'Bevestig en ga door'
        , filesApproval: {
          termsAndConditions: 'Terms & Conditions'
          , description: 'Ik keur de bestanden goed en stem in met de DesignBro <termsAndConditions/>'
        }
        , leaveReview: {
          designer: 'Hoe goed was je ontwerper?'
          , brand: 'Hoe was je algehele ervaring?'
          , label: 'Beschrijf je ervaring om te werken met {{name}}'
          , required: 'Verplicht.'
        }
      }
      , files: {
        review: {
          success: {
            offer: {
              title: [
                'Bedankt voor je review!',
                'We willen je graag om hulp vragen:'
              ],
              description: `
                Kan je ook jouw review op de volgende plekken plaatsen?
                <br/>In ruil daarvoor bieden we je graag een korting aan van
                <br/> <strong>{{discountAmount}}$ op je volgende project!</strong>
              `,
              copy: 'Kopieer quote'
              , detail: '{{durationAmount}} dagen geldig',
              decline: 'Nee dank, ga door',

              reward: {
                title: 'Dank je!',
                subTitle: 'Hier is je <strong>{{discountAmount}}$ kortingscode:</strong>'
                , copy: 'Kopieer'
                , description: [
                  'Kopieer en bewaar dit op een veilige plek!',
                  'Deze kortingscode is de komende {{durationAmount}} dagen geldig.'
                ],
                confirm: 'Ga verder'
              }
            },
            share: {
              title: 'Gefeliciteerd met je nieuwe ontwerp!'
              , message: 'Hoi! Check mijn gloednieuwe ontwerp, gemaakt op DesignBro ({{link}})!'
              , description: 'Deel dit nieuwe ontwerp met je vrienden!'
            }
          }
        }
      }
    }
  }

  , projectFeedback: {
    page: {
      title: 'Dank je voor je waardevolle feedback!',
      description: 'We hopen je in de toekomst weer bij DesignBro te zien!'
    },
    other: {
      title: 'We willen heel graag weten waarom je niet voor DesignBro hebt gekozen',
      description: 'Je waardevolle feedback wordt zeer gewaardeerd!',
      form: {
        label: 'Typ hier',
        submit: 'Verstuur'
      }
    }
  },

  projects: {
    'Latest activity': 'Laatste activiteit',
    'My Projects': 'Mijn Projecten',
    'Discover Projects': 'Ontdek Projecten',
    'Your portfolio has not yet been approved': 'Je portfolio is nog niet goedgekeurd',
    'Expired spots': 'Verlopen spots',
    'In progress': 'Lopend',
    'Finalists': 'Finalist',
    'Winner': 'Winnaar',
    'Add spot': 'Spot toevoegen',
    'Finalize': 'Afronden',
    'Delete draft': 'Verwijder concept',
    'See Project': 'Bekijk Project',
    'Complete briefing': 'Briefing afronden',
    'Please check the uploaded source files here': 'Controleer alle bestanden hier',
    'Review files': 'Controleer bestanden',
    'Select this Designer as finalist': 'Selecteer deze Ontwerper als finalist?',
    'Select this Designer as winner' : 'Selecteer deze Ontwerper als winnaar?',
    'Do you want to approve this design': 'Wil je dit ontwerp goedkeuren?',
    'Approve': 'Goedkeuren',
    'Approve design': 'Ontwerp goedkeuren',
    'View': 'Bekijken',
    'Comment': 'Commentaar',
    'Select Finalist left': 'Selecteer Finalist ({{count}} over)',
    'Eliminate Work': 'Verwijder ontwerp',
    'Block Designer': 'Blokkeer Ontwerper',
    'No files yet': 'Nog geen bestanden',
    'files uploaded': 'bestanden opgeleverd',
    'Download Zip': 'Download Zip',
    'Approve Files': 'Keur goed',
    'Review Designer': 'Beoordeel Designer',
    'How many days would you like to add': 'Hoeveel dagen wil je toevoegen?',
    'How many spots would you like to add': 'Hoeveel spots wil je toevoegen?',
    'Total cost': 'Totale kosten',
    'Eliminate design': 'Verwijder ontwerp',
    'Select as winner': 'Selecteer als winner',
    'Approve design & request files': 'Keur ontwerp goed en vraag om de bestanden',
    'Select as finalist': 'Selecteer als finalist',
    'Previous designs': 'Vorige ontwerpen',
    'Please ask your designer in the chat to restore a previous design': 'Vraag je ontwerper in de chat om een eerder ontwerp terug te zetten',
    'for': 'voor',
    'Project by': 'Project door',
    'Start type': 'Start typen',
    'Send': 'Verstuur',
    'Tell us more': 'Vertel ons meer',
    'Drag & Drop files here': 'Sleep hier bestanden naartoe',
    'Download': 'Download',
    'eliminateWorkTooltip': 'Wanneer je dit ontwerp verwijdert, zul je dit ontwerp nooit meer zien, maar het zal deze plek weer openen voor onze pool van ontwerpers. Dit betekent dat als er genoeg tijd en interesse in je project is, een nieuwe ontwerper deze plek kan kiezen met een nieuw ontwerp',
    'blockDesignerTooltip': "Houd je echt niet van het werk van een ontwerper, of waren ze onbeleefd? Wanneer je een ontwerper blokkeert, elimineert dit niet alleen hun werk, het voorkomt ook dat deze ontwerper ooit nog met jou samenwerkt, zelfs in toekomstige projecten.",
    'selectFinalistTooltip': 'Voordat de ontwerpfase voorbij is, moet je minimaal 1 finalist en maximaal 3 finalisten kiezen. Deze finalisten krijgen nog {{finalistStageExpireDays}} dagen extra om aan je project te werken in de finalistenfase '
  },

  projectSuccess: {
    referralModal: {
      title: 'Hoe heb je ons gevonden?',
      options: {
        referral: 'Via-via (persoonlijke aanbeveling)',
        advertisement: 'Advertentie',
        onlineSearch: 'Google (zoekmachine)',
        fromAWebsite: 'Over jullie gelezen op een website',
        readReview: 'Via een review',
        socialMedia: 'Social media',
        YouTube: 'YouTube',
        workedWith: 'Ik heb al eerder met jullie gewerkt',
        other: 'Anders'
      },
      label: 'Laat ons weten hoe'
    }
  },

  designer: {
    settings: {
      general: {
        username: 'Gebruikersnaam'
        , dateOfBirthMonth: 'Geboortemaand'
        , dateOfBirthDay: 'Geboortedag'
        , dateOfBirthYear: 'Geboortejaar'
        , gender: 'Geslacht'
        , address: {
          title: 'Adresgegevens:'
          , address1: 'Adres 1'
          , address2: 'Adres 2 (opt.)'
          , stateName: 'Provincie (opt.)'
          , zip: 'Postcode (opt.)'
          , phone: 'Telefoonnummer'
        }
      }
    },
    '1-3 years': '1-3 jaar',
    '4-7 years': '4-7 jaar',
    'Acceptable': 'Acceptabel',
    'Brand Identity': 'Huisstijl',
    'Country': 'Land',
    'Dashboard': 'Dashboard',
    'Female': 'Vrouw',
    'First Name': 'Voornaam',
    'Go Back': 'Ga Terug',
    'Good': 'Goed',
    'Great, thank you!': 'Dank je!',
    'If you have any issues or questions, drop us a line.': 'Als je vragen hebt, stuur ons dan een bericht.',
    'Last Name': 'Achternaam',
    'Let us take a look at your work and we’ll get back to you as soon as we can. This can take some time; we get a lot of good portfolios. Please bear with us in the meantime!': 'Laat ons naar je portfolio kijken en we komen bij je terug. Dit kan tijd kosten, we krijgen veel goede aanmeldingen! Heb a.j.b. geduld!',
    'Let’s get things going: just fill in your profile.': 'Laten we starten: vul je profiel in.',
    'Male': 'Man',
    'Native': 'Moedertaal',
    'Next Step': 'Volgende stap',
    'No experience': 'Geen ervaring',
    'Not good': 'Niet goed',
    'Online Media (website, banners and others)': 'Online Media (website, banners en andere)',
    'Online portfolio': 'Online portfolio',
    'over 8 years': 'meer dan 8 jaar',
    'Please confirm that the work you have uploaded is your work and that there is nothing prohibiting you from sharing it.': 'Bevestig dat het werk dat je upload van jou is en dat erg niets is dat jou belemmert om het te delen.',
    'Please set your experience': 'Geef je ervaring aan',
    'Prefer not to say': 'Zeg ik liever niet',
    'Print Media': 'Print Media',
    'Set your experience': 'Geef je ervaring aan',
    'Social': 'Social',
    'Tell us about the project': 'Vertel ons over het project',
    'Upload image (JPG or PNG, max file size 2MB)': 'Upload afbeelding (JPG of PNG, max grootte 2MB)',
    'Welcome to DesignBro! It’s great to see you here!': 'Welkom bij DesignBro! Fijn dat je er bent!!',
    'Written English level': 'Geschreven Engels niveau',
    'You can Drag & Drop your file from computer': 'Je kan bestanden slepen vanaf je computer',
    'You mentioned you have "no experience". That means that you will not be able to use our platform, as we currently only offer our clients experienced designers. You can come back if you made a mistake or once you have a bit more experience.': 'Je geeft aan "geen ervaring" te hebben. Dat betekent dat je ons platform niet kunnen gebruiken omdat we alleen ontwerpers met ervaring laten werken met onze klanten. Pas dit aan als je het verkeerd aangaf, of kom terug als je meer ervaring hebt.',
    'You will be able to enter competitions as soon as it gets approved!': 'Je kan je inschrijven op projecten zodra het is goedgekeurd!',
    'Your portfolio will be vetted by our team and you will receive an answer by email as soon as we can. This can take some time; we get a lot of good portfolios. Please bear with us in the meantime!': 'Je portfolio wordt bekeken door ons team en je krijg via e-mail bericht zo snel als we kunnen. Dit kan even duren: we krijgen veel goede profielen. Heb a.j.b. geduld!'
  }
  , layout: {
    'All Rights reserved': 'Alle Rechten voorbehouden'
    , startProject: [
      'selecteer-design-project', 'Start een Project'
    ]
    , 'Blog': 'Blog'
    , projectTypeLinks: [
      ['logo-ontwerpen', 'Logo']
      , ['huisstijl-ontwerpen', 'Huisstijl']
      , ['packaging-design', 'Packaging']
    ]
    , 'Contact Us': 'Contact',
    'Help': 'Help',
    'Privacy Policy': 'Privacybeleid',
    'Terms': 'Voorwaarden',
    'Home': 'Home',
    'item.Drafts': 'Drafts'
    , 'Polls': 'Enquete',
    'Files': 'Bestanden',
    inProgress: 'Lopende {{projectsText}}'
    , projects: '{{type}} {{projectsText}}'
    , Drafts: 'Draft'
    , completed: '{{completed}}e'
    , 'New Project': 'Nieuw Project',
    'Prize': 'Beloning',
    Spots: 'Spots'
    , 'Design': 'Design',
    'Finalist': 'Finalist',
    'Not started': 'Niet gestart',
    'Buy time': 'Koop meer tijd',
    'left to choose your finalists': 'over om je finalisten te kiezen',
    'left in the Design Stage': 'over in de Design Fase',
    'left to choose your winner': 'over om je winnaar te kiezen',
    'left to approve the design': 'over om het ontwerp goed te keuren',
    'for the designer to upload files': 'over voor de ontwerper om bestanden te uploaden',
    'left to approve the files': 'over om de bestanden goed te keuren',
    'left in the Finalist Stage': 'Over in de Finalist Fase',
    'left for the client approval': 'over voor klant goedkeuring',
    'left to upload your files': 'over om je bestanden te uploaden',
    'Brief': 'Briefing',
    'Designs': 'Ontwerpen',
    'Vacant': 'Vacant',
    'Waiting for design': 'Wacht op ontwerp',
    'Confirm': 'Bevestig',
    'Confirmation': 'Bevestiging',
    'Cancel': 'Annuleer',
    'waiting_for_design_tooltip_contest': 'Super goed! de ontwerper {{designerName}} heeft een plek gereserveerd om deel te nemen aan jouw project. Dat betekent dat hij nu aan een ontwerp is gaan werken. De ontwerper moet zijn ontwerp binnen {{reservationExpireHours}} uur uploaden, anders verliezen ze automatisch hun plek. \n {{designerName}} heeft nog {{hoursToExpire}} uur om te uploaden',
    'waiting_for_design_tooltip_1_to_1': 'Super goed! {{designerName}} is op de hoogte gesteld en gaat aan uw project werken.',
    'design_stage_tooltip_contest': 'Er zijn drie fasen in je {{projectName}} project: \n Ontwerpfase: Hier uploaden alle ontwerpers al hun ontwerpen en als er genoeg tijd is, kunnen ze ook al wat wijzigingen aanbrengen! \n De ontwerpfase duurt in totaal {{designStageExpireDuration}} dagen. \n Finalistenfase: Je kunt je favoriete 3 ontwerpen kiezen als finalisten, daarna heb je nog {{finalistStageExpireDays}} dagen om met de finalisten te werken \n winnaarfase: zodra je je 1 winnaar hebt geselecteerd, zal de winnende ontwerper je bestanden uploaden binnen {{filesStageExpireDays}} dagen \n Bestandsstadium: Zodra jouw ontwerper bestanden heeft geüpload, heb je {{reviewFilesStageExpireDays}} dagen om de bestanden goed te keuren, of om met de ontwerper te communiceren om wijzigingen in bestanden en formaten aan te vragen.',
    'design_stage_tooltip_1_to_1': 'Er zijn twee fases in je {{projectName}} project: \n Ontwerpfase: de ontwerper uploadt het ontwerp, jij kunt feedback geven zodat de ontwerper veranderingen voor je kan aanbrengen! \n De ontwerpfase duurt in totaal {{designStageExpireDuration}} dagen. \n Bestandsstadium: Zodra jouw ontwerper bestanden heeft geüpload, heb je {{reviewFilesStageExpireDays}} dagen om de bestanden goed te keuren, of om met de ontwerper te communiceren om wijzigingen in bestanden en formaten aan te vragen.',
    'days_left_tooltip': 'Het naar beneden afgeronde aantal dagen dat nog over is in jouw project. \n Het exacte aantal voor jouw project is: {{days}} en {{hours}}.',
    'designs_info_tooltip': 'Het eerste cijfer geeft aan hoeveel ontwerpen je hebt ontvangen (inclusief eliminaties) \n Het tweede cijfer geeft het aantal ontwerpen aan dat we garanderen voor het pakket dat je hebt geselecteerd en betaald.'
  },
  'stages_tooltip': {
    you_currently_have: 'Je hebt nu <strong> {{days}} en {{hours}} </strong> over in de ',
    design_stage: '<strong>Design Fase</strong>',
    finalist_stage: '<strong>Finalist Fase</strong>',
    files_stage: '<strong>Files Fase</strong>',
    days: '{{days}} dagen',
    design_stage_content: 'Je ontvangt ontwerpen en kan feedback geven. Ontwerpers hebben dan de kans om aangepaste versies te sturen. Zorg dat je (max. 3) finalisten kiest voor het einde van deze fase',
    finalist_stage_content: 'Finalisten hebben de kans om hun ontwerp nog verder aan te passen. Blijf ook in deze fase feedback geven. Voor het eind van deze fase moet je de winner kiezen',
    files_stage_content: 'De winnende ontwerper krijgt {{files}} dagen om alle afgeronde bestanden te uploaden. Zodra de bestanden zijn geupload, heb je {{review}} dagen om deze te bekijken om zeker te zijn dat alles klopt.'
  },
  settings: {
    notifications: {
      title: 'Email notificaties'
      , projects: 'Updates van mijn projecten'
      , messages: 'Nieuwe berichten'
      , brand: 'Nieuws van DesignBro'
    },
    'General': 'Algemeen',
    'Payment': 'Betaling',
    'Notifications': 'Notificaties',
    'Email': 'Email',
    'Country / Language': 'Land / Taal',
    'Contact Information': 'Contact Informatie',
    'Will be used as default billing address': 'Wordt gebruikt als standaard factuur adres',
    'First Name': 'Voornaam',
    'Last Name': 'Achternaam',
    'Address 2 (opt)': 'Adres 2 (opt.)',
    'City': 'Stad',
    'Country': 'Land',
    'State (opt)': 'Provincie (opt.)',
    'Zip (opt)': 'Postcode (opt.)',
    'Phone number (opt)': 'Telefoonnummer (opt.)',
    'VAT number (opt)': 'BTW nummer (opt.)',
    'Excellent, your settings were successfully updated': 'Perfect, je aangepaste instellingen zijn opgeslagen!',
    'Ok': 'Ok'
    , save: 'Sla op'
    , modal: {
      title: 'Instellingen'
      , message: 'Uitstekend, je instellingen werden met succes bijgewerkt!'
    }
  },

  deliverables: {
    Deliverables: 'Deliverables',
    'please note': 'Let op: ',
    'read more': 'Lees meer',
    logo: {
      heading_1: 'Logo',
      description: 'Afhankelijk van het soort logo bevatten de uiteindelijke bestanden ook logovarianten, zoals (bv.) een losstaand pictogram, een liggend vs staand logo, enz.',
      note: 'Slechts 1 logo ontwerp is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
    },
    'brand-identity': {
      heading_1: 'Logo',
      heading_2: 'Briefhoofd, Visitekaartje, Enveloppe, Complimentbriefje',
      description: 'Afhankelijk van het soort logo bevatten de uiteindelijke bestanden ook logovarianten, zoals (bv.) een losstaand pictogram, een liggend vs staand logo enz. \n \n De definitieve bestanden voor de briefpapier artikelen bevatten ook een drukbestand: \n .pdf met 0.125 inch of 3 mm afloop.',
      note: 'Slechts 1 logo ontwerp is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.'
    },
    'business-card': {
      heading_1: 'Visitekaartje',
      description: 'De uiteindelijke bestanden voor het visitekaartje bevatten ook een drukbestand: \n .pdf met 0.125 inch of 3 mm afloop.',
      note: 'Slechts 1 ontwerp (voor voor- en achterkant) is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.'
    },
    'website': {
      heading_1: 'Website ontwerp voor ({{screenCount}} pagina’s)',
      description: "De uiteindelijke bestanden voor website ontwerp bevatten ook de bestanden voor de assets (beelden, iconen enz.) die in het ontwerp gebruikt worden (.jpg, .png of .svg). \n \n Ter indicatie: om te voorkomen dat een pagina een 'oneindige lengte' heeft, wordt verwacht dat een pagina ongeveer 3 secties heeft (bijvoorbeeld een 'header' sectie, 'over ons' sectie en 'contacteer ons' sectie). Dit wordt benaderd volgens een fair use methode. \n \n Dit Website Ontwerp project omvat alleen een ontwerp - geen werkende website. De klant ontvangt alle bestanden en middelen voor de website die elke web ontwikkelaar nodig zou hebben.",
      note: "Alleen het aantal pagina's dat in de briefing gegeven wordt, is inbegrepen. Als meer pagina's nodig zijn, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.",
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, .xd, .fig of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'website-additional-screens': {
      heading_1: 'Website ontwerp voor ({{screenCount}} pagina’s)',
      description: "De uiteindelijke bestanden voor website ontwerp bevatten ook de bestanden voor de assets (beelden, iconen enz.) die in het ontwerp gebruikt worden (.jpg, .png of .svg). \n \n Ter indicatie: om te voorkomen dat een pagina een 'oneindige lengte' heeft, wordt verwacht dat een pagina ongeveer 3 secties heeft (bijvoorbeeld een 'header' sectie, 'over ons' sectie en 'contacteer ons' sectie). Dit wordt benaderd volgens een fair use methode. \n \n Dit Website Ontwerp project omvat alleen een ontwerp - geen werkende website. De klant ontvangt alle bestanden en middelen voor de website die elke web ontwikkelaar nodig zou hebben.",
      note: "Alleen het aantal pagina's dat in de briefing gegeven wordt, is inbegrepen. Als meer pagina's nodig zijn, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.",
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, .xd, .fig of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'website-banner': {
      heading_1: 'Website Banner (5 formaten)',
      description: 'Maximaal 5 bannerformaten, gebaseerd op hetzelfde ontwerp, zijn in het project inbegrepen. \n De klant kan zelf bepalen welke 5 maten. Standaard afmetingen van website banners zijn: 300 x 250 px, 336 x 280 px, 728 x 90 px, 300 x 600 px en 320 x 50 px.',
      note: 'Er zijn slechts 5 verschillende maten inbegrepen. Als een ander ontwerp of meer maten gewenst zijn, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, .xd, .fig of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    facebook: {
      heading_1: 'Profielafbeelding',
      heading_2: 'Achtergrond',
      note: 'Slechts 1 ontwerp (voor profielafbeelding en achtergrond) is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'instagram-post': {
      heading_1: 'Instagram post afbeelding',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    linkedin: {
      heading_1: 'Profielafbeelding',
      heading_2: 'Achtergrond',
      note: 'Slechts 1 ontwerp (voor profielafbeelding en achtergrond) is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    twitter: {
      heading_1: 'Profielafbeelding',
      heading_2: 'Achtergrond',
      note: 'Slechts 1 ontwerp (voor profielafbeelding en achtergrond) is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    youtube: {
      heading_1: 'Profielafbeelding',
      heading_2: 'Achtergrond',
      note: 'Slechts 1 ontwerp (voor profielafbeelding en achtergrond) is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'zoom-background': {
      heading_1: 'Zoom achtergrond afbeelding',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    poster: {
      heading_1: 'Poster',
      description: 'De uiteindelijke bestanden voor de poster bevatten ook een afdrukbestand: \n .pdf met 0.125 inch of 3 mm afloop.',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    flyer: {
      heading_1: 'Flyer',
      description: 'De uiteindelijke bestanden voor de flyer bevatten ook een drukbestand: \n .pdf met 0.125 inch of 3 mm afloop.',
      note: 'Slechts 1 ontwerp (voor voor- en achterkant) is inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'album-cover': {
      heading_1: 'Albumhoes',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'book-cover': {
      heading_1: 'Boekomslag',
      description: 'De uiteindelijke bestanden voor de boekomslag bevatten ook een drukbestand: \n .pdf met 0.125 inch of 3 mm afloop.',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'magazine-cover': {
      heading_1: 'Magazine cover',
      description: 'De uiteindelijke bestanden voor de tijdschriftomslag bevatten ook een drukbestand: \n .pdf met 0.125 inch of 3 mm afloop.',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, .indd of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    packaging: {
      heading_1: 'Packaging design',
      description: 'De uiteindelijke bestanden voor het verpakkingsontwerp omvatten een drukbestand (.pdf) dat gebaseerd moet zijn op de door de klant verstrekte technische tekening of maten.',
      note: 'Slechts 1 ontwerp voor {{variantsCount}} SKU (smaak of variant) is inbegrepen in het project. Als een andere SKU wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'packaging-additional-variant': {
      heading_1: 'Packaging design',
      description: 'De uiteindelijke bestanden voor het verpakkingsontwerp omvatten een drukbestand (.pdf) dat gebaseerd moet zijn op de door de klant verstrekte technische tekening of maten.',
      note: 'Slechts 1 ontwerp voor 1 SKU (smaak of variant) is inbegrepen in het project. Als een andere SKU wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt'
    },
    'brand-guidelines': {
      heading_1: 'Brand guidelines document',
      description: 'Het project voor merkrichtlijnen omvat een document, gebaseerd op een door DesignBro geleverd template.',
      note: 'Slechts 1 document is inbegrepen in het project. Als een ander document wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.'
    },
    'animated-logo': {
      heading_1: 'Logo animatie',
      note: 'Slechts 1 animatie is inbegrepen in het project. Als een andere animatie gewenst is, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn..',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .psd, .aep, .c4d, .f4v,.flv, .procreate, afhankelijk van de software die de ontwerper gebruikt'
    },
    menu: {
      heading_1: 'Menu',
      description: 'De uiteindelijke bestanden voor het menu bevatten ook een drukbestand: \n .pdf met 0.125 inch of 3 mm afloop',
      note: 'Slechts 1 document (tweezijdig, omslag en menu pagina) is inbegrepen in het project. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .indd, of .ai, afhankelijk van de software die de ontwerper gebruikt.'
    },
    billboard: {
      heading_1: 'Billboard',
      description: 'De uiteindelijke bestanden voor het billboard bevatten ook een drukbestand: \n .pdf met 0.125 inch of 3 mm afloop',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt.'
    },
    't-shirt': {
      heading_1: 'T-shirt',
      note: 'Er is slechts 1 ontwerp inbegrepen. Als een ander ontwerp wordt gevraagd, neem dan contact op met het DesignBro team, want er kunnen extra kosten aan verbonden zijn.',
      'source_tooltip': 'Het bewerkbare bronbestand kan zijn: .ai, of .psd, afhankelijk van de software die de ontwerper gebruikt.'
    }
  }
}
