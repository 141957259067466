import _ from 'lodash'
import Dinero from 'dinero.js'
import { connect } from 'react-redux'

import { ADDITIONAL_SPOTS_SURCHARGE } from '@application/index/asset/constants'
import { hideModal } from '@application/index/asset/store/actions/modal'
import { buySpotsUpsell } from '@application/index/asset/store/actions/projectBuilder'

import { getVatRates, isNotIreland } from '@application/index/asset/store/selectors/vat'
import { currentDiscountSelector } from '@application/index/asset/store/selectors/discount'
import { getMe } from '@application/index/asset/store/selectors/me'
import { getCurrentProject } from '@application/index/asset/store/selectors/projects'
import { getProjectBuilderAttributes, getProjectBuilderUpsellAttributes } from '@application/index/asset/store/selectors/projectBuilder'
import { discountInAmount, currentProjectAdditionalDesignPricesSelector } from '@application/index/asset/store/selectors/prices'

import PaymentModal from '@application/index/component/projectBuilder/New/PaymentModal/PaymentModal'

const getVatRateByCountry = (vatRates, countryCode) => {
  return _.find(vatRates, (v) => v.countryCode === countryCode)
}

const getVat = (price, vatRate, vatNumber) => {
  const shouldHaveVat = vatRate && !(isNotIreland(vatRate.countryCode) && vatNumber)
  const vatMultiplier = shouldHaveVat ? vatRate.percent / 100 : 0

  return price.multiply(vatMultiplier)
}

const displayDiscountAmount = (discount) => {
  if (!discount) {
    return null
  }

  switch (discount.discountType) {
  case 'percent':
    return `${discount.value}%`
  case 'amount':
    return Dinero({ amount: discount.amountCents, currency: discount.amountCurrency }).toFormat('$0,0.00')
  default:
    return null
  }
}

const mapStateToProps = (state) => {
  const me = getMe(state)
  const { preferredPaymentMethod, countryCode, vat: vatNumber, creditCardNumber, creditCardProvider } = me

  const attributes = getProjectBuilderAttributes(state)

  const project = getCurrentProject(state)

  const { numberOfSpots } = getProjectBuilderUpsellAttributes(state)
  const additionalDesignPrices = currentProjectAdditionalDesignPricesSelector(state)

  const currentAdditionalDesignPriceCents = _.get(additionalDesignPrices.find((p) => p.quantity === project.maxSpotsCount), 'amountCents', 0)
  const newAdditionalDesignPriceCents = _.get(additionalDesignPrices.find((p) => p.quantity === project.maxSpotsCount + numberOfSpots), 'amountCents', 0)
  const totalCostPriceCents = (newAdditionalDesignPriceCents - currentAdditionalDesignPriceCents) * (1 + ADDITIONAL_SPOTS_SURCHARGE / 100)

  const price = Dinero({
    amount: Math.round(totalCostPriceCents),
    currency: project.currency
  })

  const discount = currentDiscountSelector(state)

  const discountAmount = displayDiscountAmount(discount)
  const isDiscountVisible = discount && discount.available

  const vatRates = getVatRates(state)

  const vatRate = getVatRateByCountry(vatRates, countryCode)
  const isVatVisible = countryCode && vatRate

  const priceWithDiscount = price.subtract(discountInAmount(price, discount))

  const vat = getVat(priceWithDiscount, vatRate, vatNumber)

  const total = priceWithDiscount.add(vat)

  return {
    paymentType: attributes.paymentType,
    basePriceName: 'Additional design spot(s)',
    basePriceValue: price.toFormat('$0,0.00'),
    isVatVisible,
    vatValue: vat.toFormat('$0,0.00'),
    totalValue: total.toFormat('$0,0.00'),
    paypalAmount: total.toRoundedUnit(2),
    hasCard: creditCardNumber && creditCardProvider,
    creditCardNumber,
    creditCardProvider,
    discountAmount: `- ${discountAmount}`,
    isDiscountVisible
  }
}

export default connect(mapStateToProps, {
  onSubmit: buySpotsUpsell,
  onClose: hideModal
})(PaymentModal)
