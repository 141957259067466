import React from 'react'

import {
  createNewAccount, underlined
} from '@application/index/component/modal/index/.module.scss'
import Copy from '@application/index/unit/user/client/project/Project/Polls/Poll/Share/Copy'

export default properties =>
	<Copy
		className={createNewAccount + ' ' + underlined}
		{...properties}
	/>