import React from 'react'
import { useDispatch } from 'react-redux'
import { updateProjectsActiveMailer } from '@application/index/asset/store/actions/designer'
import HeaderError from '@application/index/component/index/HeaderError'

export default () => {
  const dispatch = useDispatch()

  const params = new URLSearchParams(document.location.search)
  const id = params.get('id')
  id && dispatch(updateProjectsActiveMailer(id));

  return (
    <>
      <HeaderError/>
      <main>
        <div className="error-page container text-center">
          <p className="error-page__text">
            You have successfully unsubscribed from the projects update email.
            <br />
            Please reach out to DesignBro support in case you want to subscribe again
          </p>
        </div>
      </main>
    </>
  )
}
