const audienceQuestions = [{
  name: 'brandDnaYouthfulOrMature',
  leftLabel: 'Youthful',
  rightLabel: 'Mature'
}, {
  name: 'brandDnaMasculineOrFeminine',
  leftLabel: 'Masculine',
  rightLabel: 'Feminine'
}, {
  name: 'brandDnaLowIncomeOrHighIncome',
  leftLabel: 'Low income',
  rightLabel: 'High income'
}]

export default audienceQuestions
