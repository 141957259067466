import React from 'react'
import { Link } from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import useRootDomainUrl from '@application/index/function/useRootDomainUrl'

import useTranslate from './useTranslate'
import tLogIn from '@application/index/views/Login/useTranslation'

import TermsModal from '@application/index/component/modal/Terms'
import PrivacyPolicyModal from '@application/index/component/modal/PrivacyPolicy'

const ListLink = ({className, ...properties}) =>
  <a
    className={'main-footer__list-link' + (
      className ?
        ' ' + className
      : ''
    )}
    {...properties}
  />

, Item = properties =>
  <li>
    <ListLink {...properties}/>
  </li>

export default ({userType}) => {
  const href = useRootDomainUrl()

  , t = useTranslate()

  , FooterLink = properties => {
    const Component = userType ? Link : 'a'

    return <Component
      {...(userType ? {to: '/'} : {href})}
      {...properties}
    />
  }

  return <footer className="main-footer bg-black">
    <div className="container">
      <div className="row">
        <div className="col-lg-2 col-xl-3">
          <FooterLink className="main-footer__brand">
            <img alt="logo gray"
              src="/projects/logo_grey.svg"
            />
          </FooterLink>
        </div>
        <div className="col-lg-2">
          <ul className="main-footer__list">
            <li>
              <FooterLink className="main-footer__list-link hidden-md-down">
                {t('Home')}
              </FooterLink>
            </li>
            {[
              t('startProject')
              , ['blog', t('Blog')]
            ].map(([key, title]) =>
              <Item {...{key}}
                href={href + '/' + key}
              >
                {title}
              </Item>
            )}
          </ul>
        </div>
        <div className="col-lg-3 col-xl-2 hidden-md-down">
          <ul className="main-footer__list">
            {useTranslation().t(
              'layout.projectTypeLinks'
            ).map(([key, title]) =>
              <Item {...{key}}
                className="main-footer__list-link--reverse"
                href={href + '/' + key}
              >
                {title}
              </Item>
            )}
          </ul>
        </div>
        <div className="col-lg-3 col-xl-2">
          <ul className="main-footer__list">
            {[
              [href + '/faq', t('Help')]
              , [
                '/login'
                , tLogIn('Login')
              ]
              , [
                '/d/signup'
                , tLogIn('Join as a Designer')
              ]
            ].map(([href, title]) =>
              <Item key={href} {...{href}}>
                {title}
              </Item>
            )}
          </ul>
        </div>
        <div className="col-lg-2">
          <ul className="main-footer__list">
            {[
              <TermsModal linkClasses="main-footer__list-link cursor-pointer"/>
              , <PrivacyPolicyModal linkClasses="main-footer__list-link cursor-pointer"/>
              , <ListLink
                href={href + '/contact-us'}
              >
                {t('Contact Us')}
              </ListLink>
            ].map((children, key) =>
              <li {...{key}}>
                {children}
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="main-footer__socials">
        <a target="_blank" className="main-footer__social icon-fb-circle" href="https://fb.me/designbrocom" />
        <a target="_blank" className="main-footer__social icon-instagram-circle" href="https://www.instagram.com/designbrodotcom" />
        <a target="_blank" className="main-footer__social icon-pinterest-circle" href="https://www.pinterest.com/designbrodotcom" />
        <a target="_blank" className="main-footer__social icon-twitter-circle" href="https://twitter.com/designbrodotcom" />
      </div>
      <p className="main-footer__rights">
        {t('copyright', {
          year: new Date().getFullYear()
        })}
      </p>
    </div>
  </footer>
}