import React from 'react'
import {connect} from 'react-redux'

import Intercom from 'react-intercom'

import {getMe, isAuthenticated} from '@application/index/asset/store/reducers/me'

const mapStateToProps = state => {
  const {
    userId, email, firstName, lastName, userHash, createdAt
  } =
    getMe(state)

  return {...(isAuthenticated(state) && {
    user_id: userId
    , email
    , name: firstName + ' ' + lastName
    , user_hash: userHash
    , created_at: new Date(createdAt).getTime() / 1000
  })}
}

, App = properties =>
  window.intercom_app_id &&
    <Intercom
      appID={window.intercom_app_id} {...properties}
    />

export default connect(mapStateToProps)(App)