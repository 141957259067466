import React, {Fragment} from 'react'

export default array =>
  array.reduce((result, text, index) => {
    result.push(<Fragment key={text}>{text}</Fragment>)

    if (1 + index < array.length)
      result.push(<br key={text + 'lineBreak'}/>)

    return result
  }, [])