import { connect } from 'react-redux'

import { hideModal } from '@application/index/asset/store/actions/modal'

import ClientVideoModal from './component'

const mapDispatchToProps = {
  onClose: hideModal
}

export default connect(null, mapDispatchToProps)(
  ClientVideoModal
)
