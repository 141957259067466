import React from 'react'
import {useTranslation} from 'react-i18next'
import Input from '@application/index/component/inputs/Input'

const BrandNameForm = ({ isNewBrand, brandName, onChange }) => {
  const {
    title, label
  } =
    useTranslation().t('client.project.new.new.form')

  if (isNewBrand) {
    return (
      <div className="brand-name-input">
        <p>{title}</p>

        <Input
          name="brandName"
          value={brandName}
          {...{label}}
          onChange={onChange}
        />
      </div>
    )
  }

  return null
}

export default BrandNameForm
