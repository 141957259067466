import React from 'react'
import { useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import classNames from 'classnames'

import Button from '../../Button'

import styles from './.module.scss'

const Component = ({className, waiting, ...properties}) =>
  <Button className={classNames(styles.continueBtn, className)}
    waiting={
        waiting
      ??
        useSelector(({newProjectBuilder}) =>
          newProjectBuilder?.createInProgress
          || newProjectBuilder?.updateInProgress
        )
    }
    {...properties}
  />

export default ({
  isValid, className, waiting, children
  , validate, disabled, popupContent
}) =>
  isValid ?
    <Component {...{className, waiting, children}}/>
  :
    <Popup
      trigger={
        <Button {...{className, children}} styledAsDisabled
          onClick={event => {
            event.preventDefault()
            validate && validate()
          }}
        />
      }
      {...{disabled}}
      content={popupContent}
      on="click"
      position="top center"
      className={styles.popup}
    />