import moment from 'moment'

import {useTranslation} from 'react-i18next'

const useElapsedTime = (
  type, count
  , t = useTranslation().t
) =>
  t('elapsedTime.' + type, {count})

, useDates = ({
  state
  , designStageExpiresAt, finalistStageExpiresAt
  , filesStageExpiresAt, reviewFilesStageExpiresAt
}) => {
  var stageExpiresAt

  switch (state) {
    case 'design_stage':
      stageExpiresAt = designStageExpiresAt
    break
    case 'finalist_stage':
      stageExpiresAt = finalistStageExpiresAt
    break
    case 'files_stage':
      stageExpiresAt = filesStageExpiresAt
    break
    case 'review_files':
      stageExpiresAt = reviewFilesStageExpiresAt
    break
  }

  return [
    stageExpiresAt ?? 0
    , stageExpiresAt ? new Date() : 0
  ]
}

, getDifference = (to, from, type) =>
  moment(to).diff(from, type)

, useParameters = (to, from) => {
  const hourDifference = getDifference(to, from, 'hours')

  if (hourDifference < 0)
    to = from = 0

  return {to, from, hourDifference}
}

export const getTimeDiffInDaysOrHours = (
  to, from, t
) => {
  var hourDifference

  ({to, from, hourDifference} = useParameters(to, from))

  const getType = (plural = '') =>
    hourDifference < 24 ?
      'hour' + plural
    :
      'day' + plural

  return useElapsedTime(
    getType()
    , getDifference(to, from, getType('s'))
    , t
  )
}

, useElapsedDaysOrHours = project => {
  const [to, from] = useDates(project)

  return getTimeDiffInDaysOrHours(to, from)
}

, useElapsedDays = project => {
  var [to, from] = useDates(project)

  ;({to, from} = useParameters(to, from))

  return useElapsedTime(
    'day'
    , getDifference(to, from, 'days')
  )
}
, useElapsedHours = project => {
  const [to, from] = useDates(project)

  , {hourDifference} = useParameters(to, from)

  return useElapsedTime('hour', hourDifference % 24)
}

, getAgeInDays = createdAt =>
  useElapsedTime(
    'day', moment().diff(new Date(createdAt), 'days')
  )