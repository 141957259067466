import {lazy} from 'react'

export const LoginView = lazy(() => import(
	'@application/index/views/Login'
))
, SignupClientView = lazy(() => import(
	'@application/index/views/client/Signup'
))

, SignupDesignerView = lazy(() => import(
	'@application/index/views/designer/signup/Index'
))
, WelcomePage = lazy(() => import(
	'@application/index/unit/user/designer/WelcomePage'
))

, SignupDesignerProfileStep = lazy(() => import(
	'@application/index/views/designer/signup/Profile'
))
, SignupDesignerPortfolioStep = lazy(() => import(
	'@application/index/views/designer/signup/Portfolio'
))
, SignupDesignerFinishStep = lazy(() => import(
	'@application/index/views/designer/signup/Finish'
))

, ProjectBuilderInitialStepView = lazy(() => import(
	'@application/index/views/projectBuilder/Initial'
))
, ProjectBuilderFirstStepView = lazy(() => import(
	'@application/index/views/projectBuilder/First'
))
, ProjectBuilderSuccessView = lazy(() => import(
	'@application/index/views/projectBuilder/Success'
))
, ProjectBuilderStepView = lazy(() => import(
	'@application/index/views/projectBuilder/Step'
))

, ProjectFeedback = lazy(() => import(
	'@application/index/views/client/Project/Feedback'
))

, NewProjectBuilderLayout = lazy(() => import(
	'@application/index/views/client/Project/new/Layout'
))

, ClientProjectsInProgressView = lazy(() => import(
	'@application/index/views/client/projects/InProgress'
))
, ClientProjectsCompletedView = lazy(() => import(
	'@application/index/views/client/projects/Completed'
))

, ClientBrandsView = lazy(() => import(
	'@application/index/views/client/Brands'
))

, DesignerDashboardDiscoverView = lazy(() => import(
	'@application/index/views/designer/Dashboard/Discover'
))

, GodClientProjectsInProgressView = lazy(() => import(
	'@application/index/views/client/projects/god/InProgress'
))
, GodClientProjectsCompletedView = lazy(() => import(
	'@application/index/views/client/projects/god/Completed'
))

, DesignerProjectBriefView = lazy(() => import(
	'@application/index/views/designer/project/Brief'
))
, DesignerProjectDesignsView = lazy(() => import(
	'@application/index/views/designer/project/Designs'
))
, DesignerProjectFilesView = lazy(() => import(
	'@application/index/views/designer/project/Files'
))

, DesignerNdasView = lazy(() => import(
	'@application/index/views/designer/Ndas'
))
, DesignerMyEarningsView = lazy(() => import(
	'@application/index/views/designer/MyEarnings'
))
, DesignerPayoutsView = lazy(() => import(
	'@application/index/views/designer/Payouts'
))
, DesignerDeliverablesView = lazy(() => import(
	'@application/index/views/designer/Deliverables'
))

, ClientProjectLayout = lazy(() => import(
	'@application/index/views/client/Project'
))
, ClientSettingsLayout = lazy(() => import(
	'@application/index/views/settings/Client'
))
, ClientTransactionsView = lazy(() => import(
	'@application/index/views/client/Transactions'
))

, DesignerSettingsLayout = lazy(() => import(
	'@application/index/views/settings/Designer'
))

, DesignerProjects = lazy(() => import(
	'@application/index/views/designer/Dashboard/Projects'
))

, SignupResendConfirmationView = lazy(() => import(
	'@application/index/views/SignupResendConfirmation'
))
, PasswordRestoreView = lazy(() => import(
	'@application/index/views/password/Restore'
))
, PasswordRestoredView = lazy(() => import(
	'@application/index/views/password/Restored'
))
, PasswordResetView = lazy(() => import(
	'@application/index/views/password/Reset'
))

, MainView = lazy(() => import(
	'@application/index/views/MainView'
))

, ErrorLayout = lazy(() => import(
	'@application/index/component/index/ErrorLayout'
))