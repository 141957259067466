import React from 'react'
import { connect } from 'react-redux'
import { getModal } from '@application/index/asset/store/reducers/modal'

import AgreeNdaModal from '@application/index/unit/user/designer/index/containers/AgreeNdaModal'

import ApproveFilesModal from '@application/index/unit/projects/containers/ApproveFilesModal'
import BlockDesignerModal from '@application/index/unit/project/containers/BlockDesignerModal'
import EliminateWorkModal from '@application/index/unit/project/containers/EliminateWorkModal'
import SuccessfulReviewModal from '@application/index/unit/user/client/project/SuccessfulReviewModal'
import SourceFilesUploadModal from './SourceFilesUpload'

import SuccessModal from '@application/index/component/modal/Success'
import DeliverablesModal from '@application/index/component/modal/designer/Deliverables'
import ComingSoonModal from '@application/index/component/modal/coming-soon'
import LeaveReviewModal from '@application/index/component/modal/leave-review'
import ClientLoginModal from '@application/index/component/modal/client/Login'
import ReserveSpotModal from '@application/index/component/modal/reserve-spot/container'
import ClientVideoModal from '@application/index/component/modal/client/video'
import ClientSignupModal from '@application/index/component/modal/client/Signup'
import DesignerVideoModal from '@application/index/component/modal/designer/video'
import DesignerShareConfirmModal from '@application/index/component/modal/designer/share-confirm'

import InitialBrandsModal from '@application/index/component/projectBuilder/New/BrandsModal'
import BrandsModal from '@application/index/component/projectBuilder/Step/BrandsModal'
import DesignerOrContestDecisionModal from '@application/index/component/projectBuilder/New/DesignerOrContestDecisionModal'
import PaymentModal from '@application/index/component/projectBuilder/New/PaymentModal'
import GetQuoteModal from '@application/index/component/projectBuilder/New/GetQuoteModal'
import PaymentSuccessModal from '@application/index/component/projectBuilder/New/PaymentSuccessModal'
import SuccessMessageModal from '@application/index/component/projectBuilder/New/SuccessMessageModal'

import ExistingLogoModal from '@application/index/component/projectBuilder/Step/ExistingLogoModal'

import ConfirmationModal from '@application/index/component/modal/Confirmation'

import AdditionalSpotsModal from '@application/index/unit/user/client/project/AdditionalSpotsModalContainer'
import AdditionalSpotsPaymentModal from '@application/index/unit/user/client/project/AdditionalSpotsPaymentModalContainer'

import AdditionalDaysModal from '@application/index/unit/user/client/project/AdditionalDaysModalContainer'
import AdditionalDaysPaymentModal from '@application/index/unit/user/client/project/AdditionalDaysPaymentModalContainer'

import SignInModal from '@application/index/views/client/Project/new/components/SignInModal'
import SignUpModal from '@application/index/views/client/Project/new/components/SignUpModal'

const MODAL_COMPONENTS = {
  SUCCESS: SuccessModal,
  AGREE_NDA: AgreeNdaModal,
  COMING_SOON: ComingSoonModal,
  LEAVE_REVIEW: LeaveReviewModal,
  RESERVE_SPOT: ReserveSpotModal,
  CLIENT_VIDEO: ClientVideoModal,
  CLIENT_LOGIN: ClientLoginModal,
  CLIENT_SIGNUP: ClientSignupModal,
  APPROVE_FILES: ApproveFilesModal,
  DESIGNER_VIDEO: DesignerVideoModal,
  BLOCK_DESIGNER: BlockDesignerModal,
  ELIMINATE_WORK: EliminateWorkModal,
  SUCCESSFUL_REVIEW: SuccessfulReviewModal,
  DESIGNER_SHARE_CONFIRM: DesignerShareConfirmModal,
  SOURCE_FILES_CHECKLIST: SourceFilesUploadModal,
  EXISTING_LOGO: ExistingLogoModal,
  DELIVERABLES_MODAL: DeliverablesModal,
  DESIGNER_OR_CONTEST_DECISION: DesignerOrContestDecisionModal,
  PAYMENT_MODAL: PaymentModal,
  PAYMENT_SUCCESS_MODAL: PaymentSuccessModal,
  GET_QUOTE_MODAL: GetQuoteModal,
  SUCCESS_MESSAGE_MODAL: SuccessMessageModal,
  INITIAL_BRANDS_MODAL: InitialBrandsModal,
  BRANDS_MODAL: BrandsModal,
  CONFIRMATION_MODAL: ConfirmationModal,
  ADDITIONAL_SPOTS_MODAL: AdditionalSpotsModal,
  ADDITIONAL_SPOTS_PAYMENT_MODAL: AdditionalSpotsPaymentModal,
  ADDITIONAL_DAYS_MODAL: AdditionalDaysModal,
  ADDITIONAL_DAYS_PAYMENT_MODAL: AdditionalDaysPaymentModal,
  'project/builder/SIGN_IN_MODAL': SignInModal,
  'project/builder/SIGN_UP_MODAL': SignUpModal

}

const ModalRoot = ({ modalType, modalProps }) =>
  modalType ?
    (() => {
      const ModalComponent = MODAL_COMPONENTS[modalType]

      return <ModalComponent {...modalProps}/>
    })()
  :
    <div className="no-open-modals"/>

export default connect(getModal)(ModalRoot)
