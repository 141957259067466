import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {className, container} from './.module.scss'

import { hideModal } from '@application/index/asset/store/actions/modal'
import Modal from '@application/index/component/modal/index'

import { getFeaturedImage } from '@application/index/asset/store/selectors/featuredImage'

import useTranslate from '@application/index/asset/translation/index/useTranslate'

import offerPath from '../Offer/path'

import Links from '@application/index/unit/user/client/project/Project/Polls/Poll/Share/Links'

const path =
  'client.project.files.review.success.share'

, link = 'https://designbro.com/'

const Share = ({onClose, spotUrl}) => {
  const t = useTranslate(path)

  return <Modal
    size="large"
    {...{className}}
    title={t('title')}
    setDisplayed={onClose}
    declineAction={useTranslation().t(offerPath + 'decline')}
  >
    <div className={container}>
      <img src={spotUrl}/>
      <div className={container}>
        <p>{t('description')}</p>
        <Links {...{link}}
          message={
            t('message', {link})
            + '%0a' + spotUrl
          }
        />
      </div>
    </div>
  </Modal>
}

Share.propTypes = {
  onClose: PropTypes.func.isRequired,
  spotUrl: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  spotUrl: getFeaturedImage(state).url
})

const mapDispatchToProps = {
  onClose: hideModal
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(Share)
)
