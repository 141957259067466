import React from 'react'

import styles from './Footer.module.scss'

import { ReactComponent as Tree } from './tree.svg'
import { useTranslation } from 'react-i18next'

const Footer = ({ backButton, continueButton, validation }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.footer}>
      <div className={styles.backButtonWrapper}>
        {backButton}
      </div>
      <div className={styles.continueButtonWrapper}>
        {continueButton}
      </div>
      <div className={styles.validationWrapper}>
        {validation ?
          validation
        : <div className={styles.brandHighlight}>
          <Tree/><span>{t('projectBuilder.We plant 1 tree')}</span>&nbsp;{t('projectBuilder.every design project you start')}
        </div>}
      </div>
    </div>
  )
}

export default Footer
