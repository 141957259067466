import { persistHeaders } from '@application/index/asset/utils/auth'

export const onSuccess = (fsa, callback) => (dispatch) => {
  dispatch({
    type: 'VALIDATE_AUTH_SUCCESS',
    payload: fsa.payload
  })

  if (callback)
    callback()
}
