import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {useTranslation} from 'react-i18next'

import ReduxStoreImageDropzone from '@application/index/component/inputs/ReduxStoreImageDropzonePlain'
import styles from './.module.scss'

const getUrl = (previewUrl) => {
  if (!previewUrl) return

  if (previewUrl.startsWith('blob')) {
    return previewUrl
  }

  const fileName = previewUrl.split('?')[0].split('.')
  const fileExtension = fileName[fileName.length - 1]

  return ['png', 'jpg', 'jpeg'].includes(fileExtension) ? previewUrl : `/${fileExtension}_placeholder.png`
}

const FeaturedImageUploader = ({
  dimensionsValidation = true, setFile, url, uploadedFileId
  , upload, destroy
}) => {
  const {t} = useTranslation()

  , [previewUrl, setPreviewUrl] = useState(url)

  return (
    <div>
      <ReduxStoreImageDropzone
        {...{previewUrl}}
        onDrop={async file => {
          setPreviewUrl(file.preview)

          if (setFile)
            setFile(file)

          if (dimensionsValidation)
            return new Promise(resolve => {
              var image = new Image()

              image.src = file.preview

              image.onload = () => {
                const threshold = .05
                const squareEnough =
                  Math.abs(1 - image.width / image.height) <= threshold

                resolve(squareEnough)

                if (!squareEnough)
                  window.alert(t('designer.project.files.upload.featuredImage.error', {amount: threshold * 100}))
              }
            })
        }}
        onDelete={() => {
          setPreviewUrl()

          if (setFile)
            setFile()

          if (destroy)
            destroy(uploadedFileId)()
        }}
        {...(upload && {onUpload: upload(uploadedFileId)})}
        fileExtensions={[
          'jpg', 'png', 'gif', 'mov', 'mpeg'
        ]}
        fileSize={20}
      />
    </div>
  )
}

FeaturedImageUploader.propTypes = {
  uploadedFileId: PropTypes.string,
  upload: PropTypes.func,
  destroy: PropTypes.func
}

export default FeaturedImageUploader
