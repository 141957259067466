import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Dinero from 'dinero.js'

import { MAX_ADDITIONAL_DAYS } from '@application/index/asset/constants'
import { showAdditionalDaysPaymentModal, hideModal } from '@application/index/asset/store/actions/modal'
import { changeUpsellAttributes } from '@application/index/asset/store/actions/projectBuilder'
import { getProjectBuilderUpsellAttributes } from '@application/index/asset/store/selectors/projectBuilder'

import UpsellModal from './UpsellModal'

class UpsellModalContainer extends Component {
  handleUp = () => {
    const { count, maxCount, changeUpsellAttributes } = this.props

    if (count < maxCount) {
      changeUpsellAttributes({ numberOfDays: count + 1 })
    }
  }

  handleDown = () => {
    const { count, minCount, changeUpsellAttributes } = this.props

    if (count > minCount) {
      changeUpsellAttributes({ numberOfDays: count - 1 })
    }
  }

  handleContinue = () => {
    const { project, showAdditionalDaysPaymentModal } = this.props
    showAdditionalDaysPaymentModal({ project })
  }

  render () {
    return (
      <UpsellModal
        {...this.props}
        onUp={this.handleUp}
        onDown={this.handleDown}
        onContinue={this.handleContinue}
      />
    )
  }
}

const mapStateToProps = (state, { t, project }) => {
  const { numberOfDays } = getProjectBuilderUpsellAttributes(state)

  const additionalDayPrice = project.additionalDayPrice
  const minCount = 1
  const maxCount = MAX_ADDITIONAL_DAYS - project.additionalDays

  const totalCost = Dinero({
    amount: numberOfDays * additionalDayPrice.amountCents,
    currency: additionalDayPrice.amountCurrency
  })

  return {
    id: project.id,
    count: numberOfDays,
    totalCost: totalCost.toFormat('$0,0.00'),
    minCount,
    maxCount,
    title: t('projects.How many days would you like to add')
  }
}

export default withTranslation()(connect(mapStateToProps, {
  showAdditionalDaysPaymentModal,
  changeUpsellAttributes,
  onClose: hideModal
})(UpsellModalContainer))
