import React from 'react'
import classNames from 'classnames'
import Link from './LinkWithRelation'

export default ({as: Component = Link, className, children, ...properties}) =>
  <Component className={classNames(
      className, 'main-button main-button--clear-black main-button--md m-b-10'
    )}
    {...properties}
  >
    {children}
  </Component>