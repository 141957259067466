import { connect } from 'react-redux'
import { showSuccessMessageModal, hideModal } from '@application/index/asset/store/actions/modal'
import { requestManualProject } from '@application/index/asset/store/actions/projectBuilder'
import GetQuoteModal from './GetQuoteModal'

export default connect(null, {
  onClose: hideModal,
  showSuccessMessageModal,
  requestManualProject
})(GetQuoteModal)
