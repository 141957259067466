import api from '@application/index/asset/utils/api'

export const SET_TAGS = 'SET_TAGS'

export const TAG_UPDATE_REQUEST = 'TAG_UPDATE_REQUEST'
export const TAG_UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS'
export const TAG_UPDATE_FAILURE = 'TAG_UPDATE_FAILURE'

const updateTags = (projectId, featuredImageId, names) => api.put({
  endpoint: `/api/v1/projects/${projectId}/featured_images/${featuredImageId}/tags`,
  body: { names: names },
  types: [TAG_UPDATE_REQUEST, TAG_UPDATE_SUCCESS, TAG_UPDATE_FAILURE]
})

const loadTags = (projectId, featuredImageId) => api.get({
  endpoint: `/api/v1/projects/${projectId}/featured_images/${featuredImageId}/tags/load_tags`,
  types: ['GET_TAG_REQUEST', 'GET_TAG_SUCCESS', 'GET_TAG_FAILURE']
})

export const update = () => (dispatch, getState) => {
  const state = getState()

  dispatch(updateTags(state.projects.current, state.featuredImages.attributes.id, state.tags.names)).then(
    () => dispatch(resetTags()))
}

export const getTags = () => (dispatch, getState) => {
  const state = getState()

  if (state.featuredImages.attributes.id) {
    dispatch(loadTags(state.projects.current, state.featuredImages.attributes.id)).then(
      (response) => {
        dispatch(setTags(state, response?.payload))
      })
  }
}

export const setTags = (state, tags) => ({
  type: 'SET_TAGS',
  payload: [...state.tags.names, ...tags.map(tag => tag.name)]
});

export const resetTags = () => ({
  type: 'SET_TAGS',
  payload: [],
});

export const add = (name) => (dispatch, getState) => {
  const state = getState()

  dispatch({
    type: 'SET_TAGS',
    payload: [...state.tags.names, name]
  })
}

export const remove = (name) => (dispatch, getState) => {
  const state = getState()

  dispatch({
    type: 'SET_TAGS',
    payload: state.tags.names.filter(item => item !== name)
  })
}
