import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import { Modal } from 'semantic-ui-react'

const DesignerShareConfirmModal = ({
  history, submit, hideModal
}) =>
  <Modal className="main-modal" size="small" open onClose={hideModal}>
    <div className="main-modal__info">
      <div className="main-modal__info-body">
        <i className="main-modal__info-icon icon-check-circle in-green-500" />

        <p className="main-modal__info-text">
          Please confirm that the work you have uploaded is your work
          and that there is nothing prohibiting you from sharing it.
        </p>
      </div>

      <div className="main-modal__actions">
        <div className="main-button-link main-button-link--grey-black main-button-link--lg m-b-10 m-r-15" onClick={hideModal}>
          Go Back
        </div>

        <div
          id="share-modal-submit"
          className="main-button-link main-button-link--black-pink main-button-link--lg m-b-10"
          onClick={() =>
            submit(() => {
              hideModal()
              history.push('/d/signup/finish')
            })
          }
        >
          {useTranslation().t('client.project.modal.continue')}

          <i className="m-l-20 font-8 icon-arrow-right-long" />
        </div>
      </div>
    </div>
  </Modal>

DesignerShareConfirmModal.propTypes = {
  hideModal: PropTypes.func.isRequired
}

export default withRouter(DesignerShareConfirmModal)
