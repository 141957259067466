import { connect } from 'react-redux'
import { history } from '@application/index/index/history'

import { signOutAndCleanHeaders } from '@application/index/asset/store/actions/auth'
import { cleanHeadersInCookies } from '@application/index/asset/utils/auth'

import SignoutButton from './component'

export default connect(null, {
  onClick: signOutAndCleanHeaders
})(SignoutButton)
