import _ from 'lodash'
import Dinero from 'dinero.js'
import { connect } from 'react-redux'

import { createOneToOneProject } from '@application/index/asset/store/actions/projectBuilder'

import { hideModal } from '@application/index/asset/store/actions/modal'

import { projectBuilderProductSelector } from '@application/index/asset/store/selectors/product'
import { getProjectBuilderAttributes } from '@application/index/asset/store/selectors/projectBuilder'
import { getVatRates, isNotIreland } from '@application/index/asset/store/selectors/vat'
import { currentDiscountSelector } from '@application/index/asset/store/selectors/discount'
import { discountInAmount } from '@application/index/asset/store/selectors/prices'
import { humanizeProjectName } from '@application/index/asset/utils/humanizer'
import { getMe } from '@application/index/asset/store/selectors/me'

import PaymentModal from './PaymentModal'

const getProjectBasePrice = (product, projectType) => {
  return Dinero({
    amount: projectType === 'one_to_one' ? product.oneToOnePriceCents : product.priceCents,
    currency: product.priceCurrency
  })
}

const getVatRateByCountry = (vatRates, countryCode) => {
  return _.find(vatRates, (v) => v.countryCode === countryCode)
}

export const getVat = (price, vatRate, vatNumber) => {
  const shouldHaveVat = vatRate && !(isNotIreland(vatRate.countryCode) && vatNumber)
  const vatMultiplier = shouldHaveVat ? vatRate.percent / 100 : 0

  return price.multiply(vatMultiplier)
}

const displayDiscountAmount = (discount) => {
  if (!discount) {
    return null
  }

  switch (discount.discountType) {
  case 'percent':
    return `${discount.value}%`
  case 'amount':
    return Dinero({ amount: discount.amountCents, currency: discount.amountCurrency }).toFormat('$0,0.00')
  default:
    return null
  }
}

const mapStateToProps = (state) => {
  const me = getMe(state)
  const { countryCode, vat: vatNumber, creditCardNumber, creditCardProvider } = me

  const discount = currentDiscountSelector(state)

  const discountAmount = displayDiscountAmount(discount)
  const isDiscountVisible = discount && discount.available


  const product = projectBuilderProductSelector(state)
  const vatRates = getVatRates(state)
  const attributes = getProjectBuilderAttributes(state)

  const vatRate = getVatRateByCountry(vatRates, countryCode)
  const isVatVisible = countryCode && vatRate

  const price = getProjectBasePrice(product, attributes.projectType)
  const priceWithDiscount = price.subtract(discountInAmount(price, discount))

  const vat = getVat(priceWithDiscount, vatRate, vatNumber)

  const total = priceWithDiscount.add(vat)

  return {
    paymentType: attributes.paymentType,
    basePriceName: humanizeProjectName(product.name),
    basePriceValue: price.toFormat('$0,0.00'),
    isVatVisible,
    vatValue: vat.toFormat('$0,0.00'),
    totalValue: total.toFormat('$0,0.00'),
    paypalAmount: total.toRoundedUnit(2),
    hasCard: creditCardNumber && creditCardProvider,
    creditCardNumber,
    creditCardProvider,
    discountAmount: `- ${discountAmount}`,
    isDiscountVisible,
    product: product,
    discount: discount,
    vatRate: vatRate,
    vatNumber: vatNumber
  }
}

export default connect(mapStateToProps, {
  onSubmit: createOneToOneProject,
  onClose: hideModal
})(PaymentModal)
