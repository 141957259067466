import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import {useTranslation, Trans} from 'react-i18next'

import Textarea from 'react-textarea-autosize'

import {className} from './.module.scss'

import Modal from '@application/index/component/modal/index'
import Copy from '../Copy'

import joinWithLineBreak from '../joinWithLineBreak'

import mainPath from '../path'
import api from '@application/index/asset/utils/apiV2'

import { useDispatch } from 'react-redux'
import { getPersonalFollowUpDiscount } from '@application/index/asset/store/actions/personalFollowUpDiscount'
import { createClientDiscount } from '@application/index/asset/store/actions/discount'

const path = mainPath + 'reward.'

const Reward = ({setDisplayed, clientDiscount, ...props}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const content = clientDiscount?.code

  const discountAmount = props.personalFollowUpDiscount?.value || 10
  const durationAmount = props.personalFollowUpDiscount?.duration || 90
  const discountType = props.personalFollowUpDiscount?.discount_type === 'percent' ? '%' : '$'

  useEffect(() => {
    if(props.personalFollowUpDiscount){
      delete props.personalFollowUpDiscount.id
      const params = { discount: props.personalFollowUpDiscount, projectId: props.projectId }

      dispatch(createClientDiscount(params))
    }
  }, [])

  return <Modal
    size="large"
    {...{className}}
    title={t(path + 'title')}
    action={t(path + 'confirm')}
    onConfirm={() => setDisplayed(false)}
  >
    <p>
      <Trans
        i18nKey={path + 'subTitle'}
        values={{discountAmount, discountType}}
      />
    </p>
    <Textarea value={content}/>
    <Copy {...{content}} copyAction={t(path + 'copy')}/>
    <p>
      {joinWithLineBreak(
        t(path + 'description', {durationAmount})
      )}
    </p>
  </Modal>
}

const mapStateToProps = state => {
  return {
    personalFollowUpDiscount: state.personalFollowUpDiscount.getPersonalFollowUpDiscount,
    clientDiscount: state.clientDiscount.clientDiscount,
    projectId: state.projects.current
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createClientDiscount: (data) => dispatch(createClientDiscount(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reward);
