import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { hideModal } from '@application/index/asset/store/actions/modal'
import MaterialRadio from '@application/index/component/inputs/MaterialRadioPlain'
import MaterialTextarea from '@application/index/component/inputs/MaterialTextareaPlain'

const path = 'client.project.modal.'

const EliminateWorkModal = ({ onClose, onConfirm, project, history }) => {
  const {t} = useTranslation()
  , {
    description, options
  } =
    t(path + 'eliminateDesign')

  const [reason, setReason] = useState(null)
  const [eliminateCustomReason, setEliminateCustomReason] = useState(null)

  const onChange = (name, value) => {
    setReason(value)
    name === 'other' || setEliminateCustomReason(null)
  }

  const handleCustomReasonChange = (name, value) => {
    setEliminateCustomReason(value)
  }

  const isFormInvalid = !reason || (reason === 'other' && !eliminateCustomReason)

  const handleEliminateDesign = () => {
    const eliminationData = {
      eliminateReason: reason,
      eliminateCustomReason: eliminateCustomReason,
    }
    if (!isFormInvalid) {
      onConfirm(eliminationData).then(() => {
        history.push(`/c/projects/${project.id}`)
        onClose()
      })
    }
  }

  return (
    <Modal id="eliminate-work-modal" className="main-modal main-modal--xs" size="small" open onClose={onClose}>
      <div className="modal-primary">
        <div className="modal-primary__header conv-modal-primary-header bg-green-500 in-white">
          <p className="modal-primary__header-title">
            {t(path + 'title')}
          </p>
        </div>
        <div className="modal-primary__body">
          <div className="modal-primary__body-block conv-confirmation-modal-primary-body-block">
            <p className="in-grey-200 font-14">
              {description}
            </p>
            {Object.entries(options).map(
              ([name, label]) =>
                <MaterialRadio key={name}
                  {...{name, label, onChange}}
                  value={name}
                  checked={reason === name}
                />
            )}
            {reason === 'other' &&
              <MaterialTextarea
                type="text"
                value={eliminateCustomReason}
                name="eliminate_custom_reason"
                onChange={handleCustomReasonChange}
                error={
                  reason === 'other'
                  && !eliminateCustomReason &&
                    t('Required')
                }
              />
            }
          </div>
        </div>
        <div className="modal-primary__actions conv-modal-primary-actions conv-confirmation-primary-actions align-items-start">
          <button className="main-button-link main-button-link--lg main-button-link--grey-black m-b-10" type="button" onClick={onClose}>
            {t('layout.Cancel')}
          </button>
          <button
            id="modal-confirm"
            className="main-button-link main-button-link--lg main-button-link--black-pink m-b-10"
            type="button"
            onClick={handleEliminateDesign}
            disabled={isFormInvalid}
          >
            {t('layout.Confirm')}
            <i className="m-l-20 font-8 icon-arrow-right-long" />
          </button>
        </div>
      </div>
    </Modal>
  )
}

EliminateWorkModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default withRouter(connect(null, {
  onClose: hideModal
})(EliminateWorkModal))
