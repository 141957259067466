import React from 'react'

import {className} from './.module.scss'

import MainPageLink from '@application/index/component/index/MainPageLink'
import Logo from './Logo'
import Footer from '@application/index/component/index/Footer'

export default ({children}) =>
	<div className="page-container sign-in__page-container">
		<header className={className + ' container main-header'}>
			<MainPageLink className="main-header__logo">
				<Logo/>
			</MainPageLink>
		</header>

		{children}

		<Footer/>
	</div>