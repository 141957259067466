import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import Headline from '../Headline'
import Input from '../Input'
import usePassword from '@application/index/views/client/Project/new/components/SignUpModal/usePassword'
import PasswordInput from '../PasswordInput'
import { ContinueButton } from '../Footer'

import Cross from '../Cross'

import { signinEmail } from '@application/index/asset/store/actions/auth'
import { onSuccess } from '@application/index/asset/store/actions/signup'

import { isEmail, isWeak } from '@application/index/asset/utils/validators'

import { showSignUpModal, hideModal } from '@application/index/asset/store/actions/modal'

import styles from './SignInModal.module.scss'

import useEmail from '@application/index/views/client/Project/new/components/SignUpModal/useEmail'

import tLogIn from '@application/index/views/Login/useTranslation'

const SignInModal = ({
  title, successCallback, projectLoading, signUpModalProperties = {}
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const isValid = email && isEmail(email) && password

  const onClose = () => dispatch(hideModal())

  const handleSubmit = event => {
    event.preventDefault()

    dispatch(
      signinEmail({email, password}, projectLoading)
    ).then(response => {
      if (!response.error)
        dispatch(onSuccess(response, successCallback))
    })
  }

  return (
    <Modal open size="tiny" className={styles.modal}
      {...{onClose}}
    >
      <Cross onClick={onClose}/>

      <Modal.Content className={styles.modalContent}>
        <Headline>{t(title)}</Headline>

        <form onSubmit={handleSubmit}>
          <div className={styles.signUpForm}>
            <Input
              inputClassName={styles.input}
              value={email}
              onChange={event =>
                setEmail(event.target.value)
              }
              placeholder={useEmail()}
            />

            <PasswordInput
              inputClassName={styles.input}
              type="password"
              value={password}
              onChange={event =>
                setPassword(event.target.value)
              }
              placeholder={usePassword()}
            />
          </div>

          <div className={styles.modalFooter}>
            <ContinueButton {...{isValid}}
              popupContent={t('projectBuilder.Please create your account before checking out')}
            >
              {tLogIn('Login')} →
            </ContinueButton>

            <p>
              <span className={styles.createNewAccount}
                onClick={() => {
                  dispatch(hideModal())
                  dispatch(showSignUpModal({
                    successCallback
                    , ...signUpModalProperties
                  }))
                }}
              >
                {t('projectBuilder.Create new account')}
              </span>
            </p>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  )
}

SignInModal.defaultProps = {
  title: 'projectBuilder.Welcome back'
}

export default SignInModal
