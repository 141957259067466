const getFileData = (data) => {
  const formData = new window.FormData()
  formData.append('file', data.file)
  formData.append('type', data.type)
  return formData
}

const getMultipleFileData = (data) => {
  const formData = new window.FormData()
  data.files.forEach(file => formData.append('fileToUpload[]', file))
  formData.append('text', data.text)
  formData.append('draft_message', data.draft_message)
  data.message_send && formData.append('message_send', data.message_send)
  return formData
}

const checkFileType = (file) => {
  const extenstions = ['jpg', 'jpeg', 'png', 'gif', 'mp4', 'mpeg', 'mov', 'lot']
  const extension = file.name.replace(/.*\./, '').toLowerCase()

  return extenstions.indexOf(extension) >= 0
}

export { getFileData, getMultipleFileData, checkFileType }
