import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import classNames from 'classnames'
import _ from 'lodash'
import { Popup } from 'semantic-ui-react'

import FormButtonWrapper from '@application/index/component/index/FormButtonWrapper'
import FormButton from '@application/index/component/index/FormButton'
import Uploader from './Uploader'
import Tags from './Tags'
import styles from './.module.scss'

import {useDispatch} from 'react-redux'
import { update } from '@application/index/asset/store/actions/tags'
import { getTags } from '@application/index/asset/store/actions/tags'

const FeaturedImageForm = ({ canContinue, onSubmit }) => {
  const { register, handleSubmit, setValue, errors } = useForm()

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getTags())
  },[])

  const submitHandler = () => {
    dispatch(update())
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className={styles.subheader}>
        Set the featured image for the winning design
        <Popup
          content="The featured image should be a clear representation of the winning design. It will be shown in your portfolio on DesignBro as well as on the client's brand overview page."
          position="right center"
          size="mini"
          trigger={<i className={classNames('icon-info-circle', styles.infoIcon)} />}
        />
      </div>

      <div className={styles.warning}>
        NOTE: The featured image must be in a square shaped JPG format.
      </div>

      <div className={styles.wrapper}>
        <Uploader/>
        <Tags/>
      </div>

      <FormButtonWrapper className={styles.buttonWrapper}>
        <FormButton
          type="submit"
          className={classNames({ active: canContinue })}
          disabled={!canContinue}
        >
          Confirm & continue &#8594;
        </FormButton>
      </FormButtonWrapper>
    </form>
  )
}

export default FeaturedImageForm
