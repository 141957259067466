import {
  GET_PERSONAL_FOLLOW_UP_DISCOUNT_REQUEST,
  GET_PERSONAL_FOLLOW_UP_DISCOUNT_SUCCESS,
  GET_PERSONAL_FOLLOW_UP_DISCOUNT_FAILURE
} from '@application/index/asset/store/actions/personalFollowUpDiscount'

import { combineReducers } from 'redux'

const getPersonalFollowUpDiscount = (state = [], action) => {
  switch (action.type) {
  case GET_PERSONAL_FOLLOW_UP_DISCOUNT_REQUEST:
    return null
  case GET_PERSONAL_FOLLOW_UP_DISCOUNT_SUCCESS:
    return action.payload[0] || []
  case GET_PERSONAL_FOLLOW_UP_DISCOUNT_FAILURE:
    return null
  default:
    return state
  }
}

export default combineReducers({
  getPersonalFollowUpDiscount
})
