import _ from 'lodash'
import { createSelector } from 'reselect'

export const discountsSelector = state => state.entities.discounts
export const currentDiscountValueSelector = state => state.projectBuilder.attributes.discountCode

export const currentDiscountSelector = createSelector(
  [discountsSelector, currentDiscountValueSelector],
  (discounts, discountCode) => {

  for (const key in discounts) {
    var clientId = discounts[key].clientId
    if (clientId && discounts[key].usedNum >= discounts[key].maxNum){
      delete discounts[key]
    } else if(clientId && clientId !== discounts[key].client.id){
      delete discounts[key]
    } else if(!clientId && discounts[key].usedNum >= discounts[key].maxNum){
      delete discounts[key]
    } else {
      continue
    }
  }

    return _.find(discounts, (d) => d.code === discountCode)
  }
)

export const isCurrentDiscountValidSelector = createSelector(
  [currentDiscountSelector, currentDiscountValueSelector],
  (currentDiscount, discountCode) => {
    return currentDiscount && currentDiscount.available
  }
)
