import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

import Navigation from '@application/index/views/Login/Navigation'

import EmailClientForm from './Form'

import t from '@application/index/views/Login/useTranslation'

const SignupClient = ({
  asModalWindow = false, showSaveWorkHint = false
}) => (
  <main className="sign-in__container">
    <div className={classNames('sign-in__form', {
      'm-b-0': asModalWindow
    })}>
      <Navigation {...{asModalWindow}} />

      {showSaveWorkHint &&
        <p className="m-b-35 font-bold">
          Sign up now to save your work!
        </p>
      }

      <EmailClientForm/>

      <p className="sign-in__form-text-md">
        Already have an account?
         <Link className="sign-in__form-link-pink"
          to="/login"
        >
          {t('Login')}
        </Link>
      </p>
    </div>
  </main>
)

SignupClient.propTypes = {
  asModalWindow: PropTypes.bool.isRequired,
  showSaveWorkHint: PropTypes.bool.isRequired
}

export default SignupClient