import api from '@application/index/asset/utils/api'

import {name, endpoint} from './constant'

export const LOAD_PRODUCTS_REQUEST = name + '_REQUEST'
export const LOAD_PRODUCTS_SUCCESS = name + '_SUCCESS'
export const LOAD_PRODUCTS_FAILURE = name + '_FAILURE'

export const loadProducts = () => api.get({
  endpoint, normalize: true, types: [
    LOAD_PRODUCTS_REQUEST,
    LOAD_PRODUCTS_SUCCESS,
    LOAD_PRODUCTS_FAILURE
  ]
})
