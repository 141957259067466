import React from 'react'

import styles from './index/.module.scss'

import LinkWithRelation from '@application/index/component/index/LinkWithRelation'

import { home, newProject } from './shared'

import {useTranslation} from 'react-i18next'
import StartNew from './StartNew'

const separator = '- '

export default ({ polls, links, contentFiles, contentDrafts, onClick }) => {
  const {t} = useTranslation()

  const get = links =>
    links.map(({
      id, pollId, brand_name, product_name, brand_id, index
    }) => {
      const pollIndex = index && ' ' + separator + 'Poll ' + index

      return <LinkWithRelation
        key={pollId ?? id}
        className={styles.link}
        title={
          brand_name && (brand_name + ' ' + separator)
          + product_name
          + (pollIndex ?? '')
        }
        to={home + '/' + (contentDrafts ?
          'brands/' + brand_id + '/in-progress'
        :
          'projects/' + id
            + [
              [
                polls, '/poll/' + pollId
              ],
              [
                contentFiles, '/files'
              ]
            ].reduce((result, [condition, path]) =>
              result + (condition ?
                path
              : '')
            , '')
        )}
        {...{onClick}}
      >
        {brand_name && <>
          <span>{brand_name}&nbsp;</span>
          {separator}
        </>}
        {product_name}
        {pollIndex}
      </LinkWithRelation>
    })

  const array = Array.isArray(links)
  const object = links && !array

  return <>
    <hr/>
    {polls ? <>
      <h4 className={styles.first}>
        {t('projects.In progress')}
      </h4>
      {object && get(links.started)}
    </> :
      array && get(links)
    }
    {polls ? <>
      <h4>{t('completed')}</h4>
      {object && get(links.finished)}
    </> :
      !contentFiles &&
        <StartNew to={newProject} {...{onClick}}>
          {t('brands.project')}
        </StartNew>
    }
  </>
}