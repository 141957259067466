import { connect } from 'react-redux'

import BrandsList from './BrandsList'
import { getBrands } from '@application/index/asset/store/selectors/brands'

const mapStateToProps = (state) => ({
  brands: getBrands(state)
})

export default connect(mapStateToProps)(BrandsList)
