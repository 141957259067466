import { connect } from 'react-redux'

import { getMe } from '@application/index/asset/store/reducers/me'

import Footer from './component'

const mapStateToProps = (state) => ({
  userType: getMe(state).userType
})

export default connect(mapStateToProps)(Footer)
