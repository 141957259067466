import React, {Suspense, useEffect} from 'react'

import {useSelector} from 'react-redux'
import {meSelector} from '@application/index/asset/store/selectors/me'
import useChangeLanguage from '@application/index/asset/translation/index/useChange'

import { Route, Switch, Redirect } from 'react-router-dom'

import {history} from '@application/index/index/history'
import Router from './Router'

import {newProject} from '@application/index/views/Layout/NavigationMenu/index/shared'
import { products } from '@application/index/asset/constants'

import Unsubscribe from '@application/index/component/static/Unsubscribe'
import {
  LoginView, SignupClientView

  , SignupDesignerView, WelcomePage

  , SignupDesignerProfileStep
  , SignupDesignerPortfolioStep
  , SignupDesignerFinishStep

  , ProjectBuilderInitialStepView
  , ProjectBuilderFirstStepView
  , ProjectBuilderSuccessView
  , ProjectBuilderStepView

  , ProjectFeedback

  , NewProjectBuilderLayout

  , ClientProjectsInProgressView
  , ClientProjectsCompletedView

  , ClientBrandsView

  , DesignerDashboardDiscoverView

  , GodClientProjectsInProgressView
  , GodClientProjectsCompletedView

  , DesignerProjectBriefView
  , DesignerProjectDesignsView
  , DesignerProjectFilesView

  , DesignerNdasView, DesignerMyEarningsView
  , DesignerPayoutsView, DesignerDeliverablesView

  , ClientProjectLayout, ClientSettingsLayout
  , ClientTransactionsView

  , DesignerSettingsLayout

  , DesignerProjects

  , SignupResendConfirmationView
  , PasswordRestoreView, PasswordRestoredView
  , PasswordResetView

  , MainView

  , ErrorLayout
}
  from './page'

import Intercom from './Intercom'

import SimpleModal from './modal/Simple'
import ModalRoot from './modal/Root'

const completed = 'completed'

, Routes = () => {
  const {language} = useSelector(meSelector)

  const changeLanguage = useChangeLanguage()

  useEffect(() => changeLanguage(language), [language])

  return <Router {...{history}}>
    <Suspense>
      <Switch>
        <Route path="/unsubscribe-projects-active-mails"><Unsubscribe/></Route>
        <Route path="/login"><LoginView/></Route>
        <Route path="/c/signup">
          <SignupClientView/>
        </Route>

        <Route path="/d/signup" exact>
          <SignupDesignerView/>
        </Route>
        <Route path="/d/welcome" exact>
          <WelcomePage/>
        </Route>

        <Route path="/d/signup/profile">
          <SignupDesignerProfileStep/>
        </Route>
        <Route path="/d/signup/portfolio">
          <SignupDesignerPortfolioStep/>
        </Route>
        <Route path="/d/signup/finish">
          <SignupDesignerFinishStep/>
        </Route>

        <Route exact path={newProject}>
          <ProjectBuilderInitialStepView/>
        </Route>
        <Route exact path={
          newProject
          + '/:productKey(' + products.join('|') + ')'
        }>
          <ProjectBuilderFirstStepView/>
        </Route>
        <Route exact path="/projects/:id/success">
          <ProjectBuilderSuccessView/>
        </Route>
        <Route exact path="/projects/:id/:step">
          <ProjectBuilderStepView/>
        </Route>

        <Route path="/project/:id/feedback">
          <ProjectFeedback/>
        </Route>

        <Route path="/new-project">
          <NewProjectBuilderLayout/>
        </Route>

        <Route path="/c/brands/:id/in-progress">
          <ClientProjectsInProgressView/>
        </Route>
        <Route path={'/c/brands/:id/' + completed}>
          <ClientProjectsCompletedView/>
        </Route>

        <Route exact path="/c">
          <ClientBrandsView/>
        </Route>

        <Route exact path="/d">
          <Redirect to="/d/in-progress"/>
        </Route>
        <Route exact path="/d/discover">
          <DesignerDashboardDiscoverView/>
        </Route>
        <Route
          path="/d/discover/:product_category_id"
        >
          <DesignerDashboardDiscoverView/>
        </Route>

        <Route exact path="/g">
          <GodClientProjectsInProgressView/>
        </Route>
        <Route path={'/g/' + completed}>
          <GodClientProjectsCompletedView/>
        </Route>

        <Route exact path="/d/projects/:id">
          <DesignerProjectBriefView/>
        </Route>
        <Route exact path="/d/projects/:id/designs">
          <DesignerProjectDesignsView/>
        </Route>
        <Route path="/d/projects/:id/files">
          <DesignerProjectFilesView/>
        </Route>

        <Route path="/d/ndas">
          <DesignerNdasView/>
        </Route>
        <Route path="/d/my-earnings">
          <DesignerMyEarningsView/>
        </Route>
        <Route path="/d/payouts">
          <DesignerPayoutsView/>
        </Route>
        <Route path="/d/deliverables">
          <DesignerDeliverablesView/>
        </Route>

        { /* Brief, Designs and Files pages */ }
        { /* /c/projects/:id */ }
        { /* /c/projects/:id/designs */ }
        { /* /c/projects/:id/files */ }
        <Route path="/c/projects/:id">
          <ClientProjectLayout/>
        </Route>

        { /* Client settings */ }
        { /* /c/settings */ }
        { /* /c/settings/notifications */ }
        { /* /c/settings/password */ }
        <Route path="/c/settings">
          <ClientSettingsLayout/>
        </Route>

        <Route path="/c/transactions">
          <ClientTransactionsView/>
        </Route>

        { /* Client settings */ }
        { /* /d/settings */ }
        { /* /d/settings/notifications */ }
        { /* /d/settings/password */ }
        <Route path="/d/settings">
          <DesignerSettingsLayout/>
        </Route>

        <Route path="/d/:state">
          <DesignerProjects/>
        </Route>

        <Route path="/confirmation">
          <SignupResendConfirmationView/>
        </Route>
        <Route path="/restore-password">
          <PasswordRestoreView/>
        </Route>
        <Route path="/password-restored">
          <PasswordRestoredView/>
        </Route>
        <Route path="/change-password/:token">
          <PasswordResetView/>
        </Route>

        <Route exact path="/"><MainView/></Route>

        <Route path="/"><ErrorLayout/></Route>
      </Switch>
    </Suspense>

    <SimpleModal/>
    <ModalRoot/>

    <Intercom/>
  </Router>
}

export default Routes
