import { connect } from 'react-redux'

import { signinEmail } from '@application/index/asset/store/actions/auth'
import showErrors from '@application/index/asset/utils/errors'
import { showServerErrors } from '@application/index/asset/utils/form'

import Content from './Content'

const onError = showErrors(null, {
  401: showServerErrors('forms.signin')
})

export default connect(null, {
  signinEmail,
  onError
})(Content)
