import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import HeaderControlsClient from '@application/index/component/index/HeaderControlsClient'
import HeaderControlsDesigner from '@application/index/component/index/HeaderControlsDesigner'

import MainPageLink from '@application/index/component/index/MainPageLink'

const Header = ({ me }) => (
  <header className="bg-black">
    <div className="container main-header">
      {(() => {
        const Component = me.userType ? Link : MainPageLink

        return <Component className="main-header__logo" {...(
          me.userType && {to: '/'}
        )}>
          <img src="/designbro-logo-white.svg" alt="logo" class="logo"/>
        </Component>
      })()}

      {me.userType === 'designer' && <HeaderControlsDesigner />}
      {me.userType === 'client' && <HeaderControlsClient />}
    </div>
  </header>
)

export default Header
