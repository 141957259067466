import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '@application/index/component/index/Footer/useTranslate'

import MaterialModal from './Material'
import TermsModalContent from '@application/index/component/static/signup/TermsModalContent'

const TermsModal = ({
  linkClasses = null
  , trigger = useTranslate()('Terms')
}) =>
  <MaterialModal {...{linkClasses, trigger}}
    title={
      <div>
        <p className="modal-primary__header-title main-modal__info-text">
          PLATFORM TERMS AND CONDITIONS
        </p>
        <p className="modal-primary__header-title main-modal__info-text">
          DesignBro
        </p>
        <p className="main-modal__info-text">
          Version/Last update: September 2018
        </p>
      </div>
    }
    value={<TermsModalContent/>}
  />

TermsModal.propTypes = {
  linkClasses: PropTypes.string
}

export default TermsModal
