import React from 'react'

import useTitle from './useTitle'
import useRootDomainUrl from '@application/index/function/useRootDomainUrl'

import LinkWithRelation from '@application/index/component/index/LinkWithRelation'

export default properties =>
  <LinkWithRelation as="a"
    title={useTitle()} href={useRootDomainUrl()}
    {...properties}
  />