import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import Popover from '@terebentina/react-popover'
import { useTranslation } from 'react-i18next'

import includesByRegex from '@application/index/asset/utils/includesByRegex'

import SignoutButton from '@application/index/component/index/SignoutButton'
import CurrentUserPic from '@application/index/component/index/CurrentUserPic'

const DropdownMenu = ({ registration }) => {
  const { t } = useTranslation()

  return (
    <Popover trigger={<CurrentUserPic />} position="bottom">
      <ul className="header-designer__dropdown header__menu-dropdown header-dropdown__trigon">
        <li className="header__menu-dropdown-item">
          <Link to="/c" className="header__menu-dropdown-link">
            {t('header.My Brands')}
          </Link>
        </li>

        <li className="header__menu-dropdown-item">
          <Link to="/c/settings" className="header__menu-dropdown-link">
            {t('header.Account Settings')}
          </Link>
        </li>

        <li className="header__menu-dropdown-item">
          <Link to="/c/transactions" className="header__menu-dropdown-link">
            {t('header.Transactions')}
          </Link>
        </li>

        <li className="header__menu-dropdown-item">
          <SignoutButton />
        </li>
      </ul>
    </Popover>
  )
}


DropdownMenu.propTypes = {
  registration: PropTypes.bool
}

const HeaderControlsClient = ({ registration }) => {
  return (
    <div className="header-designer__controls">
      <div className="relative">
        <DropdownMenu registration={registration} />
      </div>
    </div>
  )
}

HeaderControlsClient.propTypes = {
  registration: PropTypes.bool
}

export default withRouter(HeaderControlsClient)
