import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import resources from './resources'

import domain from './domain'

i18n
  .use(initReactI18next)
  .init({
    resources
    , lng: window.me?.data.attributes.language ??
      domain[
        window.location.hostname.split('.').pop()
      ]
    , fallbackLng: 'en'
    , returnObjects: true
    , interpolation: {
      escapeValue: false
    }
  })
