import React from 'react'
import {useSelector} from 'react-redux'

import useTranslate from '@application/index/asset/translation/index/useTranslate'
import {meSelector} from '@application/index/asset/store/selectors/me'

import {newProject} from '@application/index/views/Layout/NavigationMenu/index/shared'
import Link from '@application/index/views/Layout/NavigationMenu/index/StartNew/Link'

import {className} from './.module.scss'

export default () => {
	const t = useTranslate(
		'client.project.subscriptionPlan'
	)
	, {
		subscription: {name, maxConcurrentProjects}
		, subscriptionUsedProjectsCount
	} =
		useSelector(meSelector)

	return <div {...{className}}>
		<div>
			<div>
				{t('label')}
				<h2>{name}</h2>
			</div>
			<div>
				{t('activeProjectsLabel')}
				<h2>
					{
						subscriptionUsedProjectsCount
					}/{
						maxConcurrentProjects
					}
				</h2>
				{
					subscriptionUsedProjectsCount
						<
					maxConcurrentProjects
				?
					<Link to={newProject}>
						{t('startProject')}
					</Link>
				:
					<span>
						{t('startProjectDescription')}
					</span>
				}
			</div>
		</div>
	</div>
}