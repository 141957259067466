import { connect } from 'react-redux'

import { getMe } from '@application/index/asset/store/reducers/me'

import HeaderDark from './component'

const mapStateToProps = (state) => ({
  me: getMe(state)
})

export default connect(mapStateToProps)(HeaderDark)
