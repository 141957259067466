import { createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import multi from 'redux-multi'
import thunk from 'redux-thunk'

const configureStore = (rootReducer, preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(
      multi,
      thunk,
      apiMiddleware
    )
  )

export default configureStore
