import React from 'react'
import PropTypes from 'prop-types'

import Footer from '@application/index/component/index/Footer'
import HeaderDesigner from '@application/index/component/index/HeaderDesigner'

const SignupLayout = ({ children }) => (
  <div className="page-container">
    <HeaderDesigner registration />
    {children}
    <Footer />
  </div>
)

SignupLayout.propTypes = {
  children: PropTypes.node
}

export default SignupLayout
