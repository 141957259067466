import React, { useState } from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import { Modal } from 'semantic-ui-react'
import styles from './PaymentModal.module.scss'
import Button from '@application/index/component/index/Button'
import Dinero from 'dinero.js'
import { changeAttributes } from '@application/index/asset/store/actions/projectBuilder'

import PaymentSummaryItem from '../PaymentSummaryItem'
import Discount from '../Discount'
import { useDispatch, useSelector } from 'react-redux'
import UpsellModal from '@application/index/views/client/Project/new/page/Step/index/Details/components/UpsellModal'
import { screenPriceSelector } from '@application/index/views/client/Project/new/store/selectors/products'
import { discountInAmount } from '@application/index/asset/store/selectors/prices'
import { getVat } from './PaymentModalContainer'

const PaymentModal = ({
  basePriceName,
  basePriceValue,
  isVatVisible,
  vatValue,
  totalValue,
  isDiscountVisible,
  discountAmount,
  currency,
  className,
  onSubmit,
  onClose,
  product,
  discount,
  vatRate,
  vatNumber
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [buttonFrozen, setButtonFrozen] = useState(false)
  const handleButtonSubmit = (e) => {
    setButtonFrozen(true)
    e.preventDefault()

    onSubmit().then(() => {
      setButtonFrozen(false)
    })
  }

  const [isUpsellPopupOpen, setUpsellPopupOpen] = useState(true)

  const [maxScreensCount, setMaxScreensCount] = useState(1)
  const checkModalToOpen = product?.key === 'website-additional-screens' && isUpsellPopupOpen

  let additionalScreenPrice, productTotalValue, basePriceAdditionalScreensValue

  if(product){
    additionalScreenPrice = useSelector((state) => screenPriceSelector(state, maxScreensCount)) || Dinero({ amount: 0, currency: product?.priceCurrency })
    productTotalValue = Dinero({ amount: product?.priceCents, currency: product?.priceCurrency })

    basePriceAdditionalScreensValue = additionalScreenPrice.add(productTotalValue).toFormat('$0,0.00')
  }

  const websiteAdditionalScreenPrice = (discount, vatRate, vatNumber) => {
    const priceWithDiscount = additionalScreenPrice.add(productTotalValue).subtract(discountInAmount(additionalScreenPrice.add(productTotalValue), discount))

    vatValue = getVat(priceWithDiscount, vatRate, vatNumber)

    return priceWithDiscount.add(vatValue).toFormat('$0,0.00')
  }

  const totalPrice = product?.key === 'website-additional-screens' ? websiteAdditionalScreenPrice(discount, vatRate, vatNumber) : totalValue

  const currentScreenCount = (maxScreensCount) => {
    setMaxScreensCount(maxScreensCount)
    dispatch(changeAttributes({
      maxScreensCount
    }))
  }

  return (
    <>
      {checkModalToOpen && 
        <UpsellModal
        title='test'
        count={maxScreensCount}
        minCount={1}
        maxCount={10}
        totalCost={basePriceAdditionalScreensValue}
        onUp={() => { currentScreenCount(maxScreensCount + 1) }}
        onDown={() => { currentScreenCount(maxScreensCount - 1) }}
        onContinue={() => { setUpsellPopupOpen(false) }}
        onClose={() => { setUpsellPopupOpen(false) }}
      />}

      {!checkModalToOpen &&
        <Modal
          open
          className={classNames('main-modal main-modal--xs', className)}
          size="tiny"
          onClose={onClose}
        >
          <div className={styles.modalBody}>
            {onClose && <div className={classNames('icon-cross', styles.closeIcon)} onClick={onClose} />}

            <div className={styles.modalHeader}>
              <h2>{t('settings.Payment')}</h2>
              <span></span>
            </div>

            <div className={styles.modalContent}>
              <PaymentSummaryItem
                isVisible
                name={basePriceName}
                value={product?.key === 'website-additional-screens' ? basePriceAdditionalScreensValue : basePriceValue}
              />

              {product?.key === 'website-additional-screens' && (
                  <p className={styles.upsell}>{t('projectBuilder.Prices based on screens', { count: maxScreensCount })} </p>
              )}

              <PaymentSummaryItem
                isVisible={isVatVisible}
                name={t('projectBuilder.VAT')}
                value={product?.key === 'website-additional-screens' ? vatValue.toFormat('$0,0.00') : vatValue}
              />

              <PaymentSummaryItem
                isVisible={isDiscountVisible}
                name={t('projectBuilder.Discount')}
                value={discountAmount}
              />

              <div className={styles.total}>
                <h2 className={styles.totalCaption}>
                  {t('projectBuilder.Total')}
                </h2>

                <h2 className={styles.totalPrice}>
                  {totalPrice}
                </h2>
              </div>

              <Discount currency={currency}/>

              <Button
                disabled={buttonFrozen}
                waiting={buttonFrozen}
                className={styles.btn}
                onClick={handleButtonSubmit}
              >
                {t('projectBuilder.Pay now & start')}
                <i className={classNames(styles.icon, 'icon-arrow-right')} />
              </Button>
            </div>
          </div>
        </Modal>
      }
    </>
  )
}

export default PaymentModal
