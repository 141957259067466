import {useTranslation} from 'react-i18next'

export default () => {
	const {i18n} = useTranslation()

	return (language, onChange) => {
		if (i18n.language != language) {
			i18n.changeLanguage(language)

			if (onChange)
				onChange()
		}
	}
}