import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import { cutAt } from '@application/index/asset/utils/stringProcessor'

import WrongFileFormatModal from './WrongFileFormatModal'

const ALLOWED_FILE_EXTENSIONS = [
  'png', 'jpg', 'pdf', 'ai',
  'tif', 'tiff', 'eps', 'psd', 'psb',
  'ppt', 'pptx', 'doc', 'docx',
  'svg', 'fig', 'indd', 'ico', 'xd', 'mp4',
  'gif', 'mpeg', 'mov', 'aepx', 'c4d', 'f4v', 'flv', 'procreate', 'zip', 'aep'
]

, SourceFilesDropzone = ({onUpload}) => {
  const [uploadedFiles, setUploadedFiles] = useState([])

  , [
    wrongFileFormatModalDisplayed
    , setWrongFileFormatModalDisplayed
  ] =
    useState()

  return <>
    {uploadedFiles.map((file, key) =>
      <figure className="file-icon__item conv-popup-file-icon__item" {...{key}}>
        <div className="file-icon file-icon--loading text-center in-white"/>
        <figcaption className="file-icon__details">
          <p className="file-icon__title">
            {cutAt(file.name, 10)}
          </p>
        </figcaption>
      </figure>
    )}

    <div className="text-center">
      <Dropzone multiple
        onDrop={files =>
          files && files[0] && (
              files.reduce((result, {name}) =>
                result || ALLOWED_FILE_EXTENSIONS.includes(
                  name.split('.').pop().toLowerCase()
                )
              , false)
            ?
              (() => {
                setUploadedFiles(files)

                files.forEach(async file => {
                  await onUpload(file)

                  uploadedFiles.splice(
                    uploadedFiles.indexOf(file), 1
                  )
                  setUploadedFiles(uploadedFiles)
                })
              })()
            :
              setWrongFileFormatModalDisplayed(true)
          )
        }
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <button type="button" className="main-button-link in-grey-200 conv-popup__upload-box m-b-20 text-center">
              <div className="icon-upload-light font-40" />

              <div className="conv-popup__upload-box--text inline-block m-l-15">
                Drag and Drop
                <br />
                or Upload your files
              </div>
            </button>
            <input type="hidden" {...getInputProps()}/>
          </div>
        )}
      </Dropzone>
    </div>
    {wrongFileFormatModalDisplayed &&
      <WrongFileFormatModal
        setDisplayed={
          setWrongFileFormatModalDisplayed
        }
      />
    }
  </>
}

SourceFilesDropzone.propTypes = {
  onUpload: PropTypes.func.isRequired
}

export default SourceFilesDropzone
