import api from '@application/index/asset/utils/api'
import apiV2 from '@application/index/asset/utils/apiV2'

export const DISCOUNT_CHECK_REQUEST = 'DISCOUNT_CHECK_REQUEST'
export const DISCOUNT_CHECK_SUCCESS = 'DISCOUNT_CHECK_SUCCESS'
export const DISCOUNT_CHECK_FAILURE = 'DISCOUNT_CHECK_FAILURE'


export const POST_CLIENT_DISCOUNT_REQUEST = 'POST_CLIENT_DISCOUNT_REQUEST'
export const POST_CLIENT_DISCOUNT_SUCCESS = 'POST_CLIENT_DISCOUNT_SUCCESS'
export const POST_CLIENT_DISCOUNT_FAILURE = 'POST_CLIENT_DISCOUNT_FAILURE'

export const checkDiscount = (value, currency = null) => api.get({
  endpoint: `/api/v1/discounts/${value}?currency=${currency}`,

  types: [
    DISCOUNT_CHECK_REQUEST,
    DISCOUNT_CHECK_SUCCESS,
    DISCOUNT_CHECK_FAILURE
  ],

  normalize: true
})

export const createClientDiscount = (data) => apiV2.post({
  endpoint: '/api/v2/discounts/client_discount',
  body: data,

  types: [
    POST_CLIENT_DISCOUNT_REQUEST,
    POST_CLIENT_DISCOUNT_SUCCESS,
    POST_CLIENT_DISCOUNT_FAILURE
  ],

  normalize: true
})
