import React from 'react'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const isDesigner = (me) => me.userType === 'designer'
const isClient = (me) => me.userType === 'client'
const isGodClient = (me) => me.god

const mapStateToProps = (state) => ({
  me: state.me.me
})

, loginPath = 'login'
, clientPath = 'c'

, getDesignerPath = (me) => {
  const {
    approved, welcomeApproved
  } =
    me || {}

  return 'd/'
    + (!approved || welcomeApproved ?
      'in-progress'
    :
      'welcome'
    )
}

, requireAuthentication = (method, path) =>
  View => ({me, ...properties}) => {
    const {id} = me

    return id && !method(me) ?
      <View {...{me, ...properties}}/>
    :
      <Redirect
        to={'/' + (
          id ?
            path instanceof Function ?
              path(me)
            :
              path
          :
            loginPath
        )}
      />
  }

export const requireClientAuthentication = compose(
  connect(mapStateToProps)
  , requireAuthentication(isDesigner, getDesignerPath)
)

, getUserPath = (client, me) =>
  client ? clientPath : getDesignerPath(me)

, requireGodClientAuthentication = compose(
  connect(mapStateToProps)
  , View =>
    ({me, ...properties}) => {
      const {id} = me
      , client = isClient(me)

      return id && client && isGodClient(me) ?
        <View {...{me, ...properties}}/>
      :
        <Redirect to={'/' + (
          id ? getUserPath(client, me) : loginPath
        )}/>
    }
)

, requireDesignerAuthentication = compose(
  connect(mapStateToProps)
  , requireAuthentication(isClient, clientPath)
)

, requireNoAuthentication = compose(
  connect(mapStateToProps)
  , View =>
    ({me, ...properties}) =>
      me.id ?
        <Redirect
          to={'/' + getUserPath(isClient(me), me)}
        />
      :
        <View {...{me, ...properties}}/>
)
