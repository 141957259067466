import React, { useState } from 'react'
import {useTranslation, Trans} from 'react-i18next'
import classNames from 'classnames'
import { Modal } from 'semantic-ui-react'
import styles from './GetQuoteModal.module.scss'
import Button from '@application/index/component/index/Button'
import Textarea from 'react-textarea-autosize'

const path = 'client.project.new.designType.custom.quote'

const GetQuoteModal = ({ requestManualProject, showSuccessMessageModal, onClose, className }) => {
  const {
    placeholder, get, note
  } =
    useTranslation().t(path)

  const [content, changeContent] = useState('')
  const [inProgress, changeInProgress] = useState(false)

  const onSubmit = () => {
    changeInProgress(true)

    requestManualProject(content).then(() => {
      changeInProgress(false)
      showSuccessMessageModal()
    })
  }

  return (
    <Modal
      onClose={onClose}
      open
      className={classNames('main-modal main-modal--xs', className)}
      size="small"
    >
      <div className={styles.modalBody}>
        <div className={classNames('icon-cross', styles.closeIcon)} onClick={onClose} />

        <h2>
          <Trans
            i18nKey={path + '.title'}
            components={{
              span: <span className={styles.isGreen}/>
            }}
          />
        </h2>

        <Textarea
          value={content}
          onChange={(e) => changeContent(e.target.value)}
          className={styles.textarea}
          {...{placeholder}}
        />

        <Button
          onClick={onSubmit}
          disabled={inProgress || !content}
        >
          {get}
        </Button>

        <p className={styles.description}>
          {note}
        </p>
      </div>
    </Modal>
  )
}

export default GetQuoteModal
