import React, { useRef, useState, useEffect } from 'react'
import {useTranslation} from 'react-i18next'
import { Modal } from 'semantic-ui-react'

import styles from './BrandsModal.module.scss'

import BrandsList from './BrandsListContainer'
import BrandNameForm from './BrandNameFormContainer'

const ModalHeader = ({ children }) => (
  <div className="modal-primary__header conv-modal-primary-header">
    <span className="modal-primary__header-title text-center">{children}</span>
  </div>
)

const ModalBody = ({ children }) => (
  <div className="modal-primary__body">
    <div className="modal-primary__body-block conv-confirmation-modal-primary-body-block">
      {children}
    </div>
  </div>
)

const ModalFooter = ({ children }) => (
  <div className="modal-primary__actions conv-modal-primary-actions conv-confirmation-primary-actions align-items-start">
    <span/>
    { children }
  </div>
)

const BrandsModal = ({ onContinue }) => {
  const {t} = useTranslation()

  const modalContainer = useRef()
  const [modalContainerMounted, setModalContainerMounted] = useState()

  useEffect(() => setModalContainerMounted(true), [modalContainer])

  return <div ref={modalContainer} className={styles.brandsModal}>{
    modalContainerMounted && <Modal
      className="main-modal main-modal--sm"
      open
      closeOnDimmerClick={false}
      mountNode={modalContainer.current}
    >
      <div className="modal-primary">
        <ModalHeader>
          {t('client.project.new.title')}
        </ModalHeader>

        <ModalBody>
          <BrandsList />
          <BrandNameForm />
        </ModalBody>

        <ModalFooter>
          <button className="main-button-link main-button-link--lg main-button-link--black-pink m-b-10" type="button" onClick={onContinue}>
            {t('continue')}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  }</div>
}

export default BrandsModal
